import { LOCALE } from "@utils/config";
import translate from "@utils/translate";

import MultiLingual from "@interfaces/database/MultiLingual";

/**
 * Resolves the multi-lingual data based on the provided locale.
 * If the data for the provided locale is not available, it falls back to Whatever is available.
 * To avoid fallback, pass forceLocale as true.
 * If both the data is present and locale is not present then it falls back to English.
 * If no data is available, it returns undefined.
 *
 * @param data          The multi-lingual data to resolve.
 * @param locale        The locale to use for resolving the data. Defaults to the current locale.
 * @param forceLocale   If true, it forces the function to return data for the provided locale only.
 * @returns             The resolved multi-lingual data. Or undefined in case of when it is not able to resolve.
 */
const resolveMultiLingual = <T>(
  data: MultiLingual<T> = {},
  locale: typeof LOCALE[keyof typeof LOCALE] = translate.getCurrentLocale(),
  forceLocale = false
): T | undefined => {
  let localData = undefined;

  switch (locale) {
    case LOCALE.EN:
      localData = data.en;
      break;
    case LOCALE.JA:
      localData = data.ja;
      break;
    default:
      /**
       * If no locale is present, then we fallback to Japanese Locale.
       * This is by design.
       */
      localData = data.ja;
      break;
  }

  if (localData || forceLocale) {
    return localData;
  }

  /**
   * Now, the localData is empty.
   * We need to fall back to another language to show whatever is available.
   * To avoid fallback, set forceLocale as true.
   * In that case, the function will return undefined if the expected locale is not present.
   */
  if (data.ja) {
    localData = data.ja;
  }
  if (data.en) {
    localData = data.en;
  }
  return localData;
};

/**
 * Prepares a MultiLingual object for the provided data.
 * This is to be used before saving anything into the database.
 * This function will wrap the data into a MultiLingual object.
 *
 * @param data          The data to be wrapped.
 * @param locale        The locale of the data. If no locale is present, then the locale of the UI will be picked by default.
 * @param existingData  The existing data (if any) to be merged with the new data.
 * @returns             The wrapped Object of the Data in MultiLingual format.
 */
const prepareMultiLingual = <T>(
  data: T,
  locale: typeof LOCALE[keyof typeof LOCALE] = translate.getCurrentLocale(),
  existingData: MultiLingual<T> = {}
): MultiLingual<T> => {
  const wrappedData: MultiLingual<T> = existingData;
  switch (locale) {
    case LOCALE.EN:
      wrappedData.en = data;
      break;
    case LOCALE.JA:
      wrappedData.ja = data;
      break;
    default:
      /**
       * If no locale is present, then we fallback to Japanese Locale.
       * This is by design.
       */
      wrappedData.ja = data;
      break;
  }
  return wrappedData;
};

export { prepareMultiLingual, resolveMultiLingual };
