// chart axis and value representation:
//   X-axis
//  1 |   *
//  2 |    *
//  3 |     *
//  4 |      *
//  5 |       *
//  6 |        *
//  7 |           *
//  8 |              *
//    +----------------------- Y-axis
//    1      2     3     4    5

import intl from "react-intl-universal";

import { Box, Stack, Typography } from "@mui/material";
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter
} from "victory";

import useSize from "@hooks/useSize";

import theme, { colorPalette } from "@utils/theme";

import examineeLine from "@assets/images/examineeLine.svg";
import japaneseCultureLine from "@assets/images/japaneseCultureLine.svg";

// FIXME: make static content component dynamic

const Cultural = ({
  japaneseCultureDataPoints,
  examineeDataPoints,
  domain = { x: [1, 8], y: [1, 5] } // FIXME: Update domain based on constant values
}: {
  japaneseCultureDataPoints: Array<{ x: number; y: number }>;
  examineeDataPoints: Array<{ x: number; y: number }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  domain?: any; // FIXME: fix any type
}) => {
  const [ref, { width, height }] = useSize();
  const boxWidth = width > 556 ? 556 : width;

  const LeftStaticContent = () => {
    return (
      <Stack
        bgcolor={colorPalette.blue[20]}
        ref={ref}
        p={{ xs: 1.25, md: 2.5 }}
        rowGap={2}>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_extroverted_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_extroverted_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_logical_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_logical_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_direct_communication_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_direct_communication_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_cooperative_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_cooperative_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_spontaneous_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_spontaneous_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_self_motivated_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_self_motivated_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_vision_motivated_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_vision_motivated_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_process_oriented_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_process_oriented_subtitle")}
          </Typography>
        </Box>
      </Stack>
    );
  };
  const RightStaticContent = () => {
    return (
      <Stack
        bgcolor={colorPalette.blue[20]}
        ref={ref}
        p={{ xs: 1.25, md: 2.5 }}
        rowGap={2}>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_introverted_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_introverted_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_emotional_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_emotional_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_indirect_communication_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_indirect_communication_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_competitive_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_competitive_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_conscientious_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_conscientious_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_validation_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_validation_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_profit_motivated_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_profit_motivated_subtitle")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="white">
            {intl.get("t_cultural_data_result_oriented_title")}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            display={{ xs: "none", sm: "block" }}>
            {intl.get("t_cultural_data_result_oriented_subtitle")}
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <Box maxWidth={1560}>
      {/* chart label start */}
      <Stack direction="row" gap={2.5} justifyContent="flex-end">
        <Box>
          <Box component="img" src={japaneseCultureLine} alt="examinee" />
          <Typography ml={1} component="span">
            {intl.get("t_general_japanese_culture")}
          </Typography>
        </Box>
        <Box>
          <Box component="img" src={examineeLine} alt="examinee" />
          <Typography ml={1} component="span">
            {intl.get("t_general_examinee")}
          </Typography>
        </Box>
      </Stack>
      {/* chart label end */}

      <Stack
        mt={2}
        border="1px solid white"
        borderRadius="10px"
        direction="row"
        position="relative">
        {/* center line start */}
        <Box
          borderLeft={`1px solid ${colorPalette.grey[20]}`}
          position="absolute"
          left="50%"
          height="100%"
        />
        {/* center line end */}
        {/* left part start */}
        <LeftStaticContent />
        {/* left part end */}
        {/* middle part start */}
        <Stack width="34.75rem">
          <svg
            id="cultural-chart-class-name"
            height={height}
            preserveAspectRatio="none"
            width="100%"
            viewBox={"0 0  " + boxWidth + " " + height}
            pointerEvents="">
            <VictoryChart
              padding={{ top: 31.5, bottom: 42, left: 25, right: 25 }}
              standalone={false}
              height={height}
              domain={domain}
              width={width}>
              <VictoryGroup horizontal>
                <VictoryGroup data={examineeDataPoints}>
                  <VictoryLine
                    style={{
                      data: {
                        stroke: theme.palette.primary.dark,
                        strokeWidth: 1,
                        strokeDasharray: 4
                      }
                    }}
                    // FIXME: comment animation for pdf generation
                    // animate={{
                    //   duration: 1000,
                    //   onLoad: { duration: 1000 }
                    // }}
                  />
                  <VictoryScatter
                    style={{
                      data: {
                        fill: theme.palette.background.default,
                        stroke: theme.palette.primary.dark,
                        strokeWidth: 1
                      }
                    }}
                    size={3}
                  />
                </VictoryGroup>
                <VictoryGroup data={japaneseCultureDataPoints}>
                  <VictoryLine
                    style={{
                      data: {
                        stroke: theme.palette.secondary.main,
                        strokeWidth: 1,
                        strokeDasharray: 4
                      }
                    }}
                    // FIXME: comment animation for pdf generation
                    // animate={{
                    //   duration: 1000,
                    //   onLoad: { duration: 1000 }
                    // }}
                  />
                  <VictoryScatter
                    style={{
                      data: {
                        fill: theme.palette.background.default,
                        stroke: theme.palette.secondary.main,
                        strokeWidth: 1
                      }
                    }}
                    size={3}
                  />
                </VictoryGroup>
              </VictoryGroup>
              <VictoryAxis
                invertAxis
                style={{ axis: { stroke: "none" } }} // Note: change stoke color and comment tickFormat props to show the graph points for testing
                tickFormat={() => ""}
              />
              <VictoryAxis
                style={{ axis: { stroke: "none" } }}
                tickFormat={() => ""}
                dependentAxis
              />
            </VictoryChart>
          </svg>
        </Stack>
        {/* middle part end */}

        {/* right part start */}
        <RightStaticContent />
        {/* right part end */}
      </Stack>
    </Box>
  );
};

export default Cultural;
