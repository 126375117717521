import { Box } from "@mui/material";

import Chart from "@components/Chart";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const ChartPage = () => {
  return (
    <>
      <Heading text="Chart" />
      <Box maxWidth={700}>
        <Chart domain={[-10, 10]} x={-5.5} y={8} />
      </Box>
    </>
  );
};

export default ChartPage;
