import intl from "react-intl-universal";
import { useSearchParams } from "react-router-dom";

import {
  Input,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { doc } from "firebase/firestore";
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
  useSendEmailVerification,
  useSignInWithEmailAndPassword,
  useSignOut
} from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";

import Button from "@components/Button";
import Loader from "@components/Loader";

import { COMPANY_TYPE, FIRESTORE_COLLECTIONS, USER_TYPE } from "@utils/config";
import { auth, db } from "@utils/firebase";

import useUserProfile from "@hooks/database/useUserProfile";

const Login = () => {
  const [user, loading] = useAuthState(auth);
  const [signOut] = useSignOut(auth);
  const [signInWithEmailAndPassword, , , isSignInError] =
    useSignInWithEmailAndPassword(auth);
  const [createUserWithEmailAndPassword, , , isRegisterError] =
    useCreateUserWithEmailAndPassword(auth);
  const [sendEmailVerification, , sendEmailVerificationError] =
    useSendEmailVerification(auth);

  const [searchParams, setSearchParams] = useSearchParams();

  const userProfile = useUserProfile();
  const companyId = userProfile?.value?.company_ids?.[0];

  const documentReference = doc(
    db,
    `${FIRESTORE_COLLECTIONS.COMPANIES}/${companyId}`
  );
  const [companyData, companyDataLoading, companyDataError] =
    useDocumentData(documentReference);

  const registerUser = (is_dummy = false) => {
    const email = prompt(intl.get("t_debugger_email_prompt"));
    const password = prompt(intl.get("t_debugger_password_prompt"));
    if (email && password) {
      if (is_dummy) {
        searchParams.set("is_dummy", "1");
        setSearchParams(searchParams.toString(), { replace: true });
      }
      createUserWithEmailAndPassword(email, password);
    } else {
      alert(intl.get("t_debugger_missing_email_password_alert"));
    }
  };
  const signin = () => {
    const email = prompt(intl.get("t_debugger_email_prompt"));
    const password = prompt(intl.get("t_debugger_password_prompt"));
    if (email && password) {
      signInWithEmailAndPassword(email, password);
    } else {
      alert(intl.get("t_debugger_missing_email_password_alert"));
    }
  };

  const getUserTypeLabel = (
    userType: typeof USER_TYPE[keyof typeof USER_TYPE] = USER_TYPE.NO_TYPE
  ) => {
    switch (userType) {
      case USER_TYPE.COMPANY:
        return "Company Manager";
      case USER_TYPE.COMPANY_DEPENDENT:
        return "Company Dependent";
      case USER_TYPE.OEM:
        return "OEM";
      case USER_TYPE.SUPER_USER:
        return "Tokhimo Internal User";
      case USER_TYPE.NO_TYPE:
      default:
        return "No Type";
    }
  };

  return (
    <>
      <Typography variant="h5">
        {intl.get("t_debugger_login_information")}:
      </Typography>
      <br />
      {loading ? (
        <Loader />
      ) : (
        <>
          {isSignInError ? (
            <Typography color="error.main">
              {intl.get("t_debugger_login_error_message")}
            </Typography>
          ) : (
            false
          )}
          {isRegisterError ? (
            <Typography color="error.main">
              {intl.get("t_debugger_registration_error_message")}
            </Typography>
          ) : (
            false
          )}
          {sendEmailVerificationError ? (
            <Typography color="error.main">
              Cannot send verification email.
            </Typography>
          ) : (
            false
          )}
          <Typography> {intl.get("t_general_status")}: </Typography>
          {user ? "Logged In" : "Not Logged In"}
          <br />
          <br />
          {user ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Property</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Update?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>User Type</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {getUserTypeLabel(userProfile.value?.user_type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button disabled>Save</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Display Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Input defaultValue={user.displayName} />
                    </TableCell>
                    <TableCell>
                      <Button>Save</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Email</Typography>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Button disabled>Save</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Email Verified?</Typography>
                    </TableCell>
                    <TableCell>{user.emailVerified ? "✔️" : "❌"}</TableCell>
                    <TableCell>
                      {user.emailVerified ? (
                        <Button disabled>
                          {intl.get("t_debugger_email_verified")}
                        </Button>
                      ) : (
                        <Button onClick={() => sendEmailVerification()}>
                          Send Verification Email
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Profile Picture</Typography>
                    </TableCell>
                    <TableCell>
                      <img src={user.photoURL ?? ""} />
                    </TableCell>
                    <TableCell>
                      <Button>Upload Photo</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Password</Typography>
                    </TableCell>
                    <TableCell>
                      <Input />
                    </TableCell>
                    <TableCell>
                      <Button>Save</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Company Type</Typography>
                    </TableCell>
                    <TableCell>
                      {companyDataError
                        ? "Error"
                        : companyDataLoading
                        ? "Loading"
                        : Object.keys(COMPANY_TYPE).find(
                            (key) =>
                              COMPANY_TYPE[key as keyof typeof COMPANY_TYPE] ===
                              companyData?.company_type
                          )}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Created At</Typography>
                    </TableCell>
                    <TableCell>{user.metadata?.creationTime}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Last Login</Typography>
                    </TableCell>
                    <TableCell>{user.metadata?.lastSignInTime}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>User ID</Typography>
                    </TableCell>
                    <TableCell>{user.uid}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {/* user type here */}
                  {userProfile.value?.user_type === USER_TYPE.COMPANY ? (
                    <TableRow>
                      <TableCell>
                        <Typography>Company ID</Typography>
                      </TableCell>
                      <TableCell>
                        {userProfile.value?.company_ids?.[0]}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : (
                    false
                  )}
                  {userProfile.value?.user_type === USER_TYPE.COMPANY ? (
                    <TableRow>
                      <TableCell>
                        <Typography>Test ID</Typography>
                      </TableCell>
                      <TableCell>{userProfile.value?.test_ids?.[0]}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : (
                    false
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            false
          )}
          <br />
          <Typography>Action:</Typography>
          <Stack direction="row" gap={2}>
            {user ? (
              <>
                <Button color="secondary" onClick={() => signOut()}>
                  {intl.get("t_general_logout")}
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => signin()}>
                  {intl.get("t_general_login")}
                </Button>
              </>
            )}
            <Button>{intl.get("t_general_forgot_password")}</Button>
          </Stack>
          <br />
          <Stack direction="row" gap={2} flexWrap="wrap">
            <>
              <Button onClick={() => registerUser(false)}>
                {intl.get("t_general_create_new_account")} (Company)
              </Button>
              <Button onClick={() => registerUser(true)}>
                {intl.get("t_general_create_new_account")} (Company with Dummy
                Data)
              </Button>
            </>
          </Stack>
        </>
      )}
    </>
  );
};

export default Login;
