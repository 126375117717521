import { ReactNode } from "react";

import { ENVIRONMENT } from "@utils/config";

interface EnvironmentSpecificProps {
  env?:
    | typeof ENVIRONMENT[keyof typeof ENVIRONMENT]
    | Array<typeof ENVIRONMENT[keyof typeof ENVIRONMENT]>;
  children: ReactNode | string | number;
  adminAllowed?: boolean;
}

const EnvironmentSpecific = ({
  env = ENVIRONMENT.STAGE,
  children,
  adminAllowed = true
}: EnvironmentSpecificProps) => {
  if (typeof env === "string") {
    env = [env];
  }
  if (env.includes(ENVIRONMENT.STAGE)) {
    env.push(ENVIRONMENT.PREVIEW);
  }
  env.push(ENVIRONMENT.LOCAL);
  env.push(ENVIRONMENT.TEST);

  if (process.env.REACT_APP_ENVIRONMENT) {
    const stringTypedEnv: Array<string> = env;

    if (stringTypedEnv.includes(process.env.REACT_APP_ENVIRONMENT)) {
      return <>{children}</>;
    }
  }

  if (adminAllowed) {
    // FIXME: update the EnvironmentSpecific conditions
    return <>{children}</>;
  }

  return <></>;
};

export default EnvironmentSpecific;
