import { ReactNode } from "react";

import { Stack } from "@mui/material";

//FIXME: Check this code for company user and if it will work then move it to root level
const CandidatePagesWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      justifyContent="center"
      minHeight={{
        xs: "calc(100vh - 9rem)",
        sm: "calc(100vh - 10rem)",
        lg: "calc(100vh - 10.5rem)"
      }}
      px={{ xs: 1, md: 10, lg: 12 }}
      py={4}>
      {children}
    </Stack>
  );
};

export default CandidatePagesWrapper;
