import React, { MouseEvent, useState } from "react";

import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import {
  Checkbox as MuiCheckbox,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

import Status from "@components/Status";

import { USER_TEST_STATUS } from "@utils/config";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

interface Data {
  nameAndEmail: string;
  nationality: string;
  invitedDate: string;
  remindedDate: string;
  takenDate: string;
  status: string;
  score: string;
  result: string;
  sendReminder: string;
}

function createData(
  nameAndEmail: string,
  nationality: string,
  invitedDate: string,
  remindedDate: string,
  takenDate: string,
  status: string,
  score: string,
  result: string,
  sendReminder: string
): Data {
  return {
    nameAndEmail,
    nationality,
    invitedDate,
    remindedDate,
    takenDate,
    status,
    score,
    result,
    sendReminder
  };
}

const rows = [
  createData(
    "user a",
    "india",
    "21.04.2022",
    "18.04.2022",
    "27.04.2022",
    "Taken",
    "75.0",
    "link1",
    "sendReminder"
  ),
  createData(
    "user b",
    "japan",
    "21.04.2022",
    "18.04.2022",
    "27.04.2022",
    "Reminded",
    "-",
    "-",
    "sendReminder"
  ),
  createData(
    "user c",
    "usa",
    "21.04.2022",
    "18.04.2022",
    "-",
    "Invited",
    "score",
    "-",
    "sendReminder"
  )
];

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "nameAndEmail",
    label: "Name and Email Address"
  },
  {
    id: "nationality",
    label: "Nationality"
  },
  {
    id: "invitedDate",
    label: "Invited Date"
  },
  {
    id: "remindedDate",
    label: "Reminded Date"
  },
  {
    id: "takenDate",
    label: "Taken Date"
  },
  {
    id: "status",
    label: "Status"
  },
  {
    id: "score",
    label: "Score"
  },
  {
    id: "result",
    label: "Result"
  },
  {
    id: "sendReminder",
    label: "Send Reminder"
  }
];

// FIXME: use checkbox component once it is fixed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledCheckbox = styled(MuiCheckbox)(({ theme }: any) => ({
  "border": `0.0625rem solid ${theme.palette.text.primary}`,
  "borderRadius": "0.125rem",
  "height": "1.5rem",
  "marginRight": "0.5rem",
  "width": "1.5rem",
  "color": "transparent",
  "&.MuiBox-root": {
    height: "1.5rem"
  },
  "&.MuiCheckbox-root": {
    backgroundColor: "transparent",
    padding: 0
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(217, 218, 225, 0.6)",
    border: `0.0625rem solid ${theme.palette.text.secondary}`,
    color: "transparent"
  },
  "&.Mui-checked": {
    backgroundColor: `${theme.palette.primary.main}`,
    borderColor: `${theme.palette.primary.main}`
  }
}));

const TablePage = () => {
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <>
      <Heading text="Table" />
      <Paper>
        <TableContainer>
          <Table aria-labelledby="candidateInfo">
            {/* header start */}
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => {
                  return (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {/* header end */}

            {/* body start */}
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.nameAndEmail);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.nameAndEmail}
                    selected={isItemSelected}>
                    <TableCell>
                      <Typography>User A</Typography>
                      <Typography>example@gmail.com</Typography>
                    </TableCell>
                    <TableCell>{row.nationality}</TableCell>
                    <TableCell>{row.invitedDate}</TableCell>
                    <TableCell>{row.remindedDate}</TableCell>
                    <TableCell>{row.takenDate}</TableCell>
                    <TableCell>
                      <Status label={USER_TEST_STATUS.TAKEN} />
                    </TableCell>
                    <TableCell>{row.score}</TableCell>
                    <TableCell>{row.result}</TableCell>

                    <TableCell>
                      <Typography component="span" mr="1rem">
                        Select
                      </Typography>
                      {/* FIXME: update checkbox component once it is fixed */}
                      <StyledCheckbox
                        checkedIcon={
                          <DoneRoundedIcon
                            sx={{ color: "#FFF", fontSize: "1.125rem" }}
                          />
                        }
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId
                        }}
                        onClick={(event) =>
                          handleClick(event, row.nameAndEmail)
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {/* body end */}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default TablePage;
