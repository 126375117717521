import TestStatus from "@pages/Candidates/TestStatus";

const TestCompleted = () => {
  return (
    <TestStatus
      heading="Test is completed."
      subHeading="Thank you for answering all questions."
      status="success"
    />
  );
};

export default TestCompleted;
