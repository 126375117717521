import { useEffect, useState } from "react";

import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { doc, getDoc, Timestamp } from "firebase/firestore";

import Loader from "@components/Loader";
import Status from "@components/Status";

import { FIRESTORE_COLLECTIONS, USER_TEST_STATUS } from "@utils/config";
import { db } from "@utils/firebase";
import { getCountryName } from "@utils/location";

import useUserProfile from "@hooks/database/useUserProfile";
import InviteCandidateModal from "@pages/CandidateList/InviteCandidateModal";
import MobileCandidateListTable from "@pages/CandidateList/MobileCandidateListTable";

interface Column {
  id: keyof Row;
  label: string;
}

const COLUMNS: ReadonlyArray<Column> = [
  {
    id: "nameAndEmail",
    label: "Name and Email Address"
  },
  {
    id: "nationality",
    label: "Nationality"
  },
  {
    id: "invitedDate",
    label: "Invited Date"
  },
  {
    id: "takenDate",
    label: "Taken Date"
  },
  {
    id: "status",
    label: "Status"
  },
  {
    id: "result",
    label: "Result"
  }
];

interface Row {
  nameAndEmail: {
    name: string;
    email: string;
  };
  nationality: string;
  invitedDate: string;
  takenDate: string;
  status: typeof USER_TEST_STATUS[keyof typeof USER_TEST_STATUS];
  result: string;
}

const CandidateList = () => {
  const [rowsData, setRowsData] = useState<Array<Row>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { value } = useUserProfile();

  const invitedUsersSubmission =
    value?.company_information?.invited_users_submission;

  useEffect(() => {
    const getUsersSubmissionData = async () => {
      setIsLoading(true);
      const userSubmissionData = [];
      if (invitedUsersSubmission && invitedUsersSubmission.length > 0) {
        for (let index = 0; index < invitedUsersSubmission.length; index++) {
          const [testId, submissionId] =
            invitedUsersSubmission?.[index]?.split("__") || [];

          // get user submission data
          if (testId && submissionId) {
            const submissionSubCollectionRef = doc(
              db,
              `${FIRESTORE_COLLECTIONS.TESTS}/${testId}/${FIRESTORE_COLLECTIONS.SUBMISSIONS}`,
              submissionId
            );
            const submission = await getDoc(submissionSubCollectionRef);

            const submissionData = submission?.data();
            if (submission.exists()) {
              // append user submission data into the table
              const {
                candidate_information = {},
                test_status = "",
                test_completed_at = ""
              } = submissionData || {};

              userSubmissionData.push({
                nameAndEmail: {
                  name: candidate_information?.name?.en ?? "",
                  email: candidate_information?.email ?? ""
                },
                nationality:
                  getCountryName(candidate_information?.nationality) ?? "",
                invitedDate: dayjs(Timestamp.now()?.toDate()).format(
                  "DD.MM.YYYY"
                ),
                takenDate: test_completed_at
                  ? dayjs(test_completed_at?.toDate()).format("DD.MM.YYYY")
                  : "-",
                status: test_status,
                result:
                  test_completed_at && USER_TEST_STATUS.TAKEN
                    ? `report/${testId}/${submissionId}`
                    : ""
              });
            }
          }
        }
        setRowsData([...rowsData, ...userSubmissionData]);
      }
      setIsLoading(false);
    };
    getUsersSubmissionData();
  }, [value?.company_information?.invited_users_submission]);

  if (isLoading) return <Loader />;

  return (
    <Stack alignItems="center" pt={{ xs: 2, md: 4 }} pb={20}>
      <Box width="100%" maxWidth="80rem">
        <Typography variant="h5" color="primary">
          Candidate List
        </Typography>
        <Stack direction="row-reverse" my={3}>
          <InviteCandidateModal setRowsData={setRowsData} rowsData={rowsData} />
        </Stack>
        <Box display={{ xs: "none", md: "block" }}>
          <TableContainer>
            <Table aria-labelledby="candidateInfo">
              {/* header start */}
              <TableHead>
                <TableRow>
                  {COLUMNS.map((headCell) => {
                    return (
                      <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              {/* header end */}

              {/* body start */}
              <TableBody>
                {rowsData?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={COLUMNS.length}
                      sx={{ textAlign: "center" }}>
                      <Box my={4}>
                        <Typography>
                          No Records Found, Please invite candidate
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {rowsData?.map((row: Row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}>
                          <TableCell>
                            <Typography>{row?.nameAndEmail?.name}</Typography>
                            <Typography>{row?.nameAndEmail?.email}</Typography>
                          </TableCell>
                          <TableCell>{row?.nationality}</TableCell>
                          <TableCell>{row?.invitedDate}</TableCell>
                          <TableCell>{row?.takenDate}</TableCell>
                          <TableCell>
                            {row?.status ? <Status label={row.status} /> : "-"}
                          </TableCell>
                          <TableCell>
                            {row.result ? (
                              <Link href={row.result} target="_blank" mb={2}>
                                Result
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          {/* <TableCell>
                            <Link to=`${{row?.result}}`>Result</Link>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
              {/* body end */}
            </Table>
          </TableContainer>
        </Box>
        <Box display={{ xs: "block", md: "none" }}>
          <MobileCandidateListTable rowsData={rowsData} />
        </Box>
      </Box>
    </Stack>
  );
};

export default CandidateList;
