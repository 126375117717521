import { useState } from "react";

import { useForm } from "react-hook-form";
import intl from "react-intl-universal";
import { Link, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Link as MuiLink,
  Stack,
  styled,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import Button from "@components/Button";
import Loader from "@components/Loader";
import TelephoneInput from "@components/TelephoneInput";
import TextField from "@components/TextField";

import useToast from "@hooks/useToast";

import { FIRESTORE_COLLECTIONS, ROUTES } from "@utils/config";
import { auth, db, functions } from "@utils/firebase";
import { prepareMultiLingual } from "@utils/multiLingual";

import CompleteProfile_BG from "@assets/images/signUp.svg";
import Tokhimo_Logo from "@assets/logos/logo-white.svg";
import useUserProfile from "@hooks/database/useUserProfile";

interface CompleteProfileFormData {
  companyName: string;
  contactName: string;
  contactPhoneNumber?: string;
}

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 62,
    width: 62
  },
  [theme.breakpoints.up("sm")]: {
    height: 55,
    width: 55
  },
  [theme.breakpoints.up("lg")]: {
    height: 62,
    width: 62
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledCompleteProfileImage = styled("img")({
  width: "95%",
  position: "relative",
  top: "calc(20vh - 1.5rem)",
  transform: "translateY(-25%)"
});

const CompleteProfile = () => {
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const toast = useToast();

  const companyInfo = userProfile.value?.company_information;

  const schema = yup.object({
    companyName: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_company_name")
        })
      ),
    contactName: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_contact_name")
        })
      ),
    contactPhoneNumber: yup
      .string()
      .test(
        "phone",
        intl.get("t_error_invalid", {
          field: intl.get("t_general_phone_number")
        }),
        (value) => {
          // Empty error will be handled separately.
          if (!value) {
            return true;
          }
          const phoneNumber = parsePhoneNumberFromString(value);
          if (!phoneNumber) {
            return false;
          }
          return phoneNumber.isValid();
        }
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_phone_number")
        })
      )
  });

  const methods = useForm({
    defaultValues: {
      companyName: "",
      contactName: "",
      contactPhoneNumber: ""
    },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  const handleCompleteProfileForm = async (
    formData: CompleteProfileFormData
  ) => {
    const { companyName, contactName, contactPhoneNumber } = formData;
    setIsLoading(true);
    const companyDocumentReference = doc(
      db,
      `${FIRESTORE_COLLECTIONS.COMPANIES}/${companyInfo?.company_id}`
    );

    if (contactPhoneNumber && contactPhoneNumber !== user?.phoneNumber) {
      const setUserPhoneNumber = httpsCallable(functions, "setUserPhoneNumber");
      await setUserPhoneNumber(contactPhoneNumber);
    }

    const companyData = {
      name: prepareMultiLingual(companyName),
      contact_name: prepareMultiLingual(contactName),
      updated_at: Timestamp.now()
    };
    await updateDoc(companyDocumentReference, companyData);
    setIsLoading(false);
    navigate(ROUTES.root);
    toast.kampai(intl.get("t_company_profile_success"), "success");
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container maxWidth={1440} alignSelf="center">
          <Grid
            item
            xs={12}
            md={6}
            position={{ md: "sticky" }}
            height={{ md: "100vh" }}
            overflow="hidden"
            top={{ md: 0 }}>
            <Box zIndex={1} mx={1.25} my={2.5} left={{ md: "5%", lg: "10%" }}>
              <MuiLink component={Link} to={ROUTES.root}>
                <StyledLogo
                  variant="square"
                  src={Tokhimo_Logo}
                  alt={intl.get("t_alt_tokhimo_logo")}
                />
              </MuiLink>
            </Box>
            <Box display={{ xs: "none", md: "block" }}>
              <StyledCompleteProfileImage
                src={CompleteProfile_BG}
                alt={intl.get("t_alt_signup_background")}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            zIndex={2}
            position="relative"
            mt={{ xs: 4, md: "calc(20vh - 1.5rem)" }}>
            <Container>
              <Typography variant="h3" align="center">
                {intl.get("t_complete_profile_heading")}
              </Typography>
              <Box
                noValidate
                component="form"
                onSubmit={handleSubmit(handleCompleteProfileForm)}
                my={3.5}>
                <TextField
                  control={control}
                  name="companyName"
                  label={intl.get("t_general_company_name")}
                  placeholder={intl.get("t_general_company_name")}
                  required
                  disabled={isLoading}
                />
                <TextField
                  control={control}
                  name="contactName"
                  label={intl.get("t_general_contact_name")}
                  placeholder={intl.get("t_general_contact_name")}
                  required
                  disabled={isLoading}
                />
                <TelephoneInput
                  control={control}
                  name="contactPhoneNumber"
                  label={intl.get("t_complete_profile_contact_phone_number")}
                  placeholder={intl.get("t_general_phone_number")}
                  required
                  disabled={isLoading}
                />
                <Stack mt={3} display={{ md: "block" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    loading={isLoading}>
                    <Box my={{ xs: 0.5, md: "unset" }} mx={{ md: 2 }}>
                      {intl.get("t_general_next")}
                    </Box>
                  </Button>
                </Stack>
              </Box>
              <Typography
                align="center"
                mt={7.5}
                mb={{ xs: "calc(10vh)", sm: "calc(20vh)" }}>
                © {dayjs().format("YYYY")}
                {intl.get("t_general_copyright")}
              </Typography>
            </Container>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CompleteProfile;
