import { useState } from "react";

import { Box, Divider, Typography } from "@mui/material";

import { Tab, TabPanel, Tabs } from "@components/Tab";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const TAB_DATA = [
  {
    label: "Company Information",
    value: 0,
    component: (
      <Typography color="text.primary">
        Hello Saved Jobs Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Possimus, eum?
      </Typography>
    )
  },
  {
    label: "Company Type",
    value: 1,
    component: (
      <Typography color="text.primary">
        Hello Applied Jobs Lorem ipsum dolor sit, amet Lorem ipsum, dolor sit
        amet consectetur adipisicing elit. Laborum dignissimos illo maiores?
      </Typography>
    )
  },
  {
    label: "Admin Data",
    value: 2,
    component: (
      <Typography color="text.primary">
        Hello Invited Jobs Lorem ipsum, dolor sit amet consectetur adipisicing
        elit. Corrupti alias perspiciatis commodi quas!
      </Typography>
    )
  }
];

const TabPage = () => {
  const [activeTabValue, setActiveTabValue] = useState<number>(0);

  const handleTabChange = (newValue: number) => setActiveTabValue(newValue);

  return (
    <Box>
      <Heading text="Tabs" />
      <Tabs value={activeTabValue} aria-label="settings">
        {TAB_DATA.map((singleTabData) => {
          const { label, value } = singleTabData;
          return (
            <Tab
              value={value}
              isActive={activeTabValue === value}
              changeHandler={handleTabChange}
              key={value}>
              {label}
            </Tab>
          );
        })}
      </Tabs>
      <Divider />

      {TAB_DATA.map(({ component }, index) => {
        return (
          <TabPanel value={activeTabValue} index={index} key={index}>
            {component}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default TabPage;
