import CandidateFooter from "@components/CandidateFooter";

const CandidatePageFooter = () => {
  return (
    <>
      <CandidateFooter />
    </>
  );
};

export default CandidatePageFooter;
