import { useState } from "react";

import intl from "react-intl-universal";
import { useNavigate, useSearchParams } from "react-router-dom";

// TODO: Add onClick on sign up and login button and redirect it to signup and login button

import {
  Analytics as AnalyticsIcon,
  Home as HomeIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  Settings as SettingsIcon
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Stack,
  styled,
  Toolbar,
  Typography
} from "@mui/material";
import { useSignOut } from "react-firebase-hooks/auth";

import Button from "@components/Button";
import VerificationEmailSentDialog from "@components/VerificationEmailSentDialog";

import { ROUTES } from "@utils/config";
import { auth } from "@utils/firebase";
import theme, { colorPalette } from "@utils/theme";

import logoWhite from "@assets/logos/logo-white.svg";

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "backgroundColor"
})<{
  backgroundColor?: string;
  color?: string;
}>(({ backgroundColor, color }) => ({
  backgroundColor,
  color,
  "height": "3.5rem",
  "marginBottom": "0.875rem",
  ":hover": {
    backgroundColor: colorPalette.blue.hover[12]
  }
}));

const StyledTypography = styled(Typography)(({ theme }) => {
  return {
    display: "none",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      display: "block"
    }
  };
});

const StyledLogoutIcon = styled(LogoutIcon)(({ theme }) => {
  return {
    display: "none",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      display: "block"
    }
  };
});

const Header = ({ isLoggedIn = false }: { isLoggedIn?: boolean }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false); // only for mobile
  const navigate = useNavigate();
  const [signOut] = useSignOut(auth);
  const [searchParams] = useSearchParams();

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      {isLoggedIn &&
      searchParams.has("send_verification_email") &&
      searchParams.get("send_verification_email") ? (
        <VerificationEmailSentDialog />
      ) : (
        false
      )}
      {/* FIXME: remove zIndex once dialog component fix */}
      <AppBar position="sticky" sx={{ zIndex: 500 }}>
        <Toolbar>
          <Box
            component="img"
            py={1}
            height={{ xs: 80, sm: 100 }}
            width={{ xs: 80, sm: 100 }}
            src={logoWhite}
            alt="tokhimo-logo"
          />
          <Box ml="auto">
            {isLoggedIn ? (
              <>
                <Stack
                  direction="row"
                  columnGap={{ xs: 3, md: 7.5 }}
                  alignItems="center">
                  <StyledTypography
                    variant="subtitle4"
                    color={
                      location.pathname === ROUTES.home
                        ? theme.palette.primary.main
                        : theme.palette.text.secondary
                    }
                    onClick={() => navigate(ROUTES.home)}>
                    {intl.get("t_general_home")}
                  </StyledTypography>
                  <StyledTypography
                    variant="subtitle4"
                    color={
                      location.pathname === ROUTES.candidateList
                        ? theme.palette.primary.main
                        : theme.palette.text.secondary
                    }
                    onClick={() => navigate(ROUTES.candidateList)}>
                    {intl.get("t_general_candidate_list")}
                  </StyledTypography>
                  <StyledTypography
                    variant="subtitle4"
                    color={
                      location.pathname === ROUTES.settings
                        ? theme.palette.primary.main
                        : theme.palette.text.secondary
                    }
                    onClick={() => navigate(ROUTES.settings)}>
                    {intl.get("t_general_settings")}
                  </StyledTypography>
                  {/* FIXME: hide switch for now */}
                  {/* <Switch
                    defaultChecked
                    icon={
                      <Box
                        component="img"
                        height={28}
                        width={28}
                        src={lightModeIcon}
                        alt="light-mode-icon"
                      />
                    }
                    checkedIcon={
                      <Box
                        component="img"
                        height={28}
                        width={28}
                        src={darkModeIcon}
                        alt="dark-mode-icon"
                      />
                    }
                  /> */}
                  <Box onClick={handleDrawerToggle} display={{ md: "none" }}>
                    <MenuIcon fontSize="large" color="primary" />
                  </Box>
                  <StyledLogoutIcon onClick={() => signOut()} />
                </Stack>
              </>
            ) : (
              <Stack
                direction="row"
                columnGap={{ xs: 2.5, md: 5 }}
                alignItems="center">
                <StyledTypography variant="subtitle4">
                  {intl.get("t_general_home")}
                </StyledTypography>
                <Button onClick={() => navigate(ROUTES.register)}>
                  {intl.get("t_general_sign_up")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate(ROUTES.login)}>
                  {intl.get("t_general_sign_in")}
                </Button>
              </Stack>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* drawer for mobile start */}
      <Box component="nav">
        <Drawer
          anchor="top"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true //Better open performance on mobile.
          }}>
          <Box onClick={handleDrawerToggle}>
            <List disablePadding>
              <StyledListItem
                backgroundColor={
                  location.pathname === ROUTES.home
                    ? theme.palette.primary.main
                    : theme.palette.background.paper
                }
                color={
                  location.pathname === ROUTES.home
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary
                }
                disablePadding
                onClick={() => navigate(ROUTES.home)}>
                <ListItemButton>
                  <HomeIcon />
                  <Typography ml={1.5} variant="subtitle4" color="inherit">
                    {intl.get("t_general_home")}
                  </Typography>
                </ListItemButton>
              </StyledListItem>
              <StyledListItem
                backgroundColor={
                  location.pathname === ROUTES.candidateList
                    ? theme.palette.primary.main
                    : theme.palette.background.paper
                }
                color={
                  location.pathname === ROUTES.candidateList
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary
                }
                disablePadding
                onClick={() => navigate(ROUTES.candidateList)}>
                <ListItemButton>
                  <AnalyticsIcon />
                  <Typography ml={1.5} variant="subtitle4" color="inherit">
                    {intl.get("t_general_candidate_list")}
                  </Typography>
                </ListItemButton>
              </StyledListItem>
              <StyledListItem
                backgroundColor={
                  location.pathname === ROUTES.settings
                    ? theme.palette.primary.main
                    : theme.palette.background.paper
                }
                color={
                  location.pathname === ROUTES.settings
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary
                }
                disablePadding
                onClick={() => navigate(ROUTES.settings)}>
                <ListItemButton>
                  <SettingsIcon />
                  <Typography ml={1.5} variant="subtitle4" color="inherit">
                    {intl.get("t_general_settings")}
                  </Typography>
                </ListItemButton>
              </StyledListItem>
              <StyledListItem disablePadding onClick={() => signOut()}>
                <ListItemButton>
                  <LogoutIcon />
                  <Typography ml={1.5} variant="subtitle4" color="inherit">
                    {intl.get("t_general_logout")}
                  </Typography>
                </ListItemButton>
              </StyledListItem>
            </List>
          </Box>
        </Drawer>
      </Box>
      {/* drawer for mobile end */}
    </>
  );
};

export default Header;
