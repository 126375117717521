import { ReactNode, useEffect, useState } from "react";

import { Control, Controller } from "react-hook-form";
import intl from "react-intl-universal";

import {
  ArrowDropDownRounded as ArrowDropDownRoundedIcon,
  CloseRounded as CloseRoundedIcon
} from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  InputAdornment,
  InputLabel,
  TextField
} from "@mui/material";

import KeyLabel from "@interfaces/components/KeyLabel";

interface TypeableSelectProps {
  options: Array<KeyLabel>;
  placeholder?: string;
  name: string;
  multiple?: boolean;
  startAdornment?: string | ReactNode;
  endAdornment?: ReactNode;
  control: Control<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  disabled?: boolean;
  label?: string;
  required?: boolean;
  helperText?: string;
  getOptions?: (str: string) => Promise<Array<KeyLabel>>;
  readOnly?: boolean;
  disablePortal?: boolean;
}

const TypeableSelect = ({
  options: optionsProp,
  placeholder,
  startAdornment = "",
  endAdornment,
  required = false,
  label,
  multiple = false,
  name,
  control,
  getOptions,
  disabled = false,
  helperText = "",
  readOnly = false,
  disablePortal = true
}: TypeableSelectProps) => {
  const [options, setOptions] = useState<Array<KeyLabel>>([]);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isNoOptions, setNoOptions] = useState<boolean>(false);

  useEffect(() => {
    const newOptions: Array<KeyLabel> =
      optionsProp.map((singleOption) => ({
        key: singleOption.key.toString(),
        label: singleOption.label
      })) ?? [];
    setOptions(newOptions);
  }, [optionsProp]);

  useEffect(() => {
    let active = true;

    if (!getOptions || open === false) {
      return;
    }
    if (inputValue === "") {
      setOpen(false);
    }

    setLoading(true);
    setNoOptions(false);
    setOpen(true);

    (async () => {
      const newOptions = await getOptions(inputValue);
      if (active) {
        setOptions(newOptions);
        if (newOptions.length > 0) {
          setNoOptions(false);
        } else {
          setNoOptions(true);
        }
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [inputValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        if (value === undefined) {
          onChange(multiple ? [] : null);
        }
        const selectedValues: Array<KeyLabel> = value
          ? value
          : multiple
          ? []
          : null;
        return (
          <>
            {label ? (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}>
                {label}
              </InputLabel>
            ) : (
              false
            )}
            <Autocomplete
              readOnly={readOnly}
              clearIcon={<CloseRoundedIcon />}
              popupIcon={<ArrowDropDownRoundedIcon />}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              loading={loading}
              loadingText={
                isNoOptions
                  ? intl.get("t_auto_complete_no_options")
                  : intl.get("t_auto_complete_loading")
              }
              options={options}
              getOptionLabel={(singleOption) => singleOption.label}
              isOptionEqualToValue={() => {
                return false;
              }}
              onChange={(_, value) => {
                onChange(value);
              }}
              onInputChange={(_, value) => {
                setInputValue(value);
              }}
              clearOnBlur={false}
              clearOnEscape
              disablePortal={disablePortal}
              disabled={disabled}
              value={selectedValues as any} // eslint-disable-line @typescript-eslint/no-explicit-any
              multiple={multiple}
              renderTags={(_, getTagProps) =>
                selectedValues
                  .filter((singleSelectedValue, index, selectedValues) => {
                    if (index === selectedValues.length - 1) {
                      return singleSelectedValue.label !== inputValue;
                    } else {
                      return true;
                    }
                  })
                  .map((singleSelectedValue, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      label={singleSelectedValue.label}
                    />
                  ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  placeholder={placeholder}
                  error={!!error}
                  helperText={error ? error.message : helperText}
                  InputProps={{
                    ...params.InputProps,
                    readOnly,
                    startAdornment: multiple ? (
                      params.InputProps.startAdornment
                    ) : (
                      <InputAdornment position="start">
                        {startAdornment}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading && !isNoOptions ? (
                          <InputAdornment position="end">
                            <CircularProgress color="inherit" size={24} />
                          </InputAdornment>
                        ) : (
                          false
                        )}
                        {multiple ? (
                          params.InputProps.endAdornment
                        ) : (
                          <InputAdornment position="end">
                            {endAdornment}
                          </InputAdornment>
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
          </>
        );
      }}
    />
  );
};

export default TypeableSelect;
