import { ReactNode, useEffect, useState } from "react";

import { Box, CircularProgress, Button as MuiButton } from "@mui/material";

interface ButtonProps {
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary";
  variant?: "contained" | "outlined" | "text";
  children?: ReactNode;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  type?: "submit" | "reset";
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  dataTest?: string;
  loading?: boolean; // This defines the loading state to be controlled by the parent.
  isLoadable?: boolean; // This tells whether the button is loadable or not.
  formId?: string;
}

const Button = ({
  size = "medium",
  color = "primary",
  variant = "contained",
  disabled = false,
  dataTest = "button",
  startAdornment = null,
  endAdornment = null,
  loading = false,
  isLoadable = false,
  children = "",
  onClick = () => {
    /* no-op */
  },
  formId,
  ...rest
}: ButtonProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <MuiButton
      size={size}
      variant={variant}
      color={color}
      disabled={disabled}
      startIcon={startAdornment}
      endIcon={endAdornment}
      data-testid={dataTest}
      onClick={() => {
        isLoadable ? setLoading(true) : false;
        if (!loading && !isLoading) {
          onClick();
        }
      }}
      form={formId}
      {...rest}>
      <Box visibility={isLoading ? "hidden" : "visible"}>{children}</Box>
      {isLoading ? (
        <CircularProgress
          size={size === "large" ? 23 : size === "medium" ? 21 : 20}
          color="inherit"
          sx={{
            position: "absolute"
          }}
        />
      ) : (
        false
      )}
    </MuiButton>
  );
};

export default Button;
