import { ReactNode, useState } from "react";

import { ExpandMoreTwoTone } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  styled,
  Typography
} from "@mui/material";

import Status from "@components/Status";

import { USER_TEST_STATUS } from "@utils/config";
import theme from "@utils/theme";

interface Row {
  nameAndEmail: {
    name: string;
    email: string;
  };
  nationality: string;
  invitedDate: string;
  takenDate: string;
  status: typeof USER_TEST_STATUS[keyof typeof USER_TEST_STATUS];
  result: string;
}

const StyledExpandIcon = styled(ExpandMoreTwoTone)<{ isExpand: boolean }>(
  ({ theme, isExpand }) => ({
    color: isExpand ? theme.palette.primary.main : theme.palette.text.secondary
  })
);

const AccordionRow = ({
  leftContent,
  rightContent
}: {
  leftContent: string | ReactNode;
  rightContent: ReactNode;
}) => {
  return (
    <>
      <Grid item xs={6}>
        <Typography variant="subtitle5">{leftContent}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption">{rightContent}</Typography>
      </Grid>
    </>
  );
};

const MobileCandidateListTable = ({ rowsData }: { rowsData: Array<Row> }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (panel: string) => (e: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      border={`1px solid ${theme.palette.text.secondary}`}
      borderRadius={2.5}>
      <Box
        borderRadius="10px 10px 0px 0px"
        padding="1.8rem 1rem"
        bgcolor="primary.main">
        <Typography color="text.primary" variant="subtitle5">
          Name And Email Address
        </Typography>
      </Box>
      {rowsData?.map((singleRowData: Row, index: number) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            disableGutters
            elevation={0}>
            <AccordionSummary
              expandIcon={
                <StyledExpandIcon isExpand={expanded === `panel${index}`} />
              }
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}>
              <Box>
                <Typography variant="subtitle5">
                  {singleRowData?.nameAndEmail?.name}
                </Typography>
                <Grid item xs={6}>
                  <Typography variant="caption">
                    {singleRowData?.nameAndEmail?.email}
                  </Typography>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container rowSpacing={3} alignItems="center">
                <AccordionRow
                  leftContent="Nationality"
                  rightContent={singleRowData.nationality}
                />
                <AccordionRow
                  leftContent="Invited Date"
                  rightContent={singleRowData.invitedDate}
                />
                <AccordionRow
                  leftContent="Taken Date"
                  rightContent={singleRowData?.takenDate}
                />
                <AccordionRow
                  leftContent="Status"
                  rightContent={
                    singleRowData?.status ? (
                      <Status label={singleRowData?.status} />
                    ) : (
                      ""
                    )
                  }
                />
                <AccordionRow leftContent="Result(PDF)" rightContent="Export" />
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default MobileCandidateListTable;
