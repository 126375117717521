import React, { ChangeEvent } from "react";

import { Pagination as MuiPagination } from "@mui/material";

interface CustomPaginationProps {
  count?: number;
  page?: number;
  onChange?: (event: ChangeEvent<unknown>, value: number) => void;
  shape?: "rounded";
  dataTest?: string;
}

const Pagination = ({
  shape = "rounded",
  count = 1,
  dataTest = "pagination",
  ...rest
}: CustomPaginationProps) => {
  return (
    <MuiPagination
      shape={shape}
      count={count}
      data-testid={dataTest}
      {...rest}
    />
  );
};

export default Pagination;
