import { MouseEvent, ReactNode, useEffect, useState } from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  IconButton,
  Dialog as MuiDialog,
  Stack,
  styled,
  Typography
} from "@mui/material";

import { DIALOG_ACTION } from "@utils/config";

interface DialogProps {
  title?: string;
  showCloseBtn?: boolean;
  isPersistent?: boolean;
  isBackdropBlur?: boolean;
  isStopEventPropagation?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  initiator: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (handleAgree?: any, handleCancel?: any) => ReactNode;
  onClose?: (reason: keyof typeof DIALOG_ACTION) => void;
  isDefaultOpen?: boolean;
}

const StyledDialogHeader = styled(Stack)({
  maxWidth: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: "1rem",
  paddingBottom: "1rem"
});

const StyledDialogTitle = styled(Typography)({
  wordBreak: "break-all"
});

const StyledBlurredBackdrop = styled(Backdrop)({
  backdropFilter: "blur(4px)"
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "auto",
  position: "absolute",
  right: 0,
  top: 0,
  color: theme.palette.text.primary
}));

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.text.secondary}`,
      padding: "0.625rem",
      [theme.breakpoints.between("sm", "lg")]: {
        padding: "1.25rem"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "2.5rem"
      }
    }
  }
}));

const Dialog = ({
  title,
  showCloseBtn = true,
  isPersistent = false,
  maxWidth = "md",
  initiator,
  isBackdropBlur = false,
  isStopEventPropagation = false,
  children,
  onClose = () => {
    /* no op */
  },
  isDefaultOpen = false
}: DialogProps) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(isDefaultOpen);
  }, [isDefaultOpen]);

  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    if (isStopEventPropagation) {
      event.stopPropagation();
    }
    setOpen(true);
  };

  const handleClose = (
    event: MouseEvent,
    reason?: "backdropClick" | "escapeKeyDown"
  ) => {
    event.preventDefault();
    if (isStopEventPropagation) {
      event.stopPropagation();
    }
    if (isPersistent && !!reason) {
      return;
    } else {
      onClose(DIALOG_ACTION.CANCEL);
      setOpen(false);
    }
  };

  // Handle Dialog Actions
  const handleAgree = () => {
    onClose(DIALOG_ACTION.AGREE);
    setOpen(false);
  };

  const handleCancel = () => {
    onClose(DIALOG_ACTION.CANCEL);
    setOpen(false);
  };

  return (
    <>
      <Box onClick={handleOpen}>{initiator}</Box>
      <StyledDialog
        onClick={(e) => {
          if (isStopEventPropagation) {
            e.stopPropagation();
          }
        }}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        slots={{ backdrop: isBackdropBlur ? StyledBlurredBackdrop : Backdrop }}
        maxWidth={maxWidth}
        disableScrollLock
        sx={{ zIndex: 1000 }} //To show below Drawer
      >
        <StyledDialogHeader direction="row">
          {title ? (
            <StyledDialogTitle variant="h5">{title}</StyledDialogTitle>
          ) : (
            false
          )}

          {showCloseBtn ? (
            <StyledIconButton onClick={handleClose} size="large">
              <CloseIcon />
            </StyledIconButton>
          ) : (
            false
          )}
        </StyledDialogHeader>

        <Box>{children(handleAgree, handleCancel)}</Box>
      </StyledDialog>
    </>
  );
};

export default Dialog;
