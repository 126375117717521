import { useEffect } from "react";

import { useForm } from "react-hook-form";
import intl from "react-intl-universal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";

import Button from "@components/Button";
import Ranking from "@components/Ranking";

import useToast from "@hooks/useToast";

import { TEST_SECTION_TYPE } from "@utils/config";
import { findIndex, map } from "@utils/lodash";
import { getInitialValues, getValidationSchema } from "@utils/yupSchema";

import useTestDetails from "@hooks/database/useTestDetailsContext";
import CandidateSubmission from "@interfaces/database/CandidateSubmission";
import RatingQuestion from "@interfaces/database/Question/RatingQuestion";
import QuestionAnswerResponse from "@interfaces/database/QuestionAnswerResponse";
import SectionSubmission from "@interfaces/database/SectionSubmission";

interface SubjectiveThinkingProps {
  questions: Array<RatingQuestion>;
}
const SubjectiveThinking = ({ questions }: SubjectiveThinkingProps) => {
  const [queryParameters] = useSearchParams();
  const { testId, submissionId } = useParams();
  const navigate = useNavigate();

  const toast = useToast();
  const { setSubmissionData, submission } = useTestDetails();

  const token = queryParameters.get("token");
  // current submission section - here use map to copy the data, otherwise it might be update the section reference which will cause data corruption
  const sections: Array<SectionSubmission> =
    submission?.sections?.map((singleSection: SectionSubmission) => {
      return {
        type: singleSection?.type,
        question_answer_responses: singleSection?.question_answer_responses
      };
    }) ?? [];

  const subjectiveThinkingSectionIdx = findIndex(sections, {
    type: TEST_SECTION_TYPE.SUBJECTIVE_THINKING
  });

  const methods = useForm({
    defaultValues: getInitialValues(questions),
    resolver: yupResolver(getValidationSchema(questions))
  });

  const { handleSubmit, control, setValue, reset } = methods;

  useEffect(() => {
    if (subjectiveThinkingSectionIdx !== -1) {
      const subjectiveThinkingSection =
        sections?.[subjectiveThinkingSectionIdx];
      if (subjectiveThinkingSection?.question_answer_responses?.length > 0) {
        // Extract question and answer data and convert it into an object
        const questionAnswerMap =
          subjectiveThinkingSection.question_answer_responses.reduce(
            (acc: Record<string, number>, response: QuestionAnswerResponse) => {
              if (response?.question_id) {
                acc[response.question_id] = Number(
                  response.user_entered_answer
                );
              }
              return acc;
            },
            {}
          );
        // Reset the form with the extracted data
        reset(questionAnswerMap);
      }
    }
  }, [submission?.sections]);

  const handleSubmissionUpdateSuccess = (submissionId: string) => {
    navigate(
      `/candidates/${testId}/${submissionId}/tests/sections/3?token=${token}`
    );
  };

  const handleSubmissionUpdateFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = (fromData: Record<string, string>) => {
    const questionAnswerResponse: Array<QuestionAnswerResponse> = map(
      fromData,
      (user_entered_answer, question_id) => ({
        question_id,
        user_entered_answer
      })
    );

    if (subjectiveThinkingSectionIdx === -1) {
      // if section not found then push new section
      sections.push({
        question_answer_responses: questionAnswerResponse,
        type: TEST_SECTION_TYPE.SUBJECTIVE_THINKING
      });
    } else {
      // update submission
      sections[subjectiveThinkingSectionIdx].question_answer_responses =
        questionAnswerResponse;
    }
    try {
      const submissionData: CandidateSubmission = {
        ...submission,
        sections: sections,
        current_active_step: "3", // FIXME: update active step
        updated_at: Timestamp.now()
      };

      if (setSubmissionData && testId && submissionId) {
        setSubmissionData(
          testId,
          submissionId,
          submissionData,
          handleSubmissionUpdateSuccess,
          handleSubmissionUpdateFail
        );
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  return (
    <>
      <Typography variant="h4">Subjective Thinking</Typography>
      <Typography variant="body1" mt={2}>
        Choose out of 5 options: Closer to A, Closer to B
      </Typography>
      <Box
        noValidate
        id="cultural-match-form"
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}>
        {questions.map((singleQuestion: RatingQuestion) => {
          return (
            <Box my={6} key={singleQuestion?.id ?? ""}>
              <Ranking
                name={singleQuestion?.id ?? ""}
                required
                label={singleQuestion?.question?.en ?? ""}
                control={control}
                setValue={setValue}
              />
            </Box>
          );
        })}
        <Button type="submit">Next Step</Button>
      </Box>
    </>
  );
};

export default SubjectiveThinking;
