import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import intl from "react-intl-universal";
import { Link, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Button as MuiButton,
  Link as MuiLink,
  Stack,
  styled,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import {
  useCreateUserWithEmailAndPassword,
  useSignInWithGoogle
} from "react-firebase-hooks/auth";
import * as yup from "yup";

import Button from "@components/Button";
import Loader from "@components/Loader";
import TextField from "@components/TextField";

import { ROUTES } from "@utils/config";
import { auth } from "@utils/firebase";
import { colorPalette } from "@utils/theme";

import Google from "@assets/icons/google.svg";
import SignUp_BG from "@assets/images/signUp.svg";
import Tokhimo_Logo from "@assets/logos/logo-white.svg";

interface RegisterForm {
  email: string;
  password: string;
}

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 62,
    width: 62
  },
  [theme.breakpoints.up("sm")]: {
    height: 55,
    width: 55
  },
  [theme.breakpoints.up("lg")]: {
    height: 62,
    width: 62
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledRightContentGridContainer = styled(Grid)(({ theme }) => ({
  position: "relative",
  marginTop: 3.75,
  [theme.breakpoints.between("sm", "md")]: {
    top: "calc(20vh - 1.5rem)",
    transform: "translateY(-25%)"
  }
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  "background": "transparent",
  "color": theme.palette.text.secondary,
  "&.MuiDivider-root::before": {
    borderTop: "thin solid",
    borderTopColor: theme.palette.text.secondary
  },
  "&.MuiDivider-root::after": {
    borderTop: "thin solid",
    borderTopColor: theme.palette.text.secondary
  }
}));

const StyledSignUpImage = styled("img")({
  width: "95%",
  position: "relative",
  top: "calc(20vh - 1.5rem)",
  transform: "translateY(-25%)"
});

const StyledGoogleSignUpButton = styled(MuiButton)({
  "backgroundColor": colorPalette.coral.base,
  "marginBottom": "1.5rem",
  "width": "100%",
  "&.MuiButton-containedPrimary": {
    "&:hover": {
      backgroundColor: colorPalette.coral.base
    }
  }
});

const Register = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [
    createUserWithEmailAndPassword,
    ,
    isCreateUserWithEmailAndPasswordLoading,
    createUserWithEmailAndPasswordError
  ] = useCreateUserWithEmailAndPassword(auth);

  // FIXME: will recheck after PR is raised
  const [signInWithGoogle, , isSignInWithGoogleLoading, signInWithGoogleError] =
    useSignInWithGoogle(auth);

  // validation schema
  const schema = yup.object({
    email: yup
      .string()
      .email()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_email_address")
        })
      ),
    password: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_general_password")
      })
    )
  });

  const defaultFormValues: RegisterForm = {
    email: "",
    password: ""
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  const handleRegisterForm = async (data: RegisterForm) => {
    try {
      await createUserWithEmailAndPassword(data.email, data.password);
    } catch (error) {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  };

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate(ROUTES.completedProfile);
      }
    });

    return () => unregisterAuthObserver();
  }, [navigate]);

  useEffect(() => {
    const code = createUserWithEmailAndPasswordError?.code;
    if (code === "auth/invalid-email") {
      setErrorMessage(intl.get("t_auth_error_wrong_email"));
    } else if (code === "auth/email-already-in-use") {
      setErrorMessage(intl.get("t_auth_error_email_already_in_use"));
    } else if (code === "auth/weak-password") {
      setErrorMessage(intl.get("t_auth_error_weak_password"));
    } else if (
      code === "auth/internal-error" ||
      code === "auth/too-many-requests"
    ) {
      setErrorMessage(intl.get("t_general_internal_error"));
    } else {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  }, [createUserWithEmailAndPasswordError]);

  useEffect(() => {
    const code = signInWithGoogleError?.code;
    if (code === "auth/internal-error" || code === "auth/too-many-requests") {
      setErrorMessage(intl.get("t_general_internal_error"));
    } else {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  }, [signInWithGoogleError]);

  return (
    <>
      {isSignInWithGoogleLoading || isCreateUserWithEmailAndPasswordLoading ? (
        <Loader />
      ) : (
        <Grid container maxWidth={1440} alignSelf="center">
          <Grid
            item
            xs={12}
            md={6}
            position={{ md: "sticky" }}
            height={{ md: "100vh" }}
            top={{ md: 0 }}>
            <Box zIndex={1} mx={1.25} my={2.5} left={{ md: "5%", lg: "10%" }}>
              <MuiLink component={Link} to={ROUTES.root}>
                <StyledLogo
                  variant="square"
                  src={Tokhimo_Logo}
                  alt={intl.get("t_alt_tokhimo_logo")}
                />
              </MuiLink>
            </Box>
            <Box display={{ xs: "none", md: "block" }}>
              <StyledSignUpImage
                src={SignUp_BG}
                alt={intl.get("t_alt_signup_background")}
              />
            </Box>
          </Grid>
          <StyledRightContentGridContainer item xs={12} md={5} zIndex={2}>
            <Container>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                mt={{ md: 7.5 }}>
                <Typography variant="body1" color="text.secondary">
                  {intl.get("t_register_already_have_account")}&nbsp;
                </Typography>
                <Typography variant="subtitle4">
                  <MuiLink component={Link} to={ROUTES.login} underline="none">
                    {intl.get("t_register_sing_in")}
                  </MuiLink>
                </Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="center">
                <Box>
                  <Typography
                    variant="h4"
                    color="primary.main"
                    align="center"
                    mt={5}
                    mb={3}
                    width={{ xs: 240, sm: "100%" }}>
                    {intl.get("t_register_welcome_heading")}
                  </Typography>
                </Box>
              </Stack>
              <Typography variant="h3" align="center">
                {intl.get("t_register_sign_up")}
              </Typography>
              {createUserWithEmailAndPasswordError || signInWithGoogleError ? (
                <Stack mt={2} alignItems="center">
                  <Typography variant="subtitle4" color="error.main">
                    {errorMessage}
                  </Typography>
                </Stack>
              ) : (
                false
              )}
              <Box
                noValidate
                component="form"
                onSubmit={handleSubmit(handleRegisterForm)}
                my={3.5}>
                <TextField
                  type="email"
                  name="email"
                  control={control}
                  label={intl.get("t_general_email_address")}
                  placeholder={intl.get("t_register_email_address_placeholder")}
                  required
                />
                <TextField
                  control={control}
                  name="password"
                  label={intl.get("t_register_password")}
                  placeholder={intl.get("t_register_password_placeholder")}
                  required
                  type="password"
                />
                <Stack mt={1.5}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large">
                    <Box my={0.5}>{intl.get("t_register_sign_up")}</Box>
                  </Button>
                </Stack>
              </Box>
              <StyledDivider>{intl.get("t_register_or")}</StyledDivider>
              <Stack mt={3}>
                <StyledGoogleSignUpButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    signInWithGoogle();
                  }}>
                  <img src={Google} alt={intl.get("t_alt_google_logo")} />
                  &nbsp;&nbsp;{intl.get("t_signup_with_google")}
                </StyledGoogleSignUpButton>
              </Stack>
              <Typography
                variant="body1"
                component={Stack}
                flexDirection="row"
                flexWrap="wrap"
                mb={{ xs: 17.9, md: 12.5 }}>
                {intl.get("t_register_tnc_content")}&nbsp;
                {/* FIXME: will update link later */}
                <MuiLink component={Link} to={ROUTES.root}>
                  {intl.get("t_register_user_terms")}
                </MuiLink>
                &nbsp;{intl.get("t_register_tnc_content_and")}&nbsp;
                {/* FIXME: will update link later */}
                <MuiLink component={Link} to={ROUTES.root}>
                  {intl.get("t_register_privacy_policy")}
                </MuiLink>
              </Typography>
              <Typography align="center" my={7.5}>
                © {dayjs().format("YYYY")}
                {intl.get("t_general_copyright")}
              </Typography>
            </Container>
          </StyledRightContentGridContainer>
        </Grid>
      )}
    </>
  );
};

export default Register;
