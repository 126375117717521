// FIXME: add i18
import { useEffect, useState } from "react";

import { useForm, useWatch } from "react-hook-form";
import intl from "react-intl-universal";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import * as yup from "yup";

import Button from "@components/Button";
import Radio from "@components/Radio";

import useToast from "@hooks/useToast";

import { COMPANY_TYPE } from "@utils/config";
import theme from "@utils/theme";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import CompanyProfile from "@interfaces/database/CompanyProfile";

// FIXME: will check later. currently string disappear on refresh after i18n added
const companyTypeOptions = [
  {
    label:
      "Start-up: Typically close-knit, with a focus on personal relationships, flexibility, and a hands-on approach to management.",
    value: COMPANY_TYPE.TYPE_A
  },
  {
    label:
      "Mid-Size Regional Company: Balancing elements of both small and large organizations, often with a collaborative atmosphere, standardized processes, and a sense of community.",
    value: COMPANY_TYPE.TYPE_B
  },
  {
    label:
      "Large National Corporation:  Emphasis on structure, defined roles, and established processes, with a mix of centralized decision-making and some regional autonomy.",
    value: COMPANY_TYPE.TYPE_C
  },
  {
    label:
      "Multi-National Corporation:  Globally diverse and collaborative, with a focus on standardized processes, adherence to corporate policies, and a need for efficient communication across borders.",
    value: COMPANY_TYPE.TYPE_D
  },
  // FIXME: phase-2 customized type - ui link:- https://www.figma.com/file/Qm9W6f7hnTVzZPmxxDZliz/Tokhimo-Aptitude-Test?type=design&node-id=2135-98489&mode=design&t=DOEdWd5AavyV1oiu-0
  {
    label: "Customized Companies type",
    value: COMPANY_TYPE.CUSTOMIZE
  }
];

// const companyTypeOptions = [
//   {
//     label: `${intl.get("t_company_type_start_up")}`,
//     value: COMPANY_TYPE.TYPE_A
//   },
//   {
//     label: `${intl.get("t_company_type_mid_size")}`,
//     value: COMPANY_TYPE.TYPE_B
//   },
//   {
//     label: `${intl.get("t_company_type_large_national")}`,
//     value: COMPANY_TYPE.TYPE_C
//   },
//   {
//     label: `${intl.get("t_company_type_multi_national")}`,
//     value: COMPANY_TYPE.TYPE_D
//   }
//   // FIXME: phase-2 customized type - ui link:- https://www.figma.com/file/Qm9W6f7hnTVzZPmxxDZliz/Tokhimo-Aptitude-Test?type=design&node-id=2135-98489&mode=design&t=DOEdWd5AavyV1oiu-0
//   // {
//   //   label: "Customized Companies type",
//   //   value: COMPANY_TYPE.CUSTOMIZE
//   // }
// ];

interface CompanyTypeForm {
  companyType: typeof COMPANY_TYPE[keyof typeof COMPANY_TYPE] | string;
}

const CompanyType = () => {
  const companyDetails = useCompanyDetails();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const schema = yup.object({
    companyType: yup.string().required("Company type is required")
  });

  const methods = useForm({
    defaultValues: {
      companyType: "" // FIXME: add default company type and remove string type from interface
    },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset } = methods;
  const companyTypeWatch = useWatch({ control, name: "companyType" });

  useEffect(() => {
    reset({
      companyType: companyDetails.value?.company_type
    });
  }, [companyDetails.value?.company_type]);

  const handleCompanyDetailsUpdateSuccess = () => {
    setIsLoading(false);
    toast.kampai(intl.get("t_company_information_update_success"), "success");
  };

  const handleCompanyDetailsUpdateFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = async (data: CompanyTypeForm) => {
    setIsLoading(true);
    const updatedCompanyType: CompanyProfile = {
      ...companyDetails.value,
      company_type:
        data.companyType as typeof COMPANY_TYPE[keyof typeof COMPANY_TYPE],
      updated_at: Timestamp.now()
    };
    if (companyDetails?.setValue) {
      companyDetails.setValue(
        updatedCompanyType,
        handleCompanyDetailsUpdateSuccess,
        handleCompanyDetailsUpdateFail
      );
    }
  };
  return (
    <Box noValidate component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="h4" mb={5}>
        {intl.get("t_company_type")}
      </Typography>
      <Stack rowGap={4}>
        <Stack rowGap={1}>
          <Typography variant="subtitle3">
            {intl.get("t_company_type_what_question")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.get("t_company_type_what_answer")}
          </Typography>
        </Stack>
        <Stack rowGap={1}>
          <Typography variant="subtitle3">
            {intl.get("t_company_type_how_decide_question")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.get("t_company_type_how_decide_answer")}
          </Typography>
        </Stack>
        <Stack rowGap={1}>
          <Typography variant="subtitle3">
            {intl.get("t_company_type_how_will_affect_question")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.get("t_company_type_how_will_affect_answer")}
          </Typography>
        </Stack>
        <Stack rowGap={1}>
          <Typography variant="subtitle3">
            {intl.get("t_company_type")}{" "}
            <Typography component="span" color={theme.palette.error.main}>
              *
            </Typography>
          </Typography>
          <Radio
            disabled={isLoading}
            direction="col"
            required
            control={control}
            name="companyType"
            labelAsHtml
            options={companyTypeOptions.map((singleCompanyTypeOption) => {
              return {
                label: (
                  <Typography variant="body2" color="text.secondary" my={1}>
                    {singleCompanyTypeOption.label}
                  </Typography>
                ),
                value: singleCompanyTypeOption.value
              };
            })}
          />
        </Stack>
        <Stack rowGap={1}>
          <Typography variant="subtitle3">
            {intl.get("t_company_type_what_is_customized_question")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.get("t_company_type_what_is_customized_answer")}
          </Typography>
        </Stack>
        <Stack rowGap={1}>
          <Typography variant="subtitle3">
            {intl.get("t_company_type_how_to_customized_type_question")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.get("t_company_type_how_to_customized_type_answer")}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row-reverse" mt={5}>
        {companyTypeWatch === COMPANY_TYPE.CUSTOMIZE ? (
          <Button onClick={() => navigate("/settings/customize-company-type")}>
            {intl.get("t_company_type_customize")}
          </Button>
        ) : (
          <Button type="submit">{intl.get("t_company_type_confirm")}</Button>
        )}
      </Stack>
    </Box>
  );
};

export default CompanyType;
