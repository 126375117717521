import intl from "react-intl-universal";
import { Link, useLocation } from "react-router-dom";

import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material";

const Navigation = () => {
  const location = useLocation();
  return (
    <>
      <Typography variant="h5">
        {intl.get("t_debugger_page_switcher")}:
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Candidate</Typography>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/candidates/welcome"
                selected={location.pathname === "/candidates/welcome"}>
                <ListItemText>Test Welcome</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/candidates/about-your-self"
                selected={location.pathname === "/candidates/about-your-self"}>
                <ListItemText>About YourSelf</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/candidates/tests/randomTestId/section/sectionId"
                selected={
                  location.pathname ===
                  "/candidates/tests/randomTestId/section/sectionId"
                }>
                <ListItemText>Test Questions</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Employers</Typography>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/"
                selected={location.pathname === "/"}>
                <ListItemText>Home</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="candidate-list"
                selected={location.pathname === "candidate-list"}>
                <ListItemText>Candidate List</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="settings"
                selected={location.pathname === "settings"}>
                <ListItemText>Settings</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="settings/company-type"
                selected={location.pathname === "settings/company-type"}>
                <ListItemText>Company Type</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="settings/customize-company-type"
                selected={
                  location.pathname === "settings/customize-company-type"
                }>
                <ListItemText>Customize Company Type</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="settings/admin-data"
                selected={location.pathname === "settings/admin-data"}>
                <ListItemText>Admin Data</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <List>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/not-found"
                selected={location.pathname === "/not-found"}>
                <ListItemText>Not Found</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default Navigation;
