import { Control, Controller, UseFormSetValue } from "react-hook-form";

import { Box, FormLabel, Stack, styled, Typography } from "@mui/material";

import DangerousComponentFromHTML from "@utils/components/DangerousComponentFromHTML";

interface RankingProps {
  name: string;
  required?: boolean;
  label: string;
  totalBox?: number;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  labelAsHtml?: boolean; // It will render form label as HTML
}

const Ranking = ({
  name,
  required,
  label,
  totalBox = 5,
  disabled,
  control,
  setValue,
  labelAsHtml = false
}: RankingProps) => {
  const StyledBox = styled(Box, {
    shouldForwardProp: (prop) =>
      prop !== "index" && prop !== "totalBox" && prop !== "value"
  })<{
    index: number;
    totalBox: number;
    value: number;
  }>(({ theme, index, totalBox, value }) => {
    let backgroundColor = "";
    let borderRadius = "2px";
    let border = `0.0625rem solid ${theme.palette.text.secondary}`;

    if (index === 1) {
      // for first box
      borderRadius = "10px 2px 2px 10px";
    } else if (index === totalBox) {
      // for last box
      borderRadius = "2px 10px 10px 2px";
    }

    // for selected box
    if (index === value) {
      // for selected box
      border = `0.0625rem solid ${theme.palette.primary.main}`;
      backgroundColor = theme.palette.primary.main;
    }
    return {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "height": "3rem",
      "color": theme.palette.text.secondary,
      ":hover": {
        cursor: "pointer",
        border: `0.0625rem solid ${theme.palette.primary.main}`
      },
      border,
      borderRadius,
      backgroundColor,
      "width": "4.875rem",
      [theme.breakpoints.only("md")]: {
        width: "7.125rem"
      },
      [theme.breakpoints.down("sm")]: {
        width: "3.875rem"
      }
    };
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => {
        return (
          <>
            {label ? (
              <FormLabel htmlFor={name} required={required} disabled={disabled}>
                {labelAsHtml ? (
                  <DangerousComponentFromHTML html={label} />
                ) : (
                  label
                )}
              </FormLabel>
            ) : (
              false
            )}
            <Stack mt={1} mb={0.5} width="max-content">
              <Box display="inline-flex" flexDirection="column">
                <Stack direction="row" gap={0.5}>
                  {[...Array(totalBox)].map((_, index) => {
                    index = index + 1;
                    return (
                      <StyledBox
                        index={index}
                        totalBox={totalBox}
                        value={value}
                        onClick={() =>
                          setValue(name, index, { shouldValidate: true })
                        }
                        key={index}>
                        {index}
                      </StyledBox>
                    );
                  })}
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" color="text.primary">
                    Closer to A
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    Closer to B
                  </Typography>
                </Stack>
              </Box>
            </Stack>
            {error && (
              <Typography
                component="div"
                mt={0.25}
                color="error.main"
                variant="caption">
                {error.message}
              </Typography>
            )}
          </>
        );
      }}
    />
  );
};

export default Ranking;
