import QuestionStepper from "@components/QuestionStepper";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const QuestionStepperPage = () => {
  return (
    <>
      <Heading text="Question Stepper" />
      <QuestionStepper totalQuestions={10} completedQuestionsCount={4} />
    </>
  );
};

export default QuestionStepperPage;
