// TODO: This file is only for development purpose
import { useEffect } from "react";

import { Badge } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { useHttpsCallable } from "react-firebase-hooks/functions";

import { FIRESTORE_COLLECTIONS } from "@utils/config";
import { db, functions } from "@utils/firebase";

const BootstrapInitialData = () => {
  const [
    bootstrapInitialData,
    isBootstrapInitialDataLoading,
    bootstrapInitialDataError
  ] = useHttpsCallable(functions, "bootstrapInitialData");

  useEffect(() => {
    try {
      const insertInitialData = async () => {
        const collectionRef = collection(
          db,
          `${FIRESTORE_COLLECTIONS.COMPANY_QUESTIONS}`
        );

        const querySnapshot = await getDocs(collectionRef);
        const count = querySnapshot.size;
        if (count === 0) {
          bootstrapInitialData();
        }
      };
      insertInitialData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, []);

  return isBootstrapInitialDataLoading ? (
    <div>Loading</div>
  ) : bootstrapInitialDataError ? (
    <div>
      Initial Data Setup Status
      <Badge color="error" variant="dot" /> &nbsp; Please check initial
      bootstrap script
    </div>
  ) : bootstrapInitialData.length ? (
    <div>
      Initial Data Setup Status &nbsp; <Badge color="success" variant="dot" />
    </div>
  ) : null;
};

export default BootstrapInitialData;
