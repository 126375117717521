/**
 * Constants related to user status (USER_STATUS)
 *
 * OK       - This is when the user login is fine and user can proceed with any action on the platform.
 * LOCKED   - This is when the user is blocked (for whatever reason) and not allowed login to the platform.
 * NO_TYPE  - This is when a new user registration has happened and the type of the user has not been set in the DB.
 */
export const USER_STATUS = Object.freeze({
  OK: "1001",
  LOCKED: "1002",
  NO_TYPE: "1999"
});

/**
 * Constants related to user status (USER_STATUS)
 *
 * HIDDEN       - This is when the company user hide the question.
 * ACTIVE       - This is when the company user publish the question (by default for now all the question status should be active).
 * DRAFT        - This is when the company user created a question but not published.
 */
export const QUESTION_STATUS = Object.freeze({
  HIDDEN: "5001",
  ACTIVE: "5002",
  DRAFT: "5003"
});
/**
 * Constants related to account types. (USER_TYPE)
 *
 * COMPANY              - This is when type of the account is company and can invite candidates for tests and see other candidates details.
 * OEM                  - This is for internal use, this allows Tokhimo to access any other user's things and do stuff on other user's behalf.
 * COMPANY_DEPENDENT    - This is when a company has invited another person to manage tests on their behalf.
 * SUPER_USER           - This is for Internal Tokhimo users. (They can access anything and bypass any restrictions).
 * NO_TYPE              - This is for a new registration when the type of user is not decided.
 */
export const USER_TYPE = Object.freeze({
  COMPANY: "2001",
  OEM: "2002",
  COMPANY_DEPENDENT: "2003",
  SUPER_USER: "2004",
  NO_TYPE: "2999"
});

/**
 * Constants related to company types. (COMPANY_TYPE)
 * FIXME: update the comments once type is finalized
 * TYPE A           - This is company type A.
 * TYPE B           - This is company type B.
 * TYPE C           - This is company type C.
 * CUSTOMIZE        - This is company type Customize.
 */

export const COMPANY_TYPE = Object.freeze({
  TYPE_A: "3001",
  TYPE_B: "3002",
  TYPE_C: "3003",
  TYPE_D: "3004",
  CUSTOMIZE: "3999"
});

/**
 * Constants related to question types. (QUESTION_TYPE)
 * RATING         - This represents a rating question.
 * RADIO          - This represents a radio button question.
 * CHECKBOX       - This represents a checkbox question.
 * SELECT         - This represents a select dropdown question.
 * MULTI_SELECT   - This represents a multi-select question.
 * TEXT           - This represents a text input question.
 * NUMBER         - This represents a number input question.
 * TEXTAREA       - This represents a textarea input question.
 */

export const QUESTION_TYPE = Object.freeze({
  RATING: "4001",
  RADIO: "4002",
  CHECKBOX: "4003",
  SELECT: "4004",
  MULTI_SELECT: "4005",
  TEXT: "4006",
  NUMBER: "4007",
  TEXTAREA: "4008"
});

/**
 * Constants related to company types. (TEST_SECTION_TYPE)
 * FIXME: update the comments once type is finalized
 * TYPE A           - This is company type A.
 * TYPE B           - This is company type B.
 * TYPE C           - This is company type C.
 * CUSTOMIZE        - This is company type Customize.
 */

export const TEST_SECTION_TYPE = Object.freeze({
  CULTURAL_MATCH: "4001",
  SOCIAL_STYLE: "4002",
  PRIORITY_LIST: "4003",
  EMOTIONAL_INTELLIGENCE: "4004",
  SUBJECTIVE_THINKING: "4005",
  ATTENTION_TO_DETAIL: "4006",
  OTHER: "4999"
});

/**
 * Constants related to test status. (USER_TEST_STATUS)
 *
 * TAKEN        - User has already taken the test
 * INVITED      - User has been invited for the test
 * INPROGRESS   - Test is currently in progress
 * EXPIRED      - Test invitation has expired
 */
export const USER_TEST_STATUS = Object.freeze({
  INVITED: "3001",
  INPROGRESS: "3002",
  EXPIRED: "3003",
  TAKEN: "3999"
});

export const USER_TEST_STATUS_T_LABELS = Object.freeze({
  [USER_TEST_STATUS.INVITED]: "t_user_test_status_invited",
  [USER_TEST_STATUS.INPROGRESS]: "t_user_test_status_in_progress",
  [USER_TEST_STATUS.EXPIRED]: "t_user_test_status_expired",
  [USER_TEST_STATUS.TAKEN]: "t_user_test_status_taken"
});

/**
 * Constants related to dialog(modal) actions. (DIALOG_ACTION)
 *
 * AGREE   - To AGREE/CONFIRM and close dialog
 * CANCEL  - To CANCEL and close dialog
 */
export const DIALOG_ACTION = Object.freeze({
  AGREE: "AGREE",
  CANCEL: "CANCEL"
});

/**
 * Constants related to Environment Types
 *
 * LOCAL   - For when the environment is locally served.
 * PREVIEW - For when the environment is server as PR Preview.
 * STAGE   - For when the environment is served on the Staging Website.
 * TEST    - For when the environment is served on the Test Pipeline.
 * PROD    - For when the environment is served on the Prod Website.
 */
export const ENVIRONMENT = Object.freeze({
  LOCAL: "LOCAL",
  PREVIEW: "PREVIEW",
  STAGE: "STAGE",
  TEST: "TEST",
  PROD: "PROD"
});

/**
 * Constant for firebase collections
 */

export const FIRESTORE_COLLECTIONS = {
  USERS: "users",
  COMPANIES: "companies",
  COMPANY_QUESTIONS: "company_questions",
  CANDIDATE_QUESTIONS: "candidate_questions",
  TESTS: "tests",
  META_COUNTRIES_CITIES: "meta_countries_cities",
  SUBMISSIONS: "submissions"
};

/**
 * Email ID of the Portal to send from.
 */
export const FROM_EMAIL_NO_REPLY = "support@tokhimo.com"; // FIXME: update this once send grid setup done and removed below comment
// export const FROM_EMAIL_NO_REPLY = "no-reply@aptitude-test.tokhimo.com";

export const LOCATION_TYPE = Object.freeze({
  COUNTRY: "60001",
  CITY: "60002"
});

// FIXME: update the routes path
/**
 * App routes constants.
 */
export const ROUTES = Object.freeze({
  root: "/",
  home: "/",
  register: "/auth/register",
  completedProfile: "/auth/complete-profile",
  login: "/auth/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/auth/reset-password",
  candidateList: "/candidate-list",
  settings: "/settings",
  companyInformation: "/settings",
  companyType: "/settings/company-type",
  customizeCompanyType: "settings/customize-company-type",
  adminData: "/settings/admin-data",
  notFound: "/not-found",
  candidates: "/candidates",
  candidateWelcome: "/candidates/welcome",
  candidateYourSelf: "/candidates/about-your-self",
  candidateTest: "/candidates/tests/sections"
});

/**
 * Constant for locales
 */

export const LOCALE = Object.freeze({
  EN: "en-US",
  JA: "ja-JP"
});

// Toast notification time
export const TOAST_DEFAULT_HIDE_DURATION_MS = 3000;

// FIXME: remove random function and update the sections chart data
/**
 * Constant for company type A,B,C japanese culture chart data
 */
const randomNumber = () => parseFloat((Math.random() * 4 + 1).toFixed(1));
// Y axis value should be between 1 to 5
export const COMPANY_TYPE_A_CULTURAL_CHART_DATA = [
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber()
].map((yAxisPoint, index) => ({
  x: index + 1,
  y: yAxisPoint
}));
export const COMPANY_TYPE_B_CULTURAL_CHART_DATA = [
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber()
].map((yAxisPoint, index) => ({
  x: index + 1,
  y: yAxisPoint
}));
export const COMPANY_TYPE_C_CULTURAL_CHART_DATA = [
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber(),
  randomNumber()
].map((yAxisPoint, index) => ({
  x: index + 1,
  y: yAxisPoint
}));

/**
 * Constant for cultural type category
 */

// FIXME: update the sections after discursion with shota san
export const CULTURAL_TYPE_QUESTION_CATEGORY = Object.freeze({
  EXTROVERTED_OR_INTROVERTED: "6001",
  LOGICAL_OR_EMOTIONAL: "6002",
  DIRECT_COMMUNICATION_OR_INDIRECT_COMMUNICATION: "6003",
  COOPERATION_OR_COMPETITIVE: "6004",
  SPONTANEOUS_OR_CONSCIENTIOUS: "6005",
  SELF_MOTIVATED_OR_VALIDATION_MOTIVATED: "6006",
  VISION_MOTIVATED_OR_PROFIT_MOTIVATED: "6007",
  PROCESS_ORIENTED_OR_RESULT_ORIENTED: "6008"
});

// FIXME: remove below label or update once finalize
// export const CULTURAL_TYPE_QUESTION_CATEGORY_LABELS = Object.freeze({
//   EXTROVERTED_OR_INTROVERTED: "6001",
//   LOGICAL_OR_EMOTIONAL: "6002",
//   DIRECT_COMMUNICATION_OR_INDIRECT_COMMUNICATION: "6003",
//   COOPERATION_OR_COMPETITIVE: "6004",
//   SPONTANEOUS_OR_CONSCIENTIOUS: "6005",
//   SELF_MOTIVATED_OR_VALIDATION_MOTIVATED: "6006",
//   VISION_MOTIVATED_OR_PROFIT_MOTIVATED: "6007",
//   PROCESS_ORIENTED_OR_RESULT_ORIENTED: "6008"
// });

// export const CULTURAL_TYPE_QUESTION_CATEGORY_T_LABELS = Object.freeze({
//   [CULTURAL_TYPE_QUESTION_CATEGORY.EXTROVERTED_OR_INTROVERTED]:
//     "t_cultural_question_category_first_category",
//   [CULTURAL_TYPE_QUESTION_CATEGORY.LOGICAL_OR_EMOTIONAL]:
//     "t_cultural_question_category_second_category",
//   [CULTURAL_TYPE_QUESTION_CATEGORY.DIRECT_COMMUNICATION_OR_INDIRECT_COMMUNICATION]:
//     "t_cultural_question_category_third_category",
//   [CULTURAL_TYPE_QUESTION_CATEGORY.COOPERATION_OR_COMPETITIVE]:
//     "t_cultural_question_category_fourth_category",
//   [CULTURAL_TYPE_QUESTION_CATEGORY.SPONTANEOUS_OR_CONSCIENTIOUS]:
//     "t_cultural_question_category_fifth_category",
//   [CULTURAL_TYPE_QUESTION_CATEGORY.SELF_MOTIVATED_OR_VALIDATION_MOTIVATED]:
//     "t_cultural_question_category_sixth_category",
//   [CULTURAL_TYPE_QUESTION_CATEGORY.VISION_MOTIVATED_OR_PROFIT_MOTIVATED]:
//     "t_cultural_question_category_seventh_category",
//   [CULTURAL_TYPE_QUESTION_CATEGORY.PROCESS_ORIENTED_OR_RESULT_ORIENTED]:
//     "t_cultural_question_category_eighth_category"
// });

/**
 * Test invitation expire time.
 */
// FIXME: update test expiration time after confirmation
export const TEST_LINK_EXPIRATION_TIME = "1d";

/**
 * Constant for japan country alpha2 code (used for providing default country code in Telephone Input)
 */

export const JP_ALPHA2_CODE = "JP";

/**
 * Constant for locales-short
 * This is used to change location and country locale.
 */

export const LOCALE_SHORT = Object.freeze({
  EN: "en",
  JA: "ja"
});

/**
 * Constant for social style section - x-axis and y-axis
 * This is used to map the x axis and y axis on social style chart.
 */
// Represents the question categories for the x-axis and y-axis on the social style chart.
export const SOCIAL_STYLE_QUESTION_CATEGORY = Object.freeze({
  LISTENER_OR_SPEAKER: "7001", // Represents the category for the x-axis on the social style chart.
  SHOW_EMOTION_OR_HIDE_EMOTION: "7002" // Represents the category for the y-axis on the social style chart.
});

// Defines the range and middle point of the social style chart.
export const SOCIAL_STYLE_CHART = Object.freeze({
  range: [-2, 2], // Defines the range of values on the social style chart.
  middlePoint: 3 // Represents the middle point of the range(1-5).
});

/**
 * Constant for radio type question options
 */

export const YES_OR_NO_OPTIONS = Object.freeze({
  YES: "8001",
  NO: "8002"
});

export const YES_OR_NO_T_LABELS = Object.freeze({
  [YES_OR_NO_OPTIONS.YES]: "t_general_yes",
  [YES_OR_NO_OPTIONS.NO]: "t_general_no"
});
