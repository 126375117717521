import DOMPurify from "dompurify";

interface DangerousComponentFromHTMLProps {
  html?: string;
}

// This will sanitize and keep only valid html tags and attributes.
const sanitizeHTML = (html: string): string => {
  return DOMPurify.sanitize(html, { ALLOWED_ATTR: ["style"] });
};

const DangerousComponentFromHTML = ({
  html = ""
}: DangerousComponentFromHTMLProps) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: sanitizeHTML(html)
      }}
    />
  );
};

export default DangerousComponentFromHTML;
