// use to handle the width and height according to another div size
import { RefObject, useLayoutEffect, useRef, useState } from "react";

interface Size {
  width: number;
  height: number;
}

const useSize = (): [RefObject<HTMLDivElement>, Size] => {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setSize({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight
        });
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  return [ref, size];
};

export default useSize;
