import { Link, useLocation } from "react-router-dom";

import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Link as MuiLink,
  Stack,
  styled,
  Typography
} from "@mui/material";

import { colorPalette } from "@utils/theme";

interface TabNavigationProps {
  navLinks: Array<{
    label: string;
    redirectLink: string;
    aliasLinks?: Array<string>;
  }>;
}

const StyledStack = styled(Stack)({
  "overflow": "auto",
  "::-webkit-scrollbar": {
    display: "none"
  }
});

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  "paddingLeft": 0,
  "paddingRight": 0,
  "&: hover": {
    background: "transparent"
  },
  "&.Mui-selected": {
    "borderBottom": `4px solid ${theme.palette.primary.main}`,
    "background": "transparent",
    "color": theme.palette.primary.main,
    ".MuiListItemText-root": {
      ".MuiTypography-root": {
        color: theme.palette.primary.main
      }
    },
    "&: hover": {
      background: "transparent"
    }
  }
}));

const TabNavigation = ({ navLinks }: TabNavigationProps) => {
  const currentPath = useLocation();
  return (
    <>
      <List disablePadding>
        <StyledStack direction="row" whiteSpace="nowrap" spacing={3}>
          {navLinks.map((singleNavLink) => (
            <MuiLink
              key={singleNavLink.label}
              component={Link}
              to={singleNavLink.redirectLink}
              underline="none">
              <ListItem disablePadding>
                <StyledListItemButton
                  selected={
                    currentPath.pathname === singleNavLink.redirectLink ||
                    singleNavLink.aliasLinks?.includes(currentPath.pathname)
                  }>
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        {singleNavLink.label}
                      </Typography>
                    }
                  />
                </StyledListItemButton>
              </ListItem>
            </MuiLink>
          ))}
        </StyledStack>
      </List>
      <Divider color={colorPalette.grey.base} />
    </>
  );
};

export default TabNavigation;
