import { Stack, Typography } from "@mui/material";

import AccessmentCategoriesIcon, {
  AccessmentCategoriesIconProps
} from "@components/AccessmentCategoriesIcon";

interface AccessmentCategoriesCardProps {
  title: string;
  description: string;
  image: AccessmentCategoriesIconProps["image"];
}

const AccessmentCategoriesCard = ({
  title,
  description,
  image
}: AccessmentCategoriesCardProps) => {
  return (
    <Stack alignItems="center" my={4.75}>
      <AccessmentCategoriesIcon image={image} alt={`${image}_icon`} />
      <Typography variant="h5" mt={3.75} mb={1.5}>
        {title}
      </Typography>
      <Typography variant="subtitle3" align="center" maxWidth={320}>
        {description}
      </Typography>
    </Stack>
  );
};

export default AccessmentCategoriesCard;
