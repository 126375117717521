import { useEffect, useState } from "react";

import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Box, Stack, styled, Typography } from "@mui/material";

import theme from "@utils/theme";

const StyledDragHandleIcon = styled(DragHandleIcon)(() => ({
  cursor: "grab"
}));

const StyledItemTypography = styled(Typography)(() => ({
  wordBreak: "break-all"
}));

const SortableItem = ({
  id,
  value,
  index
}: {
  id: string;
  value: string;
  index: number;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div>
      <div style={style} {...attributes}>
        <Stack
          direction="row"
          alignItems="center"
          py={1}
          px={2.5}
          color="text.primary">
          <span ref={setNodeRef} {...listeners}>
            <StyledDragHandleIcon />
          </span>
          <Stack
            minWidth={40}
            minHeight={40}
            alignItems="center"
            justifyContent="center"
            borderRadius={2.5}
            border={`1px solid ${theme.palette.text.primary}`}
            ml={1.5}>
            <Typography variant="subtitle5">{index}</Typography>
          </Stack>
          <Box textTransform="capitalize" m={1.5}>
            <StyledItemTypography variant="subtitle3">
              {value}
            </StyledItemTypography>
          </Box>
        </Stack>
      </div>
    </div>
  );
};

const SortableList = ({
  items,
  handleDragEnd,
  headTitle
}: {
  items: Array<string>;
  handleDragEnd?: (data: Array<string>) => void;
  headTitle?: string;
}) => {
  const [listItems, setListItems] = useState<string[]>(items);

  useEffect(() => {
    setListItems(items);
  }, [items]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnDragEnd = (event: any) => {
    // FIXME: fix any type
    const { active, over } = event;

    if (!active || !over) {
      return;
    }

    if (active?.id !== over?.id) {
      setListItems((items) => {
        const oldIndex = items.indexOf(active?.id);
        const newIndex = items.indexOf(over?.id);

        const listItemsAfterDragEnd = arrayMove(items, oldIndex, newIndex);

        if (handleDragEnd) {
          handleDragEnd(listItemsAfterDragEnd);
        }
        return listItemsAfterDragEnd;
      });
    }
  };

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  return (
    <Stack
      borderRadius={2.5}
      border={`1px solid ${theme.palette.text.secondary}`}
      pb={2.5}>
      <Stack bgcolor="primary.main" p={2.5} borderRadius="10px 10px 0px 0px">
        <Typography variant="subtitle1" color="text.primary">
          {headTitle ? headTitle : "Drag and drop sortable list"}
        </Typography>
      </Stack>
      <Stack>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleOnDragEnd}
          modifiers={[restrictToVerticalAxis]}>
          <SortableContext
            items={listItems}
            strategy={verticalListSortingStrategy}>
            {listItems.map((singleListItem, index) => (
              <SortableItem
                key={singleListItem}
                id={singleListItem}
                value={singleListItem}
                index={index + 1}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Stack>
    </Stack>
  );
};

export default SortableList;
