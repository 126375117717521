import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

import logoWhite from "@assets/logos/logo-white.svg";

const CandidateFooter = () => {
  return (
    <Box>
      <Box
        component="img"
        width={{ xs: 80, lg: 120 }}
        height={{ xs: 80, lg: 120 }}
        src={logoWhite}
        alt="tokhimo-logo-white"
      />
      <Typography color="text.secondary" pt={2} textAlign="center">
        {/* FIXME: add translation */}© {dayjs().format("YYYY")} Tokhimo Inc.
        All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default CandidateFooter;
