import intl from "react-intl-universal";

import { Stack, Typography } from "@mui/material";

import Button from "@components/Button";

import translate, { LOCALES_LIST } from "@utils/translate";

const LanguageSwitcher = () => {
  const currentLocale = intl.getInitOptions().currentLocale;

  return (
    <>
      <Typography variant="h5">
        {intl.get("t_debugger_language_select")}:
      </Typography>
      <br />
      <Stack direction="row" gap={2}>
        {LOCALES_LIST.map((single_locale) => (
          <Button
            key={single_locale.value}
            onClick={() => {
              translate.setCurrentLocale(single_locale.value);
            }}
            variant={
              currentLocale === single_locale.value ? "contained" : "outlined"
            }>
            {single_locale.label}
          </Button>
        ))}
      </Stack>
    </>
  );
};

export default LanguageSwitcher;
