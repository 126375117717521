import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import intl from "react-intl-universal";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Button as MuiButton,
  Link as MuiLink,
  Stack,
  styled,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import {
  useSignInWithEmailAndPassword,
  useSignInWithGoogle
} from "react-firebase-hooks/auth";
import * as yup from "yup";

import Button from "@components/Button";
import Loader from "@components/Loader";
import TextField from "@components/TextField";

import { ROUTES } from "@utils/config";
import { auth } from "@utils/firebase";
import { colorPalette } from "@utils/theme";

import Google from "@assets/icons/google.svg";
import SignIn_BG from "@assets/images/signIn.svg";
import Tokhimo_Logo from "@assets/logos/logo-white.svg";
import useUserProfile from "@hooks/database/useUserProfile";

interface SignInForm {
  email: string;
  password: string;
}

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 62,
    width: 62
  },
  [theme.breakpoints.up("sm")]: {
    height: 55,
    width: 55
  },
  [theme.breakpoints.up("lg")]: {
    height: 62,
    width: 62
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledRightContentGridContainer = styled(Grid)(({ theme }) => ({
  position: "relative",
  marginTop: 3.75,
  [theme.breakpoints.between("sm", "md")]: {
    top: "calc(20vh - 1.5rem)",
    transform: "translateY(-25%)"
  }
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  "background": "transparent",
  "color": theme.palette.text.secondary,
  "&.MuiDivider-root::before": {
    borderTop: "thin solid",
    borderTopColor: theme.palette.text.secondary
  },
  "&.MuiDivider-root::after": {
    borderTop: "thin solid",
    borderTopColor: theme.palette.text.secondary
  }
}));

const StyledGoogleSignUpButton = styled(MuiButton)({
  "backgroundColor": colorPalette.coral.base,
  "marginBottom": "1.5rem",
  "width": "100%",
  "&.MuiButton-containedPrimary": {
    "&:hover": {
      backgroundColor: colorPalette.coral.base
    }
  }
});

const StyledSignInImage = styled("img")({
  width: "95%",
  position: "relative",
  top: "calc(20vh - 1.5rem)",
  transform: "translateY(-25%)"
});

// TODO: will handle returnURL later
const Login = () => {
  const navigate = useNavigate();
  const userProfile = useUserProfile();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [
    signInWithEmailAndPassword,
    ,
    isSignInWithEmailAndPasswordLoading,
    signInWithEmailAndPasswordError
  ] = useSignInWithEmailAndPassword(auth);

  const [signInWithGoogle, , isSignInWithGoogleLoading, signInWithGoogleError] =
    useSignInWithGoogle(auth);

  useEffect(() => {
    const code = signInWithEmailAndPasswordError?.code;
    if (code === "auth/invalid-email") {
      setErrorMessage(intl.get("t_auth_error_wrong_email"));
    } else if (
      code === "auth/user-not-found" ||
      code === "auth/wrong-password"
    ) {
      setErrorMessage(intl.get("t_auth_error_wrong_email_or_password"));
    } else if (code === "auth/too-many-requests") {
      setErrorMessage(intl.get("t_auth_error_too_many_attempt"));
    } else if (code === "auth/internal-error") {
      setErrorMessage(intl.get("t_general_internal_error"));
    } else {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  }, [signInWithEmailAndPasswordError]);

  useEffect(() => {
    const code = signInWithGoogleError?.code;
    if (code === "auth/popup-closed-by-user") {
      setErrorMessage("");
    } else if (code === "auth/too-many-requests") {
      setErrorMessage(intl.get("t_auth_error_too_many_attempt"));
    } else if (code === "auth/internal-error") {
      setErrorMessage(intl.get("t_general_internal_error"));
    } else {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  }, [signInWithGoogleError]);

  // validation schema
  const schema = yup.object({
    email: yup
      .string()
      .email()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_email_address")
        })
      ),
    password: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_general_password")
      })
    )
  });

  const defaultFormValues: SignInForm = {
    email: "",
    password: ""
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  const handleLoginForm = async (data: SignInForm) => {
    try {
      await signInWithEmailAndPassword(data.email, data.password);
      setSearchParams(searchParams.toString(), { replace: true });
    } catch (error) {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  };

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (user) {
        if (userProfile.value) {
          navigate(ROUTES.home);
        } else {
          navigate(ROUTES.completedProfile);
        }
      }
    });

    return () => unregisterAuthObserver();
  }, [navigate]);

  return (
    <>
      {isSignInWithGoogleLoading || isSignInWithEmailAndPasswordLoading ? (
        <Loader />
      ) : (
        <Grid container maxWidth={1440} alignSelf="center">
          <Grid
            item
            xs={12}
            md={6}
            position={{ md: "sticky" }}
            height={{ md: "100vh" }}
            top={{ md: 0 }}>
            <Box zIndex={1} mx={1.25} my={2.5} left={{ md: "5%", lg: "10%" }}>
              <MuiLink component={Link} to={ROUTES.root}>
                <StyledLogo
                  variant="square"
                  src={Tokhimo_Logo}
                  alt={intl.get("t_alt_tokhimo_logo")}
                />
              </MuiLink>
            </Box>
            <Box display={{ xs: "none", md: "block" }}>
              <StyledSignInImage
                src={SignIn_BG}
                alt={intl.get("t_alt_signin_background")}
              />
            </Box>
          </Grid>
          <StyledRightContentGridContainer item xs={12} md={5} zIndex={2}>
            <Container>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                mt={{ md: 7.5 }}>
                <Typography variant="body1" color="text.secondary">
                  {intl.get("t_login_not_a_member")} &nbsp;
                </Typography>
                <Typography variant="subtitle4">
                  <MuiLink
                    component={Link}
                    to={ROUTES.register}
                    underline="none">
                    {intl.get("t_general_sign_up")}
                  </MuiLink>
                </Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="center">
                <Box>
                  <Typography
                    variant="h4"
                    color="primary.main"
                    align="center"
                    mt={5}
                    mb={3}
                    width={{ xs: 240, sm: "100%" }}>
                    {intl.get("t_login_welcome_back")}
                  </Typography>
                </Box>
              </Stack>
              <Typography variant="h3" align="center">
                {intl.get("t_general_sign_in")}
              </Typography>
              {signInWithEmailAndPasswordError || signInWithGoogleError ? (
                <Stack mt={2} alignItems="center">
                  <Typography variant="subtitle4" color="error.main">
                    {errorMessage}
                  </Typography>
                </Stack>
              ) : (
                false
              )}
              <Box
                noValidate
                component="form"
                onSubmit={handleSubmit(handleLoginForm)}
                my={3.5}>
                <TextField
                  type="email"
                  name="email"
                  control={control}
                  label={intl.get("t_general_email")}
                  placeholder={intl.get("t_register_email_address_placeholder")}
                  required
                />
                <TextField
                  control={control}
                  name="password"
                  label={intl.get("t_register_password")}
                  placeholder={intl.get("t_register_password_placeholder")}
                  required
                  type="password"
                />
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  mb={2}>
                  <Typography variant="subtitle4">
                    <MuiLink
                      component={Link}
                      to={ROUTES.resetPassword}
                      underline="none">
                      {intl.get("t_general_forgot_password")}
                    </MuiLink>
                  </Typography>
                </Stack>
                <Stack mt={1.5}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large">
                    <Box my={0.5}>{intl.get("t_general_sign_in")}</Box>
                  </Button>
                </Stack>
              </Box>
              <StyledDivider>{intl.get("t_register_or")}</StyledDivider>
              <Stack mt={3}>
                <StyledGoogleSignUpButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    signInWithGoogle();
                  }}>
                  <img src={Google} alt={intl.get("t_alt_google_logo")} />
                  &nbsp;&nbsp;{intl.get("t_signin_with_google")}
                </StyledGoogleSignUpButton>
              </Stack>
              <Typography align="center" my={7.5}>
                © {dayjs().format("YYYY")}
                {intl.get("t_general_copyright")}
              </Typography>
            </Container>
          </StyledRightContentGridContainer>
        </Grid>
      )}
    </>
  );
};

export default Login;
