import SortableList from "@components/SortableList";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const SortableListPage = () => {
  return (
    <>
      <Heading text="Sortable list" />
      <SortableList
        items={[
          "Salary",
          "Work life balance",
          "Work location",
          "Benefits",
          "Self Growth",
          "Relationship With Boss",
          "Relationship With Colleagues",
          "Company’s Growth",
          "Higher Responsibility",
          "Stability"
        ]}
      />
    </>
  );
};

export default SortableListPage;
