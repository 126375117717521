import intl from "react-intl-universal";

import { Box, Typography } from "@mui/material";

import CandidatePageFooter from "@components/CandidatePageFooter";
import Footer from "@components/Footer";

import AccordionPage from "@pages/dashboard/ComponentPreview/AccordionPage";
import BannerPage from "@pages/dashboard/ComponentPreview/BannerPage";
import ButtonPage from "@pages/dashboard/ComponentPreview/ButtonPage";
import ChartPage from "@pages/dashboard/ComponentPreview/ChartPage";
import CulturalChartPage from "@pages/dashboard/ComponentPreview/CulturalChartPage";
import FormElementsPage from "@pages/dashboard/ComponentPreview/FormElementsPage";
import HeaderPage from "@pages/dashboard/ComponentPreview/HeaderPage";
import Heading from "@pages/dashboard/ComponentPreview/Heading";
import ModalPage from "@pages/dashboard/ComponentPreview/ModalPage";
import PaginationPage from "@pages/dashboard/ComponentPreview/PaginationPage";
import ProgressBarPage from "@pages/dashboard/ComponentPreview/ProgressBarPage";
import QuestionStepperPage from "@pages/dashboard/ComponentPreview/QuestionStepperPage";
import ReportPage from "@pages/dashboard/ComponentPreview/ReportPage";
import SortableListPage from "@pages/dashboard/ComponentPreview/SortableListPage";
import StatusPage from "@pages/dashboard/ComponentPreview/StatusPage";
import StepperPage from "@pages/dashboard/ComponentPreview/StepperPage";
import TablePage from "@pages/dashboard/ComponentPreview/TablePage";
import TabPage from "@pages/dashboard/ComponentPreview/TabPage";
import TooltipPage from "@pages/dashboard/ComponentPreview/TooltipPage";

const ComponentPreview = () => {
  // FIXME: delete this file once all components are ready
  return (
    <Box bgcolor="background.paper" p={2}>
      <Typography m={1}>{intl.get("t_general_sample")}</Typography>
      <Heading text="Components Preview" />
      <ReportPage />
      <ButtonPage />
      <PaginationPage />
      <TabPage />
      <StatusPage />
      <TablePage />
      <FormElementsPage />
      <ProgressBarPage />
      <ModalPage />
      <AccordionPage />
      <Heading text="Candidate Footer" />
      <CandidatePageFooter />
      <Heading text="Footer" />
      <Footer />
      <HeaderPage />
      <SortableListPage />
      <TooltipPage />
      <ChartPage />
      <CulturalChartPage />
      <StepperPage />
      <BannerPage />
      <QuestionStepperPage />
    </Box>
  );
};

export default ComponentPreview;
