import React, { ReactNode, useState } from "react";

import { ExpandMoreTwoTone } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  styled,
  Typography
} from "@mui/material";

import Status from "@components/Status";

import { USER_TEST_STATUS } from "@utils/config";
import theme from "@utils/theme";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const StyledExpandIcon = styled(ExpandMoreTwoTone, {
  shouldForwardProp: (prop) => prop !== "isExpand"
})<{ isExpand: boolean }>(({ theme, isExpand }) => ({
  color: isExpand ? theme.palette.primary.main : theme.palette.text.secondary
}));

const AccordionRow = ({
  leftContent,
  rightContent
}: {
  leftContent: string | ReactNode;
  rightContent: ReactNode;
}) => {
  return (
    <>
      <Grid item xs={6}>
        <Typography variant="subtitle5">{leftContent}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption">{rightContent}</Typography>
      </Grid>
    </>
  );
};

const AccordionPage = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (panel: string) => (e: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Heading text="Accordion" />
      <Box
        border={`1px solid ${theme.palette.text.secondary}`}
        borderRadius={2.5}>
        <Box
          borderRadius="10px 10px 0px 0px"
          padding="1.8rem 1rem"
          bgcolor="primary.main">
          <Typography color="text.primary" variant="subtitle5">
            Name And Email Address
          </Typography>
        </Box>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          disableGutters
          elevation={0}>
          <AccordionSummary
            expandIcon={<StyledExpandIcon isExpand={expanded === "panel1"} />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Box>
              <Typography variant="subtitle5">Shubham Khunt</Typography>
              <Grid item xs={6}>
                <Typography variant="caption">
                  shubhamkhunt08@gmail.com
                </Typography>
              </Grid>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={3} alignItems="center">
              <AccordionRow leftContent="Nationality" rightContent="India" />
              <AccordionRow
                leftContent="Invited Date"
                rightContent="21.04.2022"
              />
              <AccordionRow
                leftContent="Reminded Date"
                rightContent="18.04.2022"
              />
              <AccordionRow
                leftContent="Taken Date"
                rightContent="18.04.2022"
              />
              <AccordionRow
                leftContent="Status"
                rightContent={<Status label={USER_TEST_STATUS.TAKEN} />}
              />
              <AccordionRow leftContent="Score" rightContent="75" />
              <AccordionRow leftContent="Result(PDF)" rightContent="Export" />
              <AccordionRow leftContent="Send Reminder" rightContent="Send" />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          disableGutters
          elevation={0}>
          <AccordionSummary
            expandIcon={<StyledExpandIcon isExpand={expanded === "panel2"} />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Box>
              <Typography variant="subtitle5">Jon deo</Typography>
              <Grid item xs={6}>
                <Typography variant="caption">jondeo@gmail.com</Typography>
              </Grid>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={3} alignItems="center">
              <AccordionRow leftContent="Nationality" rightContent="Japan" />
              <AccordionRow
                leftContent="Invited Date"
                rightContent="23.02.2022"
              />
              <AccordionRow
                leftContent="Reminded Date"
                rightContent="13.04.2022"
              />
              <AccordionRow leftContent="Taken Date" rightContent="-" />
              <AccordionRow
                leftContent="Status"
                rightContent={<Status label={USER_TEST_STATUS.EXPIRED} />}
              />
              <AccordionRow leftContent="Score" rightContent="-" />
              <AccordionRow leftContent="Result(PDF)" rightContent="Export" />
              <AccordionRow leftContent="Send Reminder" rightContent="Send" />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default AccordionPage;
