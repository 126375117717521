import { Divider, Typography } from "@mui/material";

import theme from "@utils/theme";

const Heading = ({ text = "component name" }: { text: string }) => {
  return (
    <>
      <Divider color={theme.palette.primary.main} sx={{ margin: "1rem 0" }} />
      <Typography color="text.secondary" variant="h4" sx={{ margin: "1rem 0" }}>
        {text}
      </Typography>
      <Divider color={theme.palette.primary.main} sx={{ margin: "1rem 0" }} />
    </>
  );
};

export default Heading;
