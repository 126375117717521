import { useEffect, useState } from "react";

import intl from "react-intl-universal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";

import Button from "@components/Button";
import SortableList from "@components/SortableList";

import useToast from "@hooks/useToast";

import { TEST_SECTION_TYPE, USER_TEST_STATUS } from "@utils/config";
import { find, findIndex, isArray } from "@utils/lodash";

import useTestDetails from "@hooks/database/useTestDetailsContext";
import CandidateSubmission from "@interfaces/database/CandidateSubmission";
import SectionSubmission from "@interfaces/database/SectionSubmission";

const PriorityWork = () => {
  const { testId, submissionId } = useParams();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const token = queryParams.get("token");
  const { setSubmissionData, submission } = useTestDetails();
  const toast = useToast();
  const [items, setItems] = useState<Array<string>>([
    "Salary",
    "Work life balance",
    "Work location",
    "Benefits",
    "Self Growth",
    "Relationship With Boss",
    "Relationship With Colleagues",
    "Company’s Growth",
    "Higher Responsibility",
    "Stability"
  ]);

  const sections: Array<SectionSubmission> =
    submission?.sections?.map((singleSection: SectionSubmission) => {
      return {
        type: singleSection?.type,
        question_answer_responses: singleSection?.question_answer_responses,
        meta_data: singleSection?.meta_data
      };
    }) ?? [];

  const priorityWorkSectionIdx = findIndex(sections, {
    type: TEST_SECTION_TYPE.PRIORITY_LIST
  });

  useEffect(() => {
    if (priorityWorkSectionIdx !== -1) {
      const priorityWorkSection = sections?.[priorityWorkSectionIdx];
      if (
        isArray(priorityWorkSection?.meta_data?.priority_work) &&
        priorityWorkSection?.meta_data?.priority_work
      ) {
        setItems([...priorityWorkSection.meta_data.priority_work]);
      }
    }
  }, []);

  const handleSubmissionUpdateSuccess = () => {
    navigate(
      `/candidates/${testId}/${submissionId}/tests/sections/6?token=${token}`
    );
  };

  const handleSubmissionUpdateFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleDragEnd = (sortedList: Array<string>) => {
    setItems(sortedList);
  };

  const handleFinish = () => {
    const prioritySectionData = find(submission?.sections, {
      type: TEST_SECTION_TYPE.PRIORITY_LIST
    });
    const prioritySectionIdx = findIndex(submission?.sections, {
      type: TEST_SECTION_TYPE.PRIORITY_LIST
    });

    if (!prioritySectionData) {
      submission?.sections?.push({
        meta_data: { priority_work: items },
        question_answer_responses: [],
        type: TEST_SECTION_TYPE.PRIORITY_LIST
      });
    } else if (prioritySectionIdx !== -1 && submission?.sections) {
      submission.sections[prioritySectionIdx] = {
        meta_data: { priority_work: items },
        question_answer_responses: [],
        type: TEST_SECTION_TYPE.PRIORITY_LIST
      };
    }

    const submissionData: CandidateSubmission = {
      ...submission,
      test_status: USER_TEST_STATUS.TAKEN,
      test_completed_at: Timestamp.now(),
      updated_at: Timestamp.now()
    };
    if (setSubmissionData && testId && submissionId) {
      setSubmissionData(
        testId,
        submissionId,
        submissionData,
        handleSubmissionUpdateSuccess,
        handleSubmissionUpdateFail
      );
    }
  };

  return (
    <>
      <Typography variant="h4">Priority in Work</Typography>
      <Typography variant="body1" my={2}>
        Sort by what you prioritize the most
      </Typography>
      <SortableList items={items} handleDragEnd={handleDragEnd} />
      <Box mt={2}>
        <Button type="submit" onClick={handleFinish}>
          Finish
        </Button>
      </Box>
    </>
  );
};

export default PriorityWork;
