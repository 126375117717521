import { useEffect, useState } from "react";

import intl from "react-intl-universal";
import { useParams } from "react-router-dom";

import { Box, Container, Grid, Stack, styled, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { map, values } from "lodash";

import Chart from "@components/Chart";
import Cultural from "@components/Cultural";
import Loader from "@components/Loader";
import ProgressBar from "@components/ProgressBar";
import SortableList from "@components/SortableList";

import { FIRESTORE_COLLECTIONS } from "@utils/config";
import { db } from "@utils/firebase";
import { colorPalette } from "@utils/theme";

import CandidateSubmission from "@interfaces/database/CandidateSubmission";

const StyledContainer = styled(Container)({
  marginTop: "1.5rem",
  marginBottom: "1.5rem",
  zIndex: "5"
});

const ReportPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [submission, setSubmission] = useState<CandidateSubmission>();

  const { testId, submissionId } = useParams();

  const randomNumber = () => parseFloat((Math.random() * 4 + 1).toFixed(1));

  useEffect(() => {
    const getSubmissionData = async () => {
      if (testId && submissionId) {
        setIsLoading(true);
        const submissionSubCollectionRef = doc(
          db,
          `${FIRESTORE_COLLECTIONS.TESTS}/${testId}/${FIRESTORE_COLLECTIONS.SUBMISSIONS}`,
          submissionId
        );
        const submission = await getDoc(submissionSubCollectionRef);
        const submissionData = submission.data();
        if (submission.exists()) {
          setSubmission(submissionData as CandidateSubmission);
        }
      }
      setIsLoading(false);
    };
    getSubmissionData();
  }, []);

  const testResult = submission?.test_result;
  const attentionToDetailScore = testResult?.attention_to_detail_score ?? 0;
  const culturalMatchChartPoints = testResult?.cultural_chart_data_points ?? [];
  const emotionalIntelligenceScore =
    testResult?.emotional_intelligence_score ?? 0;
  const subjectiveThinkingScore = testResult?.subjective_thinking_score ?? 0;
  const socialStyleChartDataPoints =
    testResult?.social_style_chart_data_points ?? {
      xAxis: -2,
      yAxis: -2,
      pointerSize: 15
    };
  const priorityWorkList = testResult?.priority_work ?? [];
  const valuesArray = map(culturalMatchChartPoints, values);

  const japaneseCultureDataPoints = [...Array(8)].map((_, index) => {
    return {
      x: index + 1,
      y: randomNumber()
    };
  });
  const examineeDataPoints = [...Array(8)].map((_, index) => ({
    x: index + 1,
    y: valuesArray?.[index]?.[0]
  }));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <StyledContainer>
          <Typography variant="h2" color="primary.main" align="center">
            {intl.get("t_home_report")}
          </Typography>
          <Box
            bgcolor={colorPalette.black.background.base}
            mx={{ md: 12.5 }}
            mt={7.5}
            p={5}>
            <Box>
              <Stack
                flexDirection="row"
                gap={1}
                alignItems="center"
                alignSelf="flex-start">
                <Stack
                  height={56}
                  width={56}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={1.25}
                  bgcolor={colorPalette.lighterBlue[15]}>
                  <Typography variant="h3" color="secondary.main">
                    1
                  </Typography>
                </Stack>
                <Box>
                  <Typography variant="h4" color="secondary.main">
                    {intl.get("t_home_report_cultural_match_title")}
                  </Typography>
                  {/*  FIXME: will update later*/}
                  <Typography variant="body1">
                    {intl.get("t_home_report_cultural_match_subtitle")}
                  </Typography>
                </Box>
              </Stack>

              {/* FIXME: need to fix responsive later */}
              <Cultural
                domain={{ x: [1, 8], y: [1, 5] }}
                examineeDataPoints={examineeDataPoints}
                japaneseCultureDataPoints={japaneseCultureDataPoints}
              />
            </Box>
            <Grid container rowSpacing={1} columnSpacing={3} mt={5} mb={3.75}>
              <Grid item xs={12} md={7}>
                <Stack flexDirection="row" gap={1} alignItems="center" mb={4}>
                  <Stack
                    height={56}
                    width={56}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={1.25}
                    bgcolor={colorPalette.lighterBlue[15]}>
                    <Typography variant="h3" color="secondary.main">
                      2
                    </Typography>
                  </Stack>
                  <Box>
                    <Typography variant="h4" color="secondary.main">
                      {intl.get("t_home_report_social_style_title")}
                    </Typography>
                    <Typography variant="body1">
                      {intl.get("t_home_report_social_style_subtitle")}
                    </Typography>
                  </Box>
                </Stack>
                <Chart
                  pointerSize={socialStyleChartDataPoints.pointerSize}
                  domain={[-2, 2]}
                  x={socialStyleChartDataPoints.xAxis}
                  y={socialStyleChartDataPoints.yAxis}
                />
              </Grid>
              <Grid item xs={12} md={5} mt={{ xs: 5, md: "unset" }}>
                <Stack flexDirection="row" gap={1} alignItems="center" mb={4}>
                  <Stack
                    height={56}
                    width={56}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={1.25}
                    bgcolor={colorPalette.lighterBlue[15]}>
                    <Typography variant="h3" color="secondary.main">
                      3
                    </Typography>
                  </Stack>
                  <Box>
                    <Typography variant="h4" color="secondary.main">
                      {intl.get("t_home_report_work_priority_title")}
                    </Typography>
                    <Typography variant="body1">
                      {intl.get("t_home_report_work_priority_subtitle")}
                    </Typography>
                  </Box>
                </Stack>
                <SortableList
                  items={priorityWorkList}
                  headTitle={submission?.candidate_information?.name?.en}
                />
              </Grid>
            </Grid>
            <Grid container columnGap={3} mt={2}>
              <Grid item xs={12}>
                <Stack flexDirection="row" gap={1} alignItems="center" mb={4}>
                  <Stack
                    height={56}
                    width={56}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={1.25}
                    bgcolor={colorPalette.lighterBlue[15]}>
                    <Typography variant="h3" color="secondary.main">
                      4
                    </Typography>
                  </Stack>
                  <Box>
                    <Typography variant="h4" color="secondary.main">
                      {intl.get("t_home_report_progress_title")}
                    </Typography>
                    <Typography variant="body1">
                      {intl.get("t_home_report_progress_subtitle")}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md mt={4}>
                <ProgressBar
                  value={subjectiveThinkingScore}
                  severity="success"
                  label={intl.get("t_home_report_progress_subjective_thinking")}
                />
              </Grid>
              <Grid item xs={12} md mt={4}>
                <ProgressBar
                  value={emotionalIntelligenceScore}
                  severity="error"
                  label={intl.get(
                    "t_home_report_progress_emotional_intelligence"
                  )}
                />
              </Grid>
              <Grid item xs={12} md mt={4}>
                <ProgressBar
                  value={attentionToDetailScore}
                  severity="warning"
                  label={intl.get("t_home_report_progress_attention_to_detail")}
                />
              </Grid>
            </Grid>
          </Box>
        </StyledContainer>
      )}
    </>
  );
};

export default ReportPage;
