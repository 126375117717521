/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import intl from "react-intl-universal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControlLabel, Stack, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import * as yup from "yup";

import Button from "@components/Button";
import QuestionStepper from "@components/QuestionStepper";
import Radio from "@components/Radio";

import useToast from "@hooks/useToast";

import { TEST_SECTION_TYPE } from "@utils/config";
import { getInitialValues, getValidationSchema } from "@utils/yupSchema";

import useTestDetails from "@hooks/database/useTestDetailsContext";
import CandidateSubmission from "@interfaces/database/CandidateSubmission";
import RadioQuestion from "@interfaces/database/Question/RadioQuestion";
import QuestionAnswerResponse from "@interfaces/database/QuestionAnswerResponse";
import SectionSubmission from "@interfaces/database/SectionSubmission";

interface AttentionToDetailFormProps {
  initialValue: Record<string, string>;
  name: string;
  options: Array<{ label: string; value: string | number; disabled?: boolean }>;
  label: string;
  completedQuestionsCount: number;
  totalQuestions: number;
  handleFormSubmit: (formData: Record<string, string>) => void;
  labelAsHtml: boolean;
}

const AttentionToDetailForm = ({
  initialValue,
  name,
  options,
  label,
  completedQuestionsCount = 1,
  totalQuestions = 10,
  handleFormSubmit,
  labelAsHtml = true
}: AttentionToDetailFormProps) => {
  // validation schema
  const schema = yup.object({
    [name]: yup.string().required("Answer is required").nullable()
  });

  const methods = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset, setValue } = methods;

  useEffect(() => {
    reset(initialValue);
    // setValue(name, initialValue[name]);
  }, [name, label, completedQuestionsCount, options, initialValue, reset]);

  return (
    <Box
      noValidate
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}
      id={name}>
      {/* <Typography variant="subtitle3"> */}
      <Box my={5}>
        <Radio
          // setValue={setValue}
          // required
          name={name}
          control={control}
          options={options}
          labelAsHtml={labelAsHtml}
          label={label}
        />
      </Box>
      {/* </Typography> */}
      <QuestionStepper
        totalQuestions={totalQuestions}
        completedQuestionsCount={completedQuestionsCount}
      />
      <br />
      <Button type="submit" formId={name}>
        Next Step
      </Button>
    </Box>
  );
};

export default AttentionToDetailForm;
