import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import * as yup from "yup";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import Radio from "@components/Radio";
import Ranking from "@components/Ranking";
import TextField from "@components/TextField";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const TextFieldPage = () => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const addressMaxLength = 50;

  // validation schema
  const schema = yup.object({
    name: yup.string().required("Name is required"),
    gender: yup.string().required("Gender is required"),
    hobbies: yup
      .array()
      .required("Hobbies is required")
      .min(1, "Please select at least 1 hobby"),
    password: yup.string().required("Password is required"),
    address: yup
      .string()
      .required("Address is required")
      .max(
        addressMaxLength - 1,
        "The characters have reached the maximum limit"
      ),
    mobileNo: yup
      .string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
    ranking: yup.string().required("Ranking is required")
  });

  const methods = useForm({
    defaultValues: {
      name: "",
      password: "",
      address: "",
      hobbies: [],
      gender: "",
      ranking: ""
    },
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = methods;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => alert(JSON.stringify(data));
  return (
    <>
      <Heading text="Form Elements" />
      <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField control={control} name="name" label="Name" required />
        <TextField
          control={control}
          name="password"
          label="Password"
          required
          type="password"
        />
        <TextField
          control={control}
          name="mobileNo"
          label="Mobile Number"
          required
          type="number"
        />
        <TextField
          control={control}
          name="address"
          label="Address"
          required
          maxLength={addressMaxLength}
          multiline
          rows={4}
        />
        <Radio
          label="Choose you gender"
          direction="row"
          required
          control={control}
          name="gender"
          options={[
            {
              label: "Male",
              value: "male"
            },
            {
              label: "Female",
              value: "female"
            },
            {
              label: "Other",
              value: "other"
            }
          ]}
        />
        <Checkbox
          label="Choose you number"
          control={control}
          setValue={setValue}
          error={errors.hobbies}
          name="hobbies"
          required
          options={[
            {
              label: "Cricket",
              value: "cricket"
            },
            {
              label: "Dancing",
              value: "dancing"
            },
            {
              label: "Cooking",
              value: "cooking"
            }
          ]}
        />
        <Ranking
          label="Ranking"
          name="ranking"
          setValue={setValue}
          control={control}
          required
        />
        <Button type="submit">Submit</Button>
      </Box>
    </>
  );
};

export default TextFieldPage;
