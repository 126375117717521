import { useEffect, useState } from "react";

import intl from "react-intl-universal";
import {
  matchPath,
  Outlet,
  useLocation,
  useParams,
  useSearchParams
} from "react-router-dom";

import { httpsCallable } from "firebase/functions";

import Loader from "@components/Loader";

import useToast from "@hooks/useToast";

import { ROUTES, USER_TYPE } from "@utils/config";
import { functions } from "@utils/firebase";

import useTestDetails from "@hooks/database/useTestDetailsContext";
import TestStatus from "@pages/Candidates/TestStatus";
import RequireAuth from "@utils/components/RequireAuth";

const CandidatesApp = () => {
  const { pathname } = useLocation();
  const isCandidatePath = matchPath(`/${ROUTES.candidates}/*`, pathname);
  // FIXME: Find solution and Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateTestInvitationLink: any = httpsCallable(
    functions,
    "validateTestInvitationLink"
  );

  const [queryParameters] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [testStatusProps, setTestStatusProps] = useState<{
    heading: string;
    subHeading: string;
    redirectionLink?: string;
    btnText?: string;
    status: "error" | "success";
  }>({
    heading: "",
    subHeading: "",
    status: "error",
    redirectionLink: "",
    btnText: ""
  });
  const {
    isTestDataLoading,
    testDataError,
    getTestData,
    isSubmissionDataLoading,
    submissionDataError,
    getSubmissionData
  } = useTestDetails();
  const toast = useToast();

  const token = queryParameters.get("token");
  const { testId, submissionId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const testValidation = await validateTestInvitationLink(token);
        // test link is expired
        if (testValidation?.data?.name === "TokenExpiredError") {
          setIsLoading(false);
          setTestStatusProps({
            // FIXME: update the translation
            heading: "Test link is expired.",
            subHeading: "Please contact your company to re-send the test link.",
            status: "error",
            //FIXME: re-direct to contact page (update re-direction link later)
            redirectionLink: ROUTES.root,
            btnText: intl.get("t_general_contact")
          });
        } else if (
          // test link is not valid
          testValidation?.data?.testId !== testId ||
          testValidation?.data?.submissionId !== submissionId
        ) {
          setIsLoading(false);
          setTestStatusProps({
            heading: "Test link is not valid.",
            subHeading:
              "Please contact your company to re-send the valid test link.",
            status: "error"
          });
        } else {
          setIsLoading(false);
          // FIXME: remove/update this else block once above else if code fixed
          // delete token if validation is successfully done
          // queryParameters.delete("token");
          // navigate({
          //   pathname: location.pathname,
          //   search: queryParameters.toString()
          // });
        }
        // FIXME: Add test completed error Object when user complete the test
      } catch (e) {
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    const fetchTestData = async () => {
      if (testId && getTestData) {
        await getTestData({ testId });
      }
    };
    const fetchSubmissionData = async () => {
      if (testId && getSubmissionData && submissionId) {
        await getSubmissionData({ testId, submissionId });
      }
    };
    fetchTestData();
    fetchSubmissionData();
  }, []);

  if (testDataError) {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    // eslint-disable-next-line no-console
    console.log("Test data error");
  }

  if (submissionDataError) {
    // eslint-disable-next-line no-console
    console.log("submission data error");
  }

  if (isLoading || isTestDataLoading || isSubmissionDataLoading) {
    return <Loader />;
  }

  if (testStatusProps.heading || testStatusProps.subHeading) {
    return <TestStatus {...testStatusProps} />;
  }

  // TODO: check test link is valid or not logic
  if (isCandidatePath) {
    return <Outlet context={{ testId, submissionId, token }} />;
  } else {
    return <RequireAuth accessRestriction={USER_TYPE.COMPANY} />;
  }
};

export default CandidatesApp;
