/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";

import { matchPath, Outlet, useLocation } from "react-router-dom";

import { Box, Container, Stack, styled, ThemeProvider } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import useForceUpdate from "use-force-update";

import CandidateFooter from "@components/CandidateFooter";
import Debugger from "@components/Debugger";
import Footer from "@components/Footer";
import Header from "@components/Header";
import Loader from "@components/Loader";
import UserStatusChecker from "@components/UserStatusChecker";

import { ENVIRONMENT, ROUTES } from "@utils/config";
import { auth } from "@utils/firebase";
import theme from "@utils/theme";
import translate from "@utils/translate";

import logoWhite from "@assets/logos/logo-white.svg";
import CompanyDetailsContext from "@utils/components/CompanyDetailsContext";
import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import TestDetailsContext from "@utils/components/TestDetailsContext";
import ToastContext from "@utils/components/ToastContext";
import UserProfileContext from "@utils/components/UserProfileContext";

const StyledContainer = styled(Container)({
  marginTop: "1.5rem",
  marginBottom: "1.5rem",
  zIndex: "5"
});

const HIDE_HEADER_FOOTER_PATHS = ["/not-found", "/auth/*", "forgot-password"];

const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const App = () => {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [user, loading] = useAuthState(auth);
  const forceUpdate = useForceUpdate();
  const { pathname } = useLocation();

  const isCandidatePath = matchPath(`${ROUTES.candidates}/*`, pathname);
  // FIXME: isCandidateListPath removed and update logic
  const isCandidateListPath = matchPath(`${ROUTES.candidateList}/*`, pathname);
  // FIXME: isCompanySettings removed and update logic
  const isCompanySettings = matchPath(`${ROUTES.settings}/*`, pathname);
  const isNotFoundPagePath = matchPath(`${ROUTES.notFound}`, pathname);
  const isAuthPagesPath = matchPath("/auth/*", pathname);
  const isHomePagePath = matchPath(`${ROUTES.root}`, pathname);

  const hideHeaderFooter = HIDE_HEADER_FOOTER_PATHS.some((singlePath) =>
    matchPath(singlePath, pathname)
  );

  useScrollToTop();

  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.initializeIntl();
    setLoaded(true);
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <UserProfileContext>
        <CompanyDetailsContext>
          <TestDetailsContext>
            <ToastContext>
              {isLoaded ? (
                <Box bgcolor="background.paper" color="white" minHeight="100vh">
                  <EnvironmentSpecific env={ENVIRONMENT.LOCAL}>
                    <Debugger />
                  </EnvironmentSpecific>
                  <Stack direction="column" minHeight="100vh">
                    {!isCandidatePath ? <UserStatusChecker /> : false}
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        {!hideHeaderFooter ? (
                          <>
                            {isCandidatePath ? (
                              <Container>
                                <Box
                                  pt={2}
                                  component="img"
                                  width={{ xs: 80, lg: 120 }}
                                  height={{ xs: 80, lg: 120 }}
                                  src={logoWhite}
                                  alt="tokhimo-logo-white"
                                />
                              </Container>
                            ) : (
                              <Header isLoggedIn={user ? true : false} />
                            )}
                          </>
                        ) : (
                          false
                        )}
                      </>
                    )}
                    {isNotFoundPagePath || isAuthPagesPath ? (
                      <Outlet />
                    ) : (
                      <>
                        {isHomePagePath ? (
                          <Outlet />
                        ) : (
                          <StyledContainer>
                            <Outlet />
                          </StyledContainer>
                        )}
                        <StyledContainer>
                          {!hideHeaderFooter ? (
                            <>
                              {isCandidatePath ||
                              isCandidateListPath ||
                              isCompanySettings ? (
                                <Box
                                  px={{
                                    xs: 2,
                                    md: 30,
                                    lg: 40
                                  }}>
                                  <CandidateFooter />
                                </Box>
                              ) : (
                                <Footer />
                              )}
                            </>
                          ) : (
                            false
                          )}
                        </StyledContainer>
                      </>
                    )}
                  </Stack>
                </Box>
              ) : (
                false
              )}
            </ToastContext>
          </TestDetailsContext>
        </CompanyDetailsContext>
      </UserProfileContext>
    </ThemeProvider>
  );
};

export default App;
