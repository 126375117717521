import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import intl from "react-intl-universal";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Link as MuiLink,
  Stack,
  styled,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { FirebaseError } from "firebase/app";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import * as yup from "yup";

import Button from "@components/Button";
import Loader from "@components/Loader";
import TextField from "@components/TextField";

import useToast from "@hooks/useToast";

import { ENVIRONMENT, LOCALE, LOCALE_SHORT, ROUTES } from "@utils/config";
import { auth } from "@utils/firebase";
import translate from "@utils/translate";

import ResetPassword_BG from "@assets/images/resetPassword.svg";
import Tokhimo_Logo from "@assets/logos/logo-white.svg";

interface ResetPasswordForm {
  password: string;
}

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 62,
    width: 62
  },
  [theme.breakpoints.up("sm")]: {
    height: 55,
    width: 55
  },
  [theme.breakpoints.up("lg")]: {
    height: 62,
    width: 62
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledResetPasswordImage = styled("img")({
  width: "95%",
  position: "relative",
  top: "calc(20vh - 1.5rem)",
  transform: "translateY(-25%)"
});

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const { is_reset: isReset } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [returnURL, setReturnURL] = useState<string>("/");
  const [isShowLoader, setShowLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigateHome = () => {
    navigate("/");
    toast.kampai(intl.get("t_forgot_password_expired_link"), "error");
  };

  useEffect(() => {
    const returnURL = searchParams.get("continueUrl") ?? "";
    if (returnURL) {
      setReturnURL(returnURL);
    } else {
      setReturnURL("/");
    }
    if (isReset) {
      setShowLoader(false);
      return;
    }
    const mode = searchParams.get("mode") ?? "";
    const passwordResetCode = searchParams.get("oobCode") ?? "";
    const apiKey = searchParams.get("apiKey") ?? "";
    const lang = searchParams.get("lang") ?? "";
    let newLocale: typeof LOCALE[keyof typeof LOCALE] = LOCALE.EN;
    switch (lang) {
      case LOCALE_SHORT.EN:
        newLocale = LOCALE.EN;
        break;
      case LOCALE_SHORT.JA:
        newLocale = LOCALE.JA;
        break;
      default:
        newLocale = LOCALE.EN;
        break;
    }
    const currentLocale = translate.getCurrentLocale();
    if (currentLocale !== newLocale) {
      translate.setCurrentLocale(newLocale);
    }

    if (!mode || !passwordResetCode || !apiKey || mode !== "resetPassword") {
      navigateHome();
    }

    if (
      process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.LOCAL &&
      apiKey !== process.env.REACT_APP_FIREABSE_API_KEY
    ) {
      navigateHome();
    }

    (async () => {
      try {
        const email = await verifyPasswordResetCode(auth, passwordResetCode);
        if (email) {
          setShowLoader(false);
        } else {
          navigateHome();
        }
      } catch (e) {
        navigateHome();
      }
    })();
  }, [searchParams]);

  const schema = yup.object().shape({
    password: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_general_password")
      })
    )
  });

  const defaultFormValues: ResetPasswordForm = {
    password: ""
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  const handleResetPasswordForm = (formData: ResetPasswordForm) => {
    const passwordResetCode = searchParams.get("oobCode") ?? "";
    setShowLoader(true);
    (async () => {
      try {
        await confirmPasswordReset(auth, passwordResetCode, formData.password);
        setShowLoader(false);
        navigate("password-updated?continueUrl=" + returnURL);
      } catch (e) {
        setErrorMessage(intl.get("t_general_internal_error"));

        if (e instanceof FirebaseError) {
          const code = e.code;
          if (code === "auth/invalid-action-code") {
            setErrorMessage(intl.get("t_forgot_password_expired_link"));
          } else if (code === "auth/weak-password") {
            setErrorMessage(intl.get("t_forgot_password_weak_password"));
          }
        }
      }
    })();
  };

  const navigteToContinueURL = () => {
    const continueURL = new URL(returnURL);
    if (continueURL.hostname === window.location.hostname) {
      let path = continueURL.pathname;
      if (continueURL.search) {
        path = path + "?" + continueURL.search;
      }
      navigate(path);
    } else {
      window.location.href = returnURL;
    }
  };
  return (
    <>
      {isShowLoader ? (
        <Loader />
      ) : (
        <Grid container maxWidth={1440} alignSelf="center">
          <Grid
            item
            xs={12}
            md={6}
            position={{ md: "sticky" }}
            height={{ md: "100vh" }}
            overflow="hidden"
            top={{ md: 0 }}>
            <Box zIndex={1} mx={1.25} my={2.5} left={{ md: "5%", lg: "10%" }}>
              <MuiLink component={Link} to={ROUTES.root}>
                <StyledLogo
                  variant="square"
                  src={Tokhimo_Logo}
                  alt={intl.get("t_alt_tokhimo_logo")}
                />
              </MuiLink>
            </Box>
            <Box display={{ xs: "none", md: "block" }}>
              <StyledResetPasswordImage
                src={ResetPassword_BG}
                alt={intl.get("t_alt_reset_password_background")}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            zIndex={2}
            position="relative"
            mt={{ xs: 1.5, md: "calc(20vh - 1.5rem)" }}>
            <Container>
              <Stack height="50vh" minHeight={400} justifyContent="center">
                {isReset ? (
                  <>
                    <Typography variant="h3">
                      {intl.get("t_reset_password_confirmed_title")}
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="subtitle3">
                      {intl.get("t_reset_password_confirmed_subtitle")}
                    </Typography>
                    <br />
                    <br />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={navigteToContinueURL}>
                      {intl.get("t_reset_password_confirmed_continued")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography variant="h3" mb={2.5}>
                      {intl.get("t_reset_password_title")}
                    </Typography>
                    <Typography variant="subtitle4" color="error.main">
                      {errorMessage}
                    </Typography>
                    <Typography variant="subtitle3">
                      {intl.get("t_reset_password_subtitle")}
                    </Typography>
                    <Box
                      noValidate
                      component="form"
                      onSubmit={handleSubmit(handleResetPasswordForm)}
                      my={3.5}>
                      <TextField
                        control={control}
                        name="password"
                        label={intl.get("t_reset_password_new_password")}
                        placeholder={intl.get(
                          "t_reset_password_enter_new_password"
                        )}
                        required
                        type="password"
                      />
                      <Box mt={3}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large">
                          {intl.get("t_reset_password_title")}
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Stack>
              <Typography align="center" mb={7.5}>
                © {dayjs().format("YYYY")}
                {intl.get("t_general_copyright")}
              </Typography>
            </Container>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ResetPassword;
