import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    subtitle5: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    inputLabel: React.CSSProperties;
    helperText: React.CSSProperties;
    inputText: React.CSSProperties;
  }

  // allow configuration using 'createTheme'
  interface TypographyVariantsOptions {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    subtitle5: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    inputLabel: React.CSSProperties;
    helperText: React.CSSProperties;
    inputText: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    subtitle4: true;
    subtitle5: true;
    buttonLarge: true;
    buttonSmall: true;
    inputLabel: true;
    helperText: true;
    inputText: true;
  }
}

export const colorPalette = Object.freeze({
  blue: {
    "base": "#5454D4",
    "70": "#6048FFB2",
    "60": "#5454D499",
    "20": "#5454D433",
    "hover": {
      "base": "#6048FF",
      "15": "#6048FF26",
      "12": "#6048FF1F"
    }
  },
  white: {
    "base": "#FFFFFF",
    "60": "#FFFFFF99",
    "20": "#FFFFFF33"
  },
  black: {
    base: "#140838",
    background: {
      base: "#1E1340"
    }
  },
  grey: {
    "base": "#D9DAE1",
    "70": "#D9DAE1B2",
    "60": "#D9DAE199",
    "20": "#D9DAE133",
    "05": "#D9DAE10D"
  },
  yellow: {
    "base": "#FDBF2D",
    "25": "#FDBF2D40"
  },
  red: {
    "base": "#ED0A34",
    "25": "#ED0A3440"
  },
  green: {
    "base": "#34A853",
    "25": "#34A85340"
  },

  lighterBlue: {
    "base": "#93E5EE",
    "60": "#93E5EE99",
    "15": "#93E5EE26",
    "hover": {
      "base": "#7BDDE8",
      "25": "#7BDDE840",
      "15": "#7BDDE826"
    }
  },
  coral: {
    base: "#EA4335"
  }
});

let theme = createTheme({
  palette: {
    primary: {
      main: colorPalette.blue.base,
      dark: colorPalette.blue.hover.base
    },
    secondary: {
      main: colorPalette.lighterBlue.base,
      light: colorPalette.lighterBlue.hover[25],
      dark: colorPalette.lighterBlue.hover.base,
      contrastText: colorPalette.white.base
    },
    success: {
      main: colorPalette.green.base,
      light: colorPalette.green[25]
    },
    warning: {
      main: colorPalette.yellow.base,
      light: colorPalette.yellow[25]
    },
    background: {
      default: colorPalette.black.background.base,
      paper: colorPalette.black.base
    },
    text: {
      primary: colorPalette.white.base,
      secondary: colorPalette.grey.base,
      disabled: colorPalette.white[60]
    },
    error: {
      main: colorPalette.red.base,
      light: colorPalette.red[25]
    }
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    h1: {
      fontWeight: 500,
      fontSize: "6rem",
      letterSpacing: "-1.5px"
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
      letterSpacing: 0
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.25rem",
      letterSpacing: 0
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.5rem",
      letterSpacing: 0
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.5rem",
      letterSpacing: 0
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1.5rem",
      letterSpacing: 0
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "1.25rem",
      letterSpacing: 0
    },
    subtitle3: {
      fontWeight: 400,
      fontSize: "1.25rem",
      letterSpacing: 0
    },
    subtitle4: {
      fontWeight: 500,
      fontSize: "1rem",
      letterSpacing: 0
    },
    subtitle5: {
      fontWeight: 500,
      fontSize: "0.875rem",
      letterSpacing: 0
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      letterSpacing: 0
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      letterSpacing: 0
    },
    buttonSmall: {
      fontWeight: 500,
      fontSize: "0.8125rem",
      letterSpacing: 0,
      textTransform: "capitalize"
    },
    button: {
      fontWeight: 500,
      fontSize: "0.875rem",
      letterSpacing: 0,
      textTransform: "capitalize"
    },
    buttonLarge: {
      fontWeight: 500,
      fontSize: "1rem",
      letterSpacing: 0,
      textTransform: "capitalize"
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: "0.75rem",
      letterSpacing: 0
    },
    helperText: {
      fontWeight: 400,
      fontSize: "0.75rem",
      letterSpacing: 0
    },
    inputText: {
      fontWeight: 400,
      fontSize: "1rem",
      letterSpacing: 0
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 1101,
      lg: 1641,
      xl: 2161
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        color: colorPalette.white.base
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "0.625rem"
        },
        containedPrimary: {
          "&.Mui-disabled": {
            backgroundColor: colorPalette.blue[60],
            color: colorPalette.white[60]
          }
        },
        containedSecondary: {
          "&.Mui-disabled": {
            backgroundColor: colorPalette.lighterBlue[60],
            color: colorPalette.white[60]
          }
        },
        outlinedPrimary: {
          "border": `0.063rem solid ${colorPalette.blue.base}`,
          "&:hover": {
            backgroundColor: colorPalette.blue.hover[15]
          }
        },
        outlinedSecondary: {
          "border": `0.063rem solid ${colorPalette.lighterBlue.base}`,
          "&:hover": {
            backgroundColor: colorPalette.lighterBlue.hover[15]
          }
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "borderRadius": "0.625rem",
          "height": "2.25rem",
          "width": "2.25rem",
          ":not(&.Mui-selected)": {
            ":hover": {
              backgroundColor: colorPalette.grey[20]
            }
          },
          "&.Mui-selected": {
            "color": colorPalette.white.base,
            "backgroundColor": colorPalette.blue.base,
            ":hover": {
              backgroundColor: colorPalette.blue.base
            }
          }
        },
        ellipsis: {
          display: "flex",
          justifyContent: "center",
          alignItem: "center",
          paddingTop: ".3rem"
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: { color: colorPalette.grey.base },
        indicator: {
          height: "0.25rem"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          opacity: 1,
          fontSize: "1rem",
          color: colorPalette.grey.base
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colorPalette.grey.base
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: `0.0625rem solid ${colorPalette.grey.base}`,
          maxWidth: "80rem"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colorPalette.blue.base
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: "1.25rem",
          border: "0"
        },
        body: {
          padding: ".5rem 1.25rem",
          border: "0"
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          marginTop: 12,
          marginBottom: 16
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colorPalette.white.base
        },
        asterisk: {
          color: colorPalette.red.base
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "borderRadius": "0.625rem",
          "&:hover": {
            "& > fieldset": {
              borderColor: colorPalette.blue.hover
            }
          },
          "&.Mui-focused": {
            "& > fieldset.MuiOutlinedInput-notchedOutline": {
              borderColor: colorPalette.blue.base,
              borderWidth: "1px",
              boxShadow: `0 0 0 2px ${colorPalette.blue[20]}`
            }
          },
          "&.Mui-disabled": {
            "& > fieldset.MuiOutlinedInput-notchedOutline": {
              borderColor: colorPalette.white[60],
              color: colorPalette.white[60]
            }
          }
        },
        input: {
          "&.Mui-disabled": {
            opacity: 1,
            WebkitTextFillColor: colorPalette.white[60]
          },

          // hide up-down arrow for input type number
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            display: "none"
          }
        },
        notchedOutline: {
          borderColor: colorPalette.grey.base
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          "color": colorPalette.white.base,
          "&.Mui-disabled": {
            color: colorPalette.white[60]
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "border": `0.0625rem solid ${colorPalette.grey.base}`,
          "color": "transparent",
          "margin": "0.35rem 0.75rem",
          "transform": "scale(1.2)",
          "height": "1.25rem",
          "width": "1.25rem",
          "&.Mui-disabled": {
            border: `0.0625rem solid ${colorPalette.grey.base}`,
            color: "transparent",
            background: `${colorPalette.grey[70]}`
          },
          "&.Mui-checked": {
            background: `${colorPalette.white.base}`,
            color: `${colorPalette.blue.base}`
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: "0.875rem",
          borderRadius: 4
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "backgroundColor": "transparent",
          "padding": 0,
          "margin": "0.35rem 0.75rem",
          "border": `0.0625rem solid ${colorPalette.grey.base}`,
          "borderRadius": "0.125rem",
          "height": "1.25rem",
          "marginRight": "0.5rem",
          "width": "1.25rem",
          "color": "transparent",
          "&.Mui-disabled": {
            backgroundColor: colorPalette.grey[60],
            color: "transparent"
          },
          "&.Mui-checked": {
            backgroundColor: colorPalette.blue.base,
            borderColor: colorPalette.blue.base
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "backgroundColor": colorPalette.black.background.base,
          ":last-of-type": {
            borderRadius: "10px"
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: colorPalette.blue[20]
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colorPalette.black.base
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          minWidth: "16rem",
          padding: 0,
          border: "1px solid white",
          borderRadius: "4px",
          backgroundColor: colorPalette.black.background.base
        },
        tooltipPlacementBottom: {
          marginTop: "0.6rem !important"
        },
        arrow: {
          "fontSize": 16,
          "width": 16,
          "&::before": {
            border: "1px solid white",
            backgroundColor: colorPalette.black.background.base,
            boxSizing: "border-box"
          }
        }
      }
    },
    // FIXME: Update the alert component design
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: colorPalette.green.base,
          color: colorPalette.white.base
        },
        filledError: {
          backgroundColor: colorPalette.red.base
        },
        filledWarning: {
          backgroundColor: colorPalette.yellow.base,
          color: colorPalette.white.base
        },
        filledInfo: {
          backgroundColor: colorPalette.white.base,
          color: colorPalette.black.base
        },
        action: {
          color: colorPalette.white.base
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          boxShadow: `0px 3px 5px -1px ${colorPalette.grey[20]}, 0px 6px 10px ${colorPalette.grey[20]}, 0px 1px 18px ${colorPalette.grey[20]}`,
          backgroundColor: colorPalette.white.base,
          color: colorPalette.white.base
        },
        action: {
          color: colorPalette.white.base
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
