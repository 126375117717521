import { LinearProgress, styled, Typography } from "@mui/material";

import { colorPalette } from "@utils/theme";

interface ProgressBarProps {
  severity: "success" | "error" | "warning";
  value: number;
  label: string;
}

interface StyledProgressBarProps {
  variant: string;
  value: number;
  color: "success" | "error" | "warning";
  backgroundColor: string;
}

const StyledProgressBar = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== "backgroundColor"
})<StyledProgressBarProps>(({ backgroundColor }) => ({
  backgroundColor
}));

const ProgressBar = ({ severity, value, label }: ProgressBarProps) => {
  return (
    <>
      <Typography
        component="div"
        textAlign="center"
        variant="subtitle3"
        color="text.primary"
        mb={1.5}>
        {label}
      </Typography>
      <Typography
        component="div"
        textAlign="center"
        variant="subtitle3"
        color={`${severity}.main`}
        mb={1}>
        {value}
      </Typography>
      <StyledProgressBar
        color={severity}
        variant="determinate"
        value={value}
        backgroundColor={colorPalette.white[20]}
      />
    </>
  );
};

export default ProgressBar;
