/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";

import { useForm } from "react-hook-form";
import intl from "react-intl-universal";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { doc, getDoc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import { groupBy, map, mean, meanBy } from "lodash";
import * as yup from "yup";

import Button from "@components/Button";
import TextField from "@components/TextField";
import TypeableSelect from "@components/TypeableSelect";

import useToast from "@hooks/useToast";

import {
  CULTURAL_TYPE_QUESTION_CATEGORY,
  FIRESTORE_COLLECTIONS,
  SOCIAL_STYLE_CHART,
  SOCIAL_STYLE_QUESTION_CATEGORY,
  TEST_SECTION_TYPE,
  USER_TEST_STATUS
} from "@utils/config";
import { db } from "@utils/firebase";
import { getCountryName, getDropdownCountryList } from "@utils/location";

import useTestDetails from "@hooks/database/useTestDetailsContext";
import KeyLabel from "@interfaces/components/KeyLabel";
import CandidateInformation from "@interfaces/database/CandidateInformation";
import CandidateSubmission from "@interfaces/database/CandidateSubmission";
import Question from "@interfaces/database/Question";
import CandidatePagesWrapper from "@pages/Candidates/CandidatePagesWrapper";
// import TestResult from "@interfaces/database/TestResult";

interface AboutYourSelfFormProps {
  nationality?: KeyLabel;
  jobTitle?: string;
  age?: string;
}

const AboutYourSelf = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const COUNTRY_OPTIONS = getDropdownCountryList();

  const { setSubmissionData, submission } = useTestDetails();
  const toast = useToast();

  const getQuestionData = async (questionId: string) => {
    const questionCollectionRef = doc(db, `candidate_questions/${questionId}`);
    const questionData = await getDoc(questionCollectionRef);

    if (questionData.exists()) {
      return questionData.data() as Question;
    } else {
      return "";
    }
  };

  const calculateStandardDeviation = (arr: Array<number>) => {
    const meanVal = mean(arr);
    const squaredDiffs = map(arr, (value: number) =>
      Math.pow(value - meanVal, 2)
    );
    const variance = mean(squaredDiffs);
    const stdDev = Math.sqrt(variance);
    return +stdDev?.toFixed(2);
  };

  // const data = [4, 8, 6, 5, 3, 2, 8, 9];
  // const result = calculateStandardDeviation(data);
  // console.log(
  //   "Standard Deviation:",
  //   calculateStandardDeviation([3, 2, 3, 2, 4])
  // );

  const getReport = async () => {
    // let emotionalIntelligenceScore = 0;
    let attentionToDetailScore = 0;
    if (submission?.sections?.length ?? 0 > 0) {
      for (
        let index = 0;
        index < (submission?.sections?.length ?? 0);
        index++
      ) {
        const singleSection = submission?.sections?.[index];
        switch (singleSection?.type) {
          // case TEST_SECTION_TYPE.CULTURAL_MATCH: {
          //   const questionAnswerResponses =
          //     singleSection.question_answer_responses;
          //   const questionAnswerResponsesData = [];
          //   for (let idx = 0; idx < questionAnswerResponses.length; idx++) {
          //     const singleQuestionAnswerResponse = questionAnswerResponses[idx];
          //     const questionDetail = singleQuestionAnswerResponse?.question_id
          //       ? await getQuestionData(
          //           singleQuestionAnswerResponse?.question_id
          //         )
          //       : {};
          //     questionAnswerResponsesData.push({
          //       question_id: singleQuestionAnswerResponse.question_id,
          //       user_entered_answer:
          //         singleQuestionAnswerResponse.user_entered_answer,
          //       ...questionDetail
          //     });
          //   }
          //   const groupedCategory = groupBy(
          //     questionAnswerResponsesData,
          //     "question_category"
          //   );

          //   const categoryAndAveragePair = [];
          //   for (const [_, label] of Object.entries(
          //     CULTURAL_TYPE_QUESTION_CATEGORY
          //   )) {
          //     const singleCategoryQuestionAndAnswer = groupedCategory?.[label];
          //     if (singleCategoryQuestionAndAnswer) {
          //       const average = meanBy(
          //         singleCategoryQuestionAndAnswer,
          //         (singleCategoryAnswer) => {
          //           const userEnteredAnswer = Number(
          //             singleCategoryAnswer?.user_entered_answer
          //           );
          //           return Number.isNaN(userEnteredAnswer)
          //             ? 0
          //             : userEnteredAnswer;
          //         }
          //       );
          //       categoryAndAveragePair.push({
          //         [label]: +average?.toFixed(2)
          //       });
          //     } else {
          //       categoryAndAveragePair.push({
          //         [label]: 1 // by default giving 1 for cultural chart answer
          //       });
          //     }
          //   }
          //   if (categoryAndAveragePair?.length > 0 && testId && submissionId) {
          //     try {
          //       const submissionSubCollectionRef = doc(
          //         db,
          //         `${FIRESTORE_COLLECTIONS.TESTS}/${testId}/${FIRESTORE_COLLECTIONS.SUBMISSIONS}`,
          //         submissionId
          //       );
          //       const testResult: TestResult = {
          //         ...(submission?.test_result ?? {}),
          //         cultural_chart_data_points: categoryAndAveragePair
          //       };
          //       await updateDoc(submissionSubCollectionRef, {
          //         test_result: testResult
          //       });
          //     } catch (error) {
          //       // console.log(
          //       //   "error while setting cultural chart data points",
          //       //   error
          //       // );
          //     }
          //   }

          //   // const questionDocRef = admin
          //   // .firestore()
          //   // .doc(
          //   //   `${FIRESTORE_COLLECTIONS.CANDIDATE_QUESTIONS}/${singleSection.question_id}`
          //   // );
          //   break;
          // }
          // case TEST_SECTION_TYPE.SOCIAL_STYLE: {
          //   const questionAnswerResponses =
          //     singleSection.question_answer_responses;
          //   const questionAnswerResponsesData = [];
          //   for (let idx = 0; idx < questionAnswerResponses.length; idx++) {
          //     const singleQuestionAnswerResponse = questionAnswerResponses[idx];
          //     const questionDetail = singleQuestionAnswerResponse?.question_id
          //       ? await getQuestionData(
          //           singleQuestionAnswerResponse?.question_id
          //         )
          //       : {};
          //     questionAnswerResponsesData.push({
          //       question_id: singleQuestionAnswerResponse.question_id,
          //       user_entered_answer:
          //         singleQuestionAnswerResponse.user_entered_answer,
          //       ...questionDetail
          //     });
          //   }
          //   const groupedCategory = groupBy(
          //     questionAnswerResponsesData,
          //     "question_category"
          //   );

          //   const listenerOrSpeakerCategoryAnswer = groupedCategory?.[
          //     SOCIAL_STYLE_QUESTION_CATEGORY.LISTENER_OR_SPEAKER
          //   ]?.map((singleCategoryAnswer) => {
          //     const userEnteredAnswer = Number(
          //       singleCategoryAnswer?.user_entered_answer
          //     );
          //     return Number.isNaN(userEnteredAnswer) ? 1 : userEnteredAnswer;
          //   });

          //   const showEmotionOrHideEmotionAnswer = groupedCategory?.[
          //     SOCIAL_STYLE_QUESTION_CATEGORY.SHOW_EMOTION_OR_HIDE_EMOTION
          //   ]?.map((singleCategoryAnswer) => {
          //     const userEnteredAnswer = Number(
          //       singleCategoryAnswer?.user_entered_answer
          //     );
          //     return Number.isNaN(userEnteredAnswer) ? 1 : userEnteredAnswer;
          //   });

          //   console.log({
          //     listenerOrSpeakerCategoryAnswer: calculateStandardDeviation(
          //       listenerOrSpeakerCategoryAnswer
          //     ),
          //     showEmotionOrHideEmotionAnswer: calculateStandardDeviation(
          //       showEmotionOrHideEmotionAnswer
          //     )
          //   });

          //   const averageXAndYAxisStandardDeviation = meanBy([
          //     calculateStandardDeviation(listenerOrSpeakerCategoryAnswer),
          //     calculateStandardDeviation(showEmotionOrHideEmotionAnswer)
          //   ]);
          //   let pointerSize = 15;
          //   if (averageXAndYAxisStandardDeviation > 0.5) {
          //     pointerSize = 20;
          //   } else if (averageXAndYAxisStandardDeviation > 1) {
          //     pointerSize = 25;
          //   } else if (averageXAndYAxisStandardDeviation > 1.5) {
          //     pointerSize = 30;
          //   }

          //   // console.log({ pointerSize: pointerSize * pointerSize });

          //   const xAxis =
          //     meanBy(
          //       groupedCategory?.[
          //         SOCIAL_STYLE_QUESTION_CATEGORY.LISTENER_OR_SPEAKER
          //       ]?.map((singleCategoryAnswer) => {
          //         const userEnteredAnswer = Number(
          //           singleCategoryAnswer?.user_entered_answer
          //         );
          //         return Number.isNaN(userEnteredAnswer)
          //           ? 1
          //           : userEnteredAnswer;
          //       })
          //     ) - SOCIAL_STYLE_CHART.middlePoint;

          //   const yAxis =
          //     meanBy(
          //       groupedCategory?.[
          //         SOCIAL_STYLE_QUESTION_CATEGORY.SHOW_EMOTION_OR_HIDE_EMOTION
          //       ]?.map((singleCategoryAnswer) => {
          //         const userEnteredAnswer = Number(
          //           singleCategoryAnswer?.user_entered_answer
          //         );
          //         return Number.isNaN(userEnteredAnswer)
          //           ? 1
          //           : userEnteredAnswer;
          //       })
          //     ) - SOCIAL_STYLE_CHART.middlePoint;

          //   if (
          //     typeof xAxis === "number" &&
          //     typeof yAxis === "number" &&
          //     testId &&
          //     submissionId
          //   ) {
          //     try {
          //       const submissionSubCollectionRef = doc(
          //         db,
          //         `${FIRESTORE_COLLECTIONS.TESTS}/${testId}/${FIRESTORE_COLLECTIONS.SUBMISSIONS}`,
          //         submissionId
          //       );
          //       const testResult: TestResult = {
          //         ...(submission?.test_result ?? {}),
          //         social_style_chart_data_points: {
          //           xAxis: +xAxis?.toFixed(2),
          //           yAxis: +yAxis?.toFixed(2),
          //           pointerSize: 10
          //         }
          //       };
          //       await updateDoc(submissionSubCollectionRef, {
          //         test_result: testResult
          //       });
          //     } catch (error) {
          //       // console.log(
          //       //   "error while setting cultural chart data points",
          //       //   error
          //       // );
          //     }
          //   }

          //   // const questionDocRef = admin
          //   // .firestore()
          //   // .doc(
          //   //   `${FIRESTORE_COLLECTIONS.CANDIDATE_QUESTIONS}/${singleSection.question_id}`
          //   // );
          //   break;
          // }
          case TEST_SECTION_TYPE.PRIORITY_LIST:
            // console.log("priority list section");
            break;
          case TEST_SECTION_TYPE.EMOTIONAL_INTELLIGENCE:
            // {
            //   const questionAnswerResponses =
            //     singleSection.question_answer_responses;
            //   for (let idx = 0; idx < questionAnswerResponses.length; idx++) {
            //     const singleQuestionAnswerResponse =
            //       questionAnswerResponses[idx];
            //     // const questionDetail = singleQuestionAnswerResponse?.question_id
            //     //   ? await getQuestionData(
            //     //       singleQuestionAnswerResponse?.question_id
            //     //     )
            //     //   : {
            //     //       correct_answer: {
            //     //         en: "",
            //     //         ja: ""
            //     //       }
            //     //     };
            //     // if (
            //     //   questionDetail &&
            //     //   (singleQuestionAnswerResponse.user_entered_answer ==
            //     //     questionDetail?.correct_answer?.en ||
            //     //     singleQuestionAnswerResponse.user_entered_answer ==
            //     //       questionDetail?.correct_answer?.ja)
            //     // ) {
            //     const userEnteredAnswer = isNaN(
            //       Number(singleQuestionAnswerResponse?.user_entered_answer)
            //     )
            //       ? 1
            //       : Number(singleQuestionAnswerResponse?.user_entered_answer);
            //     const singleQuestionWeight = 10;
            //     const ratingCount = 5; // 1 to 5
            //     emotionalIntelligenceScore =
            //       emotionalIntelligenceScore +
            //       (userEnteredAnswer / ratingCount) * singleQuestionWeight;
            //     // }
            //   }
            // }
            break;
          case TEST_SECTION_TYPE.SUBJECTIVE_THINKING:
            // console.log("subjective thinking section");
            break;
          case TEST_SECTION_TYPE.ATTENTION_TO_DETAIL:
            // console.log("attention to detail section");
            {
              const questionAnswerResponses =
                singleSection.question_answer_responses;
              for (let idx = 0; idx < questionAnswerResponses.length; idx++) {
                const singleQuestionAnswerResponse =
                  questionAnswerResponses[idx];
                const questionDetail = singleQuestionAnswerResponse?.question_id
                  ? await getQuestionData(
                      singleQuestionAnswerResponse?.question_id
                    )
                  : {
                      correct_answer: {
                        en: "",
                        ja: ""
                      }
                    };
                const userEnteredAnswer =
                  singleQuestionAnswerResponse?.user_entered_answer;
                if (
                  questionDetail &&
                  (userEnteredAnswer == questionDetail?.correct_answer?.en ||
                    userEnteredAnswer == questionDetail?.correct_answer?.ja)
                ) {
                  const singleQuestionWeight =
                    100 / questionAnswerResponses.length;
                  attentionToDetailScore += singleQuestionWeight;
                  // attentionToDetailScore += 10;
                  // console.log({ userEnteredAnswer });
                  // emotionalIntelligenceScore = emotionalIntelligenceScore;
                  // +
                  // (userEnteredAnswer / ratingCount) * singleQuestionWeight;
                }
              }
            }
            break;
          default:
            // console.log("other");
            break;
        }
        // console.log({ emotionalIntelligenceScore });
        // console.log({ attentionToDetailScore });
        // if(singleSection?.type === TEST_SECTION_TYPE.CULTURAL_MATCH){
        //   console.log("cultural match section")
        // }
        // rest of your logic here
      }
    }
  };

  // if (submission?.test_status === USER_TEST_STATUS.TAKEN) {
  getReport();
  // }

  // const result = {
  //   culturalMatchChartPoints : categoryAndAveragePair,

  // };

  const { token }: { token: string } = useOutletContext();

  const { testId, submissionId } = useParams();
  const candidateInformation = submission?.candidate_information;

  // validation schema
  const schema = yup.object({
    nationality: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .nullable()
      .required("Nationality is required"), // FIXME: update validation message translation after chaw PR#85 merged
    jobTitle: yup.string().required("Job Title is required"),
    age: yup
      .number()
      .required("Age is required")
      .min(1, "Age is not valid")
      .max(100, "Age is not valid")
      .nullable()
      .transform((age, input) => (input === "" ? null : age))
  });

  const formInitValues = {
    nationality: {
      key: candidateInformation?.nationality ?? "",
      label: getCountryName(candidateInformation?.nationality ?? "") ?? ""
    },
    jobTitle: candidateInformation?.current_occupation?.en, // FIXME: fix translation logic
    age: candidateInformation?.age_range
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset } = methods;

  useEffect(() => {
    reset(formInitValues);
  }, [candidateInformation]);

  const handleSubmissionUpdateSuccess = (submissionId: string) => {
    navigate(
      `/candidates/${testId}/${submissionId}/tests/sections/0?token=${token}`
    );
  };

  const handleSubmissionUpdateFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = (formData: AboutYourSelfFormProps) => {
    const { jobTitle, nationality, age } = formData;
    try {
      const candidateInformation: CandidateInformation = {
        ...submission?.candidate_information,
        current_occupation: {
          en: jobTitle ?? "", // FIXME: handle translation logic - Note:- prepare translation util function early so we don't need to fix it later
          ja: jobTitle ?? ""
        },
        nationality: nationality?.key,
        age_range: age
      };

      const submissionData: CandidateSubmission = {
        ...submission,
        candidate_information: candidateInformation,
        current_active_step: "1",
        created_at: Timestamp.now(),
        updated_at: Timestamp.now(),
        test_status: USER_TEST_STATUS.INPROGRESS
      };

      if (setSubmissionData && testId && submissionId) {
        setSubmissionData(
          testId,
          submissionId,
          submissionData,
          handleSubmissionUpdateSuccess,
          handleSubmissionUpdateFail
        );
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  return (
    <CandidatePagesWrapper>
      <Stack
        justifyContent="center"
        alignItems="center"
        bgcolor={theme.palette.background.default}
        borderRadius="10px"
        px={1.25}
        py={{
          xs: 5,
          lg: 18.25
        }}>
        <Typography variant="h3" mb={3.5}>
          {intl.get("t_test_about_your_self_page_heading")}
        </Typography>
        <Box
          noValidate
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}
          width={{ xs: "100%", md: "31rem" }}>
          <TypeableSelect
            control={control}
            name="nationality"
            label={intl.get("t_general_nationality")}
            placeholder={intl.get("t_general_nationality")}
            required
            options={COUNTRY_OPTIONS}
          />
          <TextField
            control={control}
            name="jobTitle"
            label={intl.get("t_general_job_title")}
            required
            placeholder={intl.get("t_general_job_title")}
          />
          <TextField
            control={control}
            name="age"
            label={intl.get("t_general_age")}
            placeholder={intl.get("t_general_age")}
            required
            type="number"
          />
          <Stack alignItems="center">
            <Box width={{ xs: "100%", md: "8.75rem" }}>
              <Button type="submit" size="large" fullWidth>
                {intl.get("t_general_next")}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </CandidatePagesWrapper>
  );
};

export default AboutYourSelf;
