import { useEffect } from "react";

import intl from "react-intl-universal";
import { matchPath, Outlet, useLocation } from "react-router-dom";

import { Box, Stack } from "@mui/material";
import useForceUpdate from "use-force-update";

import SettingPagesWrapper from "@pages/SettingPagesWrapper";

import TabNavigation from "@components/TabNavigation";

import translate from "@utils/translate";

const Settings = () => {
  const { pathname } = useLocation();
  const isCustomizeCompanyTypePath = matchPath(
    "/settings/customize-company-type",
    pathname
  );

  const navLinks = [
    {
      label: intl.get("t_company_information"),
      redirectLink: "/settings/company-information"
    },
    {
      label: intl.get("t_company_type"),
      redirectLink: "/settings/company-type"
    }
    // FIXME: phase-2 route
    // {
    //   label: "Admin Data",
    //   redirectLink: "/settings/admin-data"
    // }
  ];

  const forceUpdate = useForceUpdate();
  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  return (
    <Stack alignItems="center" pt={{ xs: 2, md: 4 }} pb={20}>
      <Box width="100%" maxWidth="80rem">
        {!isCustomizeCompanyTypePath ? (
          <TabNavigation navLinks={navLinks} />
        ) : (
          false
        )}
        <SettingPagesWrapper>
          <Outlet />
        </SettingPagesWrapper>
      </Box>
    </Stack>
  );
};

export default Settings;
