/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";

import intl from "react-intl-universal";

import { Box, DialogContent, Divider, Link, Typography } from "@mui/material";

import BootstrapInitialData from "@components/BootstrapInitialData";
import Modal from "@components/Modal";
import { Tab, TabPanel, Tabs } from "@components/Tab";

import BasicInformation from "@components/Debugger/BasicInformation";
import InviteCandidate from "@components/Debugger/InviteCandidate";
import LanguageSwitcher from "@components/Debugger/LanguageSwitcher";
import Login from "@components/Debugger/Login";
import Navigation from "@components/Debugger/Navigation";
import ProfileData from "@components/Debugger/ProfileData";

const Debugger = () => {
  const [activeTabValue, setActiveTabValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Box
        component="div"
        position="absolute"
        top={0}
        right={5}
        bgcolor="red"
        paddingX={1}
        zIndex={15000}
        onClick={() => setOpen(!open)}>
        <Typography variant="body1">
          <Link href="#">{intl.get("t_debugger_title")}</Link>
        </Typography>
      </Box>
      <Modal
        title={`${intl.get("t_debugger_title")} - ${
          process.env.REACT_APP_VERSION
        }`}
        open={open}
        maxWidth="xl"
        onClose={() => setOpen(false)}>
        <DialogContent>
          <Box my={1}>
            <BootstrapInitialData />
          </Box>
          <Tabs value={activeTabValue}>
            <Tab
              value={0}
              isActive={activeTabValue === 0}
              changeHandler={() => setActiveTabValue(0)}>
              {intl.get("t_debugger_basic_information")}
            </Tab>
            <Tab
              value={1}
              isActive={activeTabValue === 1}
              changeHandler={() => setActiveTabValue(1)}>
              {intl.get("t_debugger_language_switcher")}
            </Tab>
            <Tab
              value={2}
              isActive={activeTabValue === 2}
              changeHandler={() => setActiveTabValue(2)}>
              {intl.get("t_debugger_login")}
            </Tab>
            <Tab
              value={3}
              isActive={activeTabValue === 3}
              changeHandler={() => setActiveTabValue(3)}>
              {intl.get("t_debugger_profile_data")}
            </Tab>
            <Tab
              value={4}
              isActive={activeTabValue === 4}
              changeHandler={() => setActiveTabValue(4)}>
              {intl.get("t_debugger_navigation")}
            </Tab>
            <Tab
              value={5}
              isActive={activeTabValue === 5}
              changeHandler={() => setActiveTabValue(5)}>
              {intl.get("t_debugger_invite_candidate")}
            </Tab>
          </Tabs>
          <Divider />
          <br />
          <TabPanel value={activeTabValue} index={0}>
            <BasicInformation />
          </TabPanel>
          <TabPanel value={activeTabValue} index={1}>
            <LanguageSwitcher />
          </TabPanel>
          <TabPanel value={activeTabValue} index={2}>
            <Typography>
              <Login />
            </Typography>
          </TabPanel>
          <TabPanel value={activeTabValue} index={3}>
            <Typography>
              <ProfileData />
            </Typography>
          </TabPanel>
          <TabPanel value={activeTabValue} index={4}>
            <Navigation />
          </TabPanel>
          <TabPanel value={activeTabValue} index={5}>
            <InviteCandidate />
          </TabPanel>
        </DialogContent>
      </Modal>
    </>
  );
};

export default Debugger;
