import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import intl from "react-intl-universal";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import Button from "@components/Button";
import TelephoneInput from "@components/TelephoneInput";
import TextField from "@components/TextField";

import useToast from "@hooks/useToast";

import { auth, functions } from "@utils/firebase";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
interface companyInfoFormProps {
  companyName: string;
  contactPhoneNumber: string;
  contactName: string;
  email: string;
}

const CompanyInformation = () => {
  const [user] = useAuthState(auth);
  const companyDetails = useCompanyDetails();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber); //this is required to update the phone number because the useAuthState hook does not return the updated phone number real time

  const toast = useToast();

  const schema = yup.object({
    companyName: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_company_name")
        })
      ),
    contactPhoneNumber: yup
      .string()
      .test(
        "phone",
        intl.get("t_error_invalid", {
          field: intl.get("t_general_phone_number")
        }),
        (value) => {
          // Empty error will be handled separately.
          if (!value) {
            return true;
          }
          const phoneNumber = parsePhoneNumberFromString(value);
          if (!phoneNumber) {
            return false;
          }
          return phoneNumber.isValid();
        }
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_phone_number")
        })
      ),
    contactName: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_contact_name")
        })
      ),
    email: yup
      .string()
      .email()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_email_address")
        })
      )
  });

  const methods = useForm({
    defaultValues: {
      companyName: "",
      contactPhoneNumber: "",
      contactName: "",
      email: ""
    },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset } = methods;

  useEffect(() => {
    reset({
      companyName: resolveMultiLingual(companyDetails.value?.name),
      contactPhoneNumber: phoneNumber ?? "",
      contactName: resolveMultiLingual(companyDetails.value?.contact_name),
      email: user?.email ?? ""
    });
  }, [companyDetails.value?.name, companyDetails.value?.contact_name]);

  const handleCompanyDetailsUpdateSuccess = () => {
    setIsLoading(false);
    toast.kampai(intl.get("t_company_information_update_success"), "success");
  };

  const handleCompanyDetailsUpdateFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = async (formData: companyInfoFormProps) => {
    const { companyName, contactName, contactPhoneNumber } = formData;
    setIsLoading(true);
    if (contactPhoneNumber && contactPhoneNumber !== user?.phoneNumber) {
      const setUserPhoneNumber = httpsCallable(functions, "setUserPhoneNumber");
      await setUserPhoneNumber(contactPhoneNumber);
      reset({ contactPhoneNumber });
      setPhoneNumber(contactPhoneNumber);
    }
    if (companyDetails?.setValue) {
      companyDetails.setValue(
        {
          ...companyDetails.value,
          name: prepareMultiLingual(companyName),
          contact_name: prepareMultiLingual(contactName),
          updated_at: Timestamp.now()
        },
        handleCompanyDetailsUpdateSuccess,
        handleCompanyDetailsUpdateFail
      );
    }
  };

  return (
    <>
      <Typography variant="h4" mb={5}>
        {intl.get("t_company_information")}
      </Typography>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}>
        <TextField
          control={control}
          name="companyName"
          label={intl.get("t_general_company_name")}
          placeholder={intl.get("t_general_company_name")}
          required
          disabled={isLoading}
        />
        <TextField
          control={control}
          name="contactName"
          label={intl.get("t_general_contact_name")}
          placeholder={intl.get("t_general_contact_name")}
          required
          disabled={isLoading}
        />
        <TelephoneInput
          control={control}
          name="contactPhoneNumber"
          label={intl.get("t_complete_profile_contact_phone_number")}
          placeholder={intl.get("t_general_phone_number")}
          required
          disabled={isLoading}
        />
        <TextField
          type="email"
          name="email"
          disabled
          control={control}
          label={intl.get("t_register_email_address")}
          placeholder={intl.get("t_register_email_address_placeholder")}
          required
        />
        <Stack direction="row-reverse">
          <Button type="submit" loading={isLoading}>
            {intl.get("t_general_update")}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default CompanyInformation;
