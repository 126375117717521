import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { Box, Divider, styled, Tooltip, Typography } from "@mui/material";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const StyledHelpOutlineIcon = styled(HelpOutlineIcon)(() => ({
  cursor: "pointer"
}));

const TooltipPage = () => {
  return (
    <>
      <Heading text="Tooltip" />
      <Tooltip
        placement="bottom-end"
        title={
          <Box>
            <Box p={2}>
              <Typography>Analytical</Typography>
              <Typography>Needs: to be right</Typography>
              <Typography>Prefers: to think</Typography>
              <Typography>Weakness: pushing</Typography>
            </Box>
            <Divider />
            <Box p={2}>
              <Typography>Analytical</Typography>
              <Typography>Needs: to be right</Typography>
              <Typography>Prefers: to think</Typography>
              <Typography>Weakness: pushing</Typography>
            </Box>
            <Divider />
            <Box p={2}>
              <Typography>Amiable</Typography>
              <Typography>Needs: to be safe </Typography>
              <Typography>Prefers: relationships</Typography>
              <Typography>Weakness: Taking action</Typography>
            </Box>
            <Divider />
            <Box p={2}>
              <Typography>Expressive</Typography>
              <Typography>Needs: Approval</Typography>
              <Typography>Prefers: spontaneity</Typography>
              <Typography>Weakness: impulsiveness</Typography>
            </Box>
          </Box>
        }
        arrow>
        <StyledHelpOutlineIcon color="secondary" />
      </Tooltip>
    </>
  );
};

export default TooltipPage;
