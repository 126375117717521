import { ReactNode } from "react";

import { Control, Controller } from "react-hook-form";

import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio as MuiRadio,
  RadioGroup
} from "@mui/material";

import DangerousComponentFromHTML from "@utils/components/DangerousComponentFromHTML";

export interface FormInputProps {
  name: string;
  control: Control<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  label?: string;
  disabled?: boolean;
  required?: boolean;
  options: Array<{
    label: string | ReactNode;
    value: string | number;
    disabled?: boolean;
  }>;
  direction?: "row" | "col";
  size?: "small" | "medium";
  labelAsHtml?: boolean; // It will render form label as HTML
}

const Radio = ({
  name,
  control,
  label,
  disabled = false,
  required = false,
  options,
  direction = "row",
  size = "small",
  labelAsHtml = false
}: FormInputProps) => {
  const generateRadioOptions = () => {
    return options.map((singleOption, index) => (
      <FormControlLabel
        key={index}
        value={singleOption.value}
        label={singleOption.label}
        control={
          <MuiRadio disabled={disabled || singleOption.disabled} size={size} />
        }
      />
    ));
  };

  return (
    <>
      {label ? (
        <FormLabel component="legend" required={required}>
          {labelAsHtml ? <DangerousComponentFromHTML html={label} /> : label}
        </FormLabel>
      ) : (
        false
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <RadioGroup
                value={value}
                onChange={onChange}
                row={direction === "col" ? false : true}>
                {generateRadioOptions()}
              </RadioGroup>
              {error ? (
                <FormHelperText error={!!error}>
                  {error?.message}
                </FormHelperText>
              ) : (
                false
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default Radio;
