/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import {
  Control,
  Controller,
  FieldError,
  Merge,
  UseFormSetValue
} from "react-hook-form";

import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Checkbox as MuiCheckbox,
  styled
} from "@mui/material";

export interface CheckboxProps {
  name: string;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  options: Array<{ label: string; value: string | number }>;
  size?: "medium";
  setValue: UseFormSetValue<any>;
  error?: Merge<FieldError, (FieldError | undefined)[]> | undefined;
}

const StyledCheckIcon = styled(DoneRoundedIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: "1rem",
  background: theme.palette.primary.main
}));

const Checkbox = ({
  name,
  control,
  setValue,
  label,
  options,
  required = false,
  disabled = false,
  error,
  size = "medium"
}: CheckboxProps) => {
  const [selectedItems, setSelectedItems] = useState<(string | number)[]>([]);

  // handling the selection manually
  const handleSelect = (value: string | number) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter(
        (item: string | number) => item !== value
      );
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems: (string | number)[]) => [
        ...prevItems,
        value
      ]);
    }
  };

  // setting form value manually here
  useEffect(() => {
    setValue(name, selectedItems);
  }, [selectedItems]);

  return (
    <>
      {label && (
        <FormLabel component="legend" required={required}>
          {label}
        </FormLabel>
      )}
      <Box component="div">
        {options.map(
          (singleOption: { label: string; value: string | number }) => {
            return (
              <FormControlLabel
                control={
                  <Controller
                    name={name}
                    render={() => {
                      return (
                        <MuiCheckbox
                          checkedIcon={<StyledCheckIcon />}
                          size={size}
                          checked={selectedItems.includes(singleOption.value)}
                          onChange={() => handleSelect(singleOption.value)}
                          disabled={disabled}
                        />
                      );
                    }}
                    control={control}
                  />
                }
                disabled={disabled}
                label={singleOption.label}
                key={singleOption.value}
              />
            );
          }
        )}
        {error && (
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        )}
      </Box>
    </>
  );
};

export default Checkbox;
