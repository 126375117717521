import { useNavigate } from "react-router-dom";

import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  ErrorRounded as ErrorRoundedIcon
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import Button from "@components/Button";

import theme from "@utils/theme";

import CandidatePagesWrapper from "@pages/Candidates/CandidatePagesWrapper";

interface TestStatusProps {
  status?: "error" | "success";
  heading: string;
  subHeading: string;
  redirectionLink?: string;
  btnText?: string;
}

const TestStatus = ({
  status = "error",
  heading,
  subHeading,
  redirectionLink,
  btnText
}: TestStatusProps) => {
  const navigate = useNavigate();
  return (
    <CandidatePagesWrapper>
      <Stack
        justifyContent="center"
        alignItems="center"
        bgcolor={theme.palette.background.default}
        borderRadius="10px"
        px={1.25}
        py={{ xs: 5, lg: 18.25 }}>
        <Box mb={2.5}>
          {status === "success" ? (
            <CheckCircleRoundedIcon color="success" sx={{ fontSize: 48 }} />
          ) : (
            <ErrorRoundedIcon color="error" sx={{ fontSize: 48 }} />
          )}
        </Box>
        <Typography variant="h3" textAlign="center" mb={1.5}>
          {heading}
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          mb={3}
          color={theme.palette.text.secondary}>
          {subHeading}
        </Typography>
        {/* FIXME: confirm button re-direction with shota san */}
        {redirectionLink && btnText ? (
          <Box width={{ xs: "100%", md: "8.75rem" }}>
            <Button
              type="submit"
              size="large"
              fullWidth
              onClick={() => navigate(redirectionLink)}>
              {btnText}
            </Button>
          </Box>
        ) : (
          false
        )}
      </Stack>
    </CandidatePagesWrapper>
  );
};

export default TestStatus;
