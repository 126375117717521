/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogActions, DialogContent, Link, Stack } from "@mui/material";
import dayjs from "dayjs";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import Button from "@components/Button";
import Modal from "@components/Modal";
import TextField from "@components/TextField";

import useToast from "@hooks/useToast";

import {
  ENVIRONMENT,
  FIRESTORE_COLLECTIONS,
  USER_TEST_STATUS
} from "@utils/config";
import { auth, db, functions } from "@utils/firebase";
import Timestamp from "@utils/Timestamp";

import useUserProfile from "@hooks/database/useUserProfile";
import CandidateSubmission from "@interfaces/database/CandidateSubmission";
import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";

interface InviteCandidateModalFormProps {
  name: string;
  email: string;
}

interface Row {
  nameAndEmail: {
    name: string;
    email: string;
  };
  nationality: string;
  invitedDate: string;
  takenDate: string;
  status: typeof USER_TEST_STATUS[keyof typeof USER_TEST_STATUS];
  result: string;
}

interface InviteCandidateModalProps {
  rowsData: Array<Row>;
  setRowsData: (rowData: Array<Row>) => void;
}

const InviteCandidateModal = ({
  setRowsData,
  rowsData
}: InviteCandidateModalProps) => {
  // validation schema
  const schema = yup.object({
    name: yup.string().required("Name is required"), // FIXME: update validation
    email: yup.string().email().required("Email is required")
  });

  const [invitationLink, setInvitationLink] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [isInvitationLoading, setIsInvitationLoading] =
    useState<boolean>(false);

  const sendTestInvitationLink = httpsCallable(
    functions,
    "sendTestInvitationLink"
  );
  const toast = useToast();
  const user = useAuthState(auth);
  const userProfile = useUserProfile();
  const companyId = userProfile?.value?.company_ids?.[0];

  const methods = useForm({
    defaultValues: { name: "", email: "" },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset } = methods;

  const handleFormSubmit = async (formData: InviteCandidateModalFormProps) => {
    const { name, email } = formData;
    if (!user) {
      toast.kampai("Please login to send test invitation", "error");
    }
    try {
      setIsInvitationLoading(true);
      const submissionData: CandidateSubmission = {
        candidate_information: {
          email,
          name: {
            en: name,
            ja: "" // handle language logic
          }
        },
        current_active_step: "0_questionId", // update logic for current active step,
        test_status: USER_TEST_STATUS.INVITED,
        created_at: Timestamp.now(),
        updated_at: Timestamp.now()
      };

      const testCollectionRef = collection(db, FIRESTORE_COLLECTIONS.TESTS);
      const testDocs = await getDocs(testCollectionRef);
      const testId = testDocs?.docs?.[0]?.id;

      const submissionCollectionReference = collection(
        db,
        `${FIRESTORE_COLLECTIONS.TESTS}/${testId}/${FIRESTORE_COLLECTIONS.SUBMISSIONS}`
      );
      const companyDocReference = doc(
        db,
        `${FIRESTORE_COLLECTIONS.COMPANIES}/${companyId}`
      );

      const submissionDocument = await addDoc(
        submissionCollectionReference,
        submissionData
      );

      const invitationLink = await sendTestInvitationLink({
        submissionId: submissionDocument.id,
        testId,
        email
      });

      if (invitationLink?.data) {
        toast.kampai("Test invitation link send to your email", "success");
        setInvitationLink(invitationLink?.data as string);

        const invitedUsersSubmission = [
          `${testId}__${submissionDocument.id}`,
          ...(userProfile?.value?.company_information
            ?.invited_users_submission ?? [])
        ];

        try {
          await updateDoc(companyDocReference, {
            invited_users_submission: invitedUsersSubmission
          });
          setRowsData([
            {
              nameAndEmail: { name: name ?? "", email: email ?? "" },
              nationality: "-",
              invitedDate: dayjs(Timestamp.now()?.toDate()).format(
                "DD.MM.YYYY"
              ),
              takenDate: "-",
              status: USER_TEST_STATUS.INVITED,
              result: ""
            },
            ...rowsData
          ]);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error updating document:", error);
        }
      }
      setIsInvitationLoading(false);
      setOpen(false);
      reset({ name: "", email: "" });
    } catch (error) {
      setIsInvitationLoading(false);
      // eslint-disable-next-line no-console
      console.log({ error });
    }
  };

  return (
    <>
      {/* <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
        {invitationLink ? (
          <Link href={invitationLink} target="_blank" mb={2}>
            {invitationLink}
          </Link>
        ) : (
          false
        )}
      </EnvironmentSpecific> */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(!open)}>
        Invite Candidates
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        title="Add Candidate">
        <Box
          noValidate
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogContent>
            <TextField
              control={control}
              name="name"
              label="Candidate's Name"
              placeholder="Enter name"
              required
            />
            <TextField
              control={control}
              name="email"
              label="Candidate's Email"
              placeholder="Enter email address"
              required
            />
          </DialogContent>
          <DialogActions>
            <Stack alignItems="flex-end" mt={1} width="100%">
              <Box width={{ md: "100%", lg: "max-content" }}>
                <Button
                  type="submit"
                  size="large"
                  fullWidth
                  loading={isInvitationLoading}>
                  Send Invitation
                </Button>
              </Box>
            </Stack>
          </DialogActions>
        </Box>
      </Modal>
    </>
  );
};

export default InviteCandidateModal;
