import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import intl from "react-intl-universal";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import {
  collection,
  getDocs,
  query,
  Timestamp,
  where
} from "firebase/firestore";

import Button from "@components/Button";
import Loader from "@components/Loader";
import Ranking from "@components/Ranking";

import useToast from "@hooks/useToast";

import {
  COMPANY_TYPE,
  FIRESTORE_COLLECTIONS,
  QUESTION_STATUS,
  ROUTES
} from "@utils/config";
import { db } from "@utils/firebase";
import { map } from "@utils/lodash";
import { getInitialValues, getValidationSchema } from "@utils/yupSchema";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import CompanyProfile from "@interfaces/database/CompanyProfile";
import CulturalTypeQuestion from "@interfaces/database/CulturalTypeQuestion";
import QuestionAnswerResponse from "@interfaces/database/QuestionAnswerResponse";

const CustomizeCompanyType = () => {
  const [questions, setQuestions] = useState([]);
  const [isQuestionsLoading, setIsQuestionsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const companyDetails = useCompanyDetails();

  const toast = useToast();

  const methods = useForm({
    defaultValues: getInitialValues(questions),
    resolver: yupResolver(getValidationSchema(questions))
  });

  const { handleSubmit, control, setValue, reset } = methods;

  useEffect(() => {
    try {
      const fetchQuestions = async () => {
        setIsQuestionsLoading(true);
        const questionsRef = collection(
          db,
          FIRESTORE_COLLECTIONS.COMPANY_QUESTIONS
        );
        const questionQuery = query(
          questionsRef,
          where("status", "==", QUESTION_STATUS.ACTIVE)
        );

        const questionData: any = []; // eslint-disable-line @typescript-eslint/no-explicit-any
        const querySnapshot = await getDocs(questionQuery);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            questionData.push({
              id: doc.id,
              ...doc.data()
            });
          });
        }
        setQuestions(questionData);
        setIsQuestionsLoading(false);
      };
      fetchQuestions();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  }, []);

  useEffect(() => {
    if (
      companyDetails &&
      companyDetails.value &&
      companyDetails.value.question_answer_responses &&
      companyDetails.value.question_answer_responses.length > 0
    ) {
      // Extract question and answer data and convert it into an object
      const questionAnswerMap =
        companyDetails?.value?.question_answer_responses.reduce(
          (acc: Record<string, number>, response: QuestionAnswerResponse) => {
            if (response?.question_id) {
              acc[response.question_id] = Number(response.user_entered_answer);
            }
            return acc;
          },
          {}
        );
      // Reset the form with the extracted data
      reset(questionAnswerMap);
    }
  }, [companyDetails?.value?.question_answer_responses]);

  const handleCompanyDetailsUpdateSuccess = () => {
    // FIXME: update the message
    toast.kampai("Test completed, Now you can invite candidates", "success");
    navigate(ROUTES.candidateList);
  };

  const handleCompanyDetailsUpdateFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = (fromData: Record<string, string>) => {
    const questionAnswerResponse: Array<QuestionAnswerResponse> = map(
      fromData,
      (user_entered_answer, question_id) => ({
        question_id,
        user_entered_answer
      })
    );
    try {
      const updatedCustomizeCompanyTypeQuestionAnswerResponses: CompanyProfile =
        {
          ...companyDetails.value,
          company_type: COMPANY_TYPE.CUSTOMIZE,
          question_answer_responses: questionAnswerResponse,
          updated_at: Timestamp.now()
        };
      if (companyDetails?.setValue) {
        companyDetails.setValue(
          updatedCustomizeCompanyTypeQuestionAnswerResponses,
          handleCompanyDetailsUpdateSuccess,
          handleCompanyDetailsUpdateFail
        );
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  if (isQuestionsLoading) {
    return <Loader />;
  }

  return (
    <>
      <Typography variant="h4">Customize Company Type</Typography>
      <Typography variant="body1" mt={2}>
        Choose out of 5 options: Closer to A, Closer to B
      </Typography>
      <Box
        noValidate
        id="cultural-match-form"
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}>
        {questions.map((singleQuestion: CulturalTypeQuestion) => {
          return (
            <Box my={6} key={singleQuestion?.id ?? ""}>
              <Ranking
                name={singleQuestion?.id ?? ""}
                required
                label={singleQuestion?.question?.en ?? ""}
                labelAsHtml
                control={control}
                setValue={setValue}
              />
            </Box>
          );
        })}
        <Button type="submit">{intl.get("t_general_submit")}</Button>
      </Box>
    </>
  );
};

export default CustomizeCompanyType;
