import { ReactNode } from "react";

import { Tab as MuiTab, styled } from "@mui/material";

interface CustomTabProps {
  value: number;
  children: ReactNode;
  changeHandler: (newValue: number) => void;
  showBadge?: boolean;
  isActive?: boolean;
}

const StyledTab = styled(MuiTab)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    maxWidth: "7rem"
  }
}));

const Tab = ({
  value,
  children,
  changeHandler,
  isActive = false
}: CustomTabProps) => {
  return (
    <StyledTab
      onClick={() => changeHandler(value)}
      className={isActive ? "Mui-selected" : ""}
      label={children}
      wrapped
    />
  );
};

export default Tab;
