import { LinearProgress, Stack, Typography } from "@mui/material";

interface StepperProps {
  currentStep: number;
  steps: Array<{ title: string }>;
}

const Stepper = ({ currentStep = 0, steps }: StepperProps) => {
  const singleStepSize = 100 / steps.length;
  const linearProgressValue =
    currentStep === 0 ? singleStepSize : singleStepSize * (currentStep + 1);

  return (
    <>
      <Stack direction="row">
        {steps.map((singleStep, index) => {
          return (
            <Typography
              key={index}
              color={currentStep >= index ? "primary.main" : "white"}
              width={{ xs: "100%", md: `${singleStepSize}%` }}
              textAlign="start"
              display={{
                xs: currentStep === index ? "block" : "none",
                md: "block"
              }}>
              {index + 1 + "."} {singleStep.title}
            </Typography>
          );
        })}
      </Stack>
      <LinearProgress variant="determinate" value={linearProgressValue} />
    </>
  );
};

export default Stepper;
