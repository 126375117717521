import { Badge, Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import { envOptions } from "@utils/firebase";

const BasicInformation = () => {
  return (
    <>
      <Typography variant="h5">Basic information:</Typography>
      <br />
      <Typography>
        Deployed on: {dayjs(process.env.REACT_APP_RELEASED_TIMESTAMP).fromNow()}
      </Typography>
      <br />
      <Typography>Current URL: {window.location.hostname}</Typography>
      <br />
      <Typography>Current Path: {window.location.pathname}</Typography>
      <br />
      <Typography>Query Parameters: {location.search}</Typography>
      <br />
      <Typography>
        Firebase connection status:&nbsp;
        {envOptions.isEmulator ? (
          <>
            &nbsp; <Badge color="success" variant="dot" /> &nbsp; Connected to
            Firebase Emulator
          </>
        ) : (
          <>
            &nbsp; <Badge color="error" variant="dot" /> &nbsp; Not connected to
            the local emulator
          </>
        )}
      </Typography>
      <br />
      <Typography>
        Firebase connected environment information: {envOptions.appId}
      </Typography>
      <Typography>Storage Host: {envOptions.storageHost}</Typography>
    </>
  );
};

export default BasicInformation;
