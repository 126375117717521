import { Alert, Box } from "@mui/material";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const BannerPage = () => {
  return (
    <>
      <Heading text="Banner" />
      <Box mb={2}>
        <Alert variant="filled" severity="error">
          This is an error alert — check it out!
        </Alert>
      </Box>
      <Box mb={2}>
        <Alert variant="filled" severity="warning">
          This is a warning alert — check it out!
        </Alert>
      </Box>
      <Box mb={2}>
        <Alert variant="filled" severity="info">
          This is an info alert — check it out!
        </Alert>
      </Box>
      <Box mb={2}>
        <Alert variant="filled" severity="success">
          This is a success alert — check it out!
        </Alert>
      </Box>
    </>
  );
};

export default BannerPage;
