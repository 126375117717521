import ProgressBar from "@components/ProgressBar";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const ProgressBarPage = () => {
  return (
    <>
      <Heading text="Progress bar" />
      <ProgressBar value={10} severity="success" label="Subjective Thinking" />
      <ProgressBar value={20} severity="error" label="Emotional Intelligence" />
      <ProgressBar value={30} severity="warning" label="Attention to Detail" />
    </>
  );
};

export default ProgressBarPage;
