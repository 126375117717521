import { useEffect, useState } from "react";

import intl from "react-intl-universal";
import { useSearchParams } from "react-router-dom";

import { DialogContent, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";

import Button from "@components/Button";
import Dialog from "@components/Dialog";
import Loader from "@components/Loader";

import useToast from "@hooks/useToast";

import { auth, functions, setAuthLocale } from "@utils/firebase";
import translate from "@utils/translate";

const VerificationEmailSentDialog = () => {
  const [isShowDialog, setShowDialog] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, isUserLoading] = useAuthState(auth);
  const toast = useToast();

  const currentLocale = translate.getCurrentLocale();

  const [
    sendEmailVerification,
    isSendEmailVerificationLoading,
    sendEmailVerificationError
  ] = useHttpsCallable(functions, "verifyEmail");

  useEffect(() => {
    if (sendEmailVerificationError) {
      toast.kampai(intl.get("t_verify_email_error"), "error");
    }
  }, [sendEmailVerificationError]);

  useEffect(() => {
    const isSendVerificationEmail =
      searchParams.get("send_verification_email") ?? "";
    if (user) {
      if (isSendVerificationEmail && !user?.emailVerified) {
        setShowDialog(true);
        setAuthLocale();
        const url =
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":" +
          window.location.port;
        sendEmailVerification({
          returnURL: url,
          locale: currentLocale,
          fullName: user.displayName ?? ""
        });
      }
    }
  }, [searchParams, user]);

  const deleteSearchParam = () => {
    searchParams.delete("send_verification_email");
    setSearchParams(searchParams.toString(), { replace: true });
  };

  return (
    <>
      {isUserLoading || isSendEmailVerificationLoading ? <Loader /> : false}
      {isShowDialog ? (
        <Dialog
          initiator=""
          isDefaultOpen
          maxWidth="xs"
          title={intl.get("t_verify_email_heading")}
          onClose={deleteSearchParam}>
          {(_, closeDialog) => (
            <DialogContent>
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle4">
                  {intl.get("t_verify_email_title", {
                    email: user?.email
                  })}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {intl.get("t_verify_email_subtitle")}
                </Typography>
                <Button fullWidth onClick={closeDialog}>
                  {intl.get("t_general_close")}
                </Button>

                <Typography
                  variant="body2"
                  width="100%"
                  mt={2}
                  textAlign="center">
                  {intl.get("t_general_copyright", {
                    year: dayjs().format("YYYY")
                  })}
                </Typography>
              </Stack>
            </DialogContent>
          )}
        </Dialog>
      ) : (
        false
      )}
    </>
  );
};

export default VerificationEmailSentDialog;
