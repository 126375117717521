import React, { MouseEvent } from "react";

import intl from "react-intl-universal";
import { Link } from "react-router-dom";

import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import dayjs from "dayjs";

import footerFbActive from "@assets/icons/footer-fb-active.svg";
import footerFb from "@assets/icons/footer-fb.svg";
import footerLinkedInActive from "@assets/icons/footer-linkedIn-active.svg";
import footerLinkedIn from "@assets/icons/footer-linkedIn.svg";
import footerTwitterActive from "@assets/icons/footer-twitter-active.svg";
import footerTwitter from "@assets/icons/footer-twitter.svg";
import logoWhite from "@assets/logos/logo-white.svg";

const StyledFooterLink = styled(Link)(({ theme }) => ({
  "color": theme.palette.text.secondary,
  "opacity": 0.8,
  "cursor": "pointer",
  "textDecoration": "none",
  ":hover": {
    color: theme.palette.text.primary,
    fontWeight: 500,
    opacity: 1
  }
}));

const Footer = () => {
  return (
    <>
      <Grid container columnSpacing={{ md: 3 }}>
        <Grid item xs={4} sm={2.5} md={2}>
          <Link rel="noopener noreferrer" to="/">
            <Box
              component="img"
              width={{ xs: 80, lg: 120 }}
              height={{ xs: 80, lg: 120 }}
              src={logoWhite}
              alt="tokhimo-logo-white"
            />
          </Link>
        </Grid>
        <Grid item xs={8} sm={4} md={2.2} mb={{ xs: 3, sm: 0 }} mt={{ md: 4 }}>
          <Stack rowGap={1.5}>
            <Typography variant="h4" mb={0.5}>
              About Tokhimo
            </Typography>
            {/* FIXME: Update footer link */}
            <StyledFooterLink to="/" target="_blank" rel="noopener noreferrer">
              Contact Us
            </StyledFooterLink>
            <StyledFooterLink to="/" target="_blank" rel="noopener noreferrer">
              Corporate Site
            </StyledFooterLink>
            <StyledFooterLink to="/" target="_blank" rel="noopener noreferrer">
              Tokhimo Jobs
            </StyledFooterLink>
          </Stack>
        </Grid>
        <Grid item xs={4} display={{ sm: "none" }} />
        <Grid item xs={8} sm={4} md={2} mt={{ md: 4 }}>
          <Stack rowGap={1.5}>
            <Typography variant="h4" mb={2}>
              Follow Us
            </Typography>
            <Stack direction="row" columnGap={2}>
              <StyledFooterLink to="/">
                <Box
                  component="img"
                  src={footerLinkedIn}
                  alt="linkedin"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onMouseOver={(e: MouseEvent<any>) => {
                    e.currentTarget.src = footerLinkedInActive;
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onMouseOut={(e: MouseEvent<any>) => {
                    e.currentTarget.src = footerLinkedIn;
                  }}
                />
              </StyledFooterLink>
              <StyledFooterLink to="/">
                <Box
                  component="img"
                  src={footerTwitter}
                  alt="twitter"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onMouseOver={(e: MouseEvent<any>) => {
                    e.currentTarget.src = footerTwitterActive;
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onMouseOut={(e: MouseEvent<any>) => {
                    e.currentTarget.src = footerTwitter;
                  }}
                />
              </StyledFooterLink>
              <StyledFooterLink to="/">
                <Box
                  component="img"
                  src={footerFb}
                  alt="facebook"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onMouseOver={(e: MouseEvent<any>) => {
                    e.currentTarget.src = footerFbActive;
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onMouseOut={(e: MouseEvent<any>) => {
                    e.currentTarget.src = footerFb;
                  }}
                />
              </StyledFooterLink>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Typography
        color="text.secondary"
        mt={{ xs: 8, sm: 6.5, md: 26.5 }}
        mb={3}
        textAlign="center">
        © {dayjs().format("YYYY")}
        {intl.get("t_general_copyright")}
      </Typography>
    </>
  );
};

export default Footer;
