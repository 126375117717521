// FIXME: Dummy page for testing -  remove this page in prod
import Cultural from "@components/Cultural";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const randomNumber = () => parseFloat((Math.random() * 4 + 1).toFixed(1));
const CulturalChartPage = () => {
  return (
    <>
      <Heading text="Cultural Chart" />
      <Cultural
        domain={{ x: [1, 8], y: [1, 5] }}
        japaneseCultureDataPoints={[...Array(8)].map((_, index) => ({
          x: index + 1,
          y: randomNumber()
        }))}
        examineeDataPoints={[...Array(8)].map((_, index) => ({
          x: index + 1,
          y: randomNumber()
        }))}
      />
    </>
  );
};

export default CulturalChartPage;
