import { Avatar, styled } from "@mui/material";

import { colorPalette } from "@utils/theme";

import Briefcase from "@assets/images/Briefcase.svg";
import CheckCircleOutline from "@assets/images/CheckCircleOutline.svg";
import PeopleGroup from "@assets/images/PeopleGroup.svg";
import SmilingFace from "@assets/images/SmilingFace.svg";
import ThumbUp from "@assets/images/ThumbUp.svg";
import TwoGear from "@assets/images/TwoGear.svg";

export interface AccessmentCategoriesIconProps {
  image:
    | "briefcase"
    | "peoplegroup"
    | "thumbup"
    | "twogear"
    | "smilingface"
    | "checkcircleoutline";
  alt: string;
}

const imageToSrcMapping = {
  briefcase: Briefcase,
  peoplegroup: PeopleGroup,
  thumbup: ThumbUp,
  twogear: TwoGear,
  smilingface: SmilingFace,
  checkcircleoutline: CheckCircleOutline
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: colorPalette.blue[20],
  borderRadius: "0.25rem",
  [theme.breakpoints.down("md")]: {
    height: "3rem",
    width: "3rem"
  },
  [theme.breakpoints.up("md")]: {
    height: "3.5rem",
    width: "3.5rem"
  }
}));

const StyledImage = styled("img")({
  height: "2.5rem",
  width: "2.5rem"
});

const AccessmentCategoriesIcon = ({
  image,
  alt
}: AccessmentCategoriesIconProps) => {
  return (
    <StyledAvatar variant="square">
      <StyledImage src={imageToSrcMapping[image]} alt={alt} />
    </StyledAvatar>
  );
};

export default AccessmentCategoriesIcon;
