import { Context, createContext, ReactNode, useEffect, useState } from "react";

import { SnackbarOrigin } from "@mui/material";

import Toast from "@components/Toast";

import { TOAST_DEFAULT_HIDE_DURATION_MS } from "@utils/config";

interface ToastContextProps {
  children?: ReactNode;
}

interface KampaiParams {
  message: string;
  variant?: "success" | "error" | "info" | "warning" | "none";
  isPersistent?: boolean;
  autoHideDuration?: number;
  position?: SnackbarOrigin;
}

export const toastContext: Context<{
  kampai: (
    message: KampaiParams["message"],
    variant?: KampaiParams["variant"],
    isPersistent?: KampaiParams["isPersistent"],
    autoHideDuration?: KampaiParams["autoHideDuration"],
    position?: KampaiParams["position"]
  ) => void;
}> = createContext({
  kampai: (_message, _variant, _isPersistent, _autoHideDuration, _position) => {
    return;
  }
});

const ToastContext = ({ children }: ToastContextProps) => {
  const [currentToastDetails, setCurrentToastDetails] = useState<KampaiParams>({
    message: "",
    variant: "none",
    isPersistent: false
  });
  const [isToastOpen, setToastOpen] = useState<boolean>(false);
  const [ticks] = useState<Array<KampaiParams>>([]);

  const updateToastDetails = () => {
    const toastDetails = {
      message: ticks[0].message,
      variant: ticks[0].variant
    };
    ticks.shift();
    setCurrentToastDetails(toastDetails);
    setToastOpen(true);
  };

  useEffect(() => {
    if (!isToastOpen && ticks.length) {
      /**
       * This is situation where the toast has recently been closed
       * and there are more messages available to display.
       * So, before showing the next message, give 250ms cooldown.
       * This would allow the animation to complete.
       * Then update details and show the next message.
       */
      setTimeout(() => {
        updateToastDetails();
      }, 250);
    }
  }, [isToastOpen]);

  const data = {
    kampai: (
      message: KampaiParams["message"],
      variant: KampaiParams["variant"] = "none",
      isPersistent: KampaiParams["isPersistent"] = false,
      autoHideDuration: KampaiParams["autoHideDuration"] = TOAST_DEFAULT_HIDE_DURATION_MS,
      position: KampaiParams["position"] = {
        vertical: "top",
        horizontal: "right"
      }
    ) => {
      ticks.push({
        message,
        variant,
        isPersistent,
        autoHideDuration,
        position
      });
      if (isToastOpen) {
        setToastOpen(false);
      } else {
        updateToastDetails();
      }
    }
  };

  return (
    <toastContext.Provider value={data}>
      <Toast
        {...currentToastDetails}
        open={isToastOpen}
        setOpen={setToastOpen}
      />
      {children}
    </toastContext.Provider>
  );
};

export default ToastContext;
