import { Stack } from "@mui/material";

import Status from "@components/Status";

import { USER_TEST_STATUS } from "@utils/config";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const StatusPage = () => {
  return (
    <>
      <Heading text="Status" />
      <Stack direction="row" gap={3}>
        <Status label={USER_TEST_STATUS.TAKEN} />
        <Status label={USER_TEST_STATUS.INVITED} />
        <Status label={USER_TEST_STATUS.INPROGRESS} />
        <Status label={USER_TEST_STATUS.EXPIRED} />
      </Stack>
    </>
  );
};

export default StatusPage;
