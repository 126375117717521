import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import {
  DomainTuple,
  ForAxes,
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryScatter
} from "victory";

import Cursor from "@components/Cursor";

import theme, { colorPalette } from "@utils/theme";

const CustomAxis = ({
  x1,
  x2,
  y1,
  y2,
  dimension
}: {
  x1?: number;
  x2?: number;
  y1?: number;
  y2?: number;
  dimension?: string;
}) => {
  return (
    <>
      {/* Ref: http://thenewcode.com/1068/Making-Arrows-in-SVG */}
      <defs>
        <marker
          id="start-arrow"
          markerWidth="9"
          markerHeight="9"
          refX="9"
          refY="3.5"
          orient="auto">
          <polygon
            points="9 1, 9 6, 5 3.5"
            fill={theme.palette.secondary.main}></polygon>
        </marker>
        <marker
          id="end-arrow"
          markerWidth="9"
          markerHeight="9"
          refX="0"
          refY="3.5"
          orient="auto"
          markerUnits="strokeWidth">
          <polygon
            points="0 1, 5 3.5, 0 6"
            fill={theme.palette.secondary.main}
          />
        </marker>
      </defs>
      <line
        x1={x1}
        y1={y2}
        x2={x2}
        y2={dimension === "x" ? y2 : y1}
        stroke={theme.palette.secondary.main}
        strokeWidth="2"
        markerStart="url(#start-arrow)"
        markerEnd="url(#end-arrow)"
      />
    </>
  );
};

interface ChartProps {
  domain?: ForAxes<DomainTuple>;
  x?: number;
  y?: number;
  pointerSize?: number;
}

const Chart = ({
  domain = [-2, 2],
  x = 0,
  y = 0,
  pointerSize = 15
}: ChartProps) => {
  return (
    <Box
      p={{ xs: 6, md: 10 }}
      position="relative"
      bgcolor={colorPalette.grey["05"]}>
      <Typography
        top="48%"
        position="absolute"
        left={{ xs: "1.5%", md: "2.8%" }}>
        Ask
      </Typography>
      <Typography
        top="48%"
        position="absolute"
        right={{ xs: "1.5%", md: "2.8%" }}>
        Tell
      </Typography>
      <Box position="absolute" right={20} top={20}>
        <Cursor>
          <Tooltip
            placement="bottom-end"
            title={
              <>
                <Box p={2}>
                  <Typography>Analytical</Typography>
                  <Typography>Needs: to be right</Typography>
                  <Typography>Prefers: to think</Typography>
                  <Typography>Weakness: pushing</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Typography>Analytical</Typography>
                  <Typography>Needs: to be right</Typography>
                  <Typography>Prefers: to think</Typography>
                  <Typography>Weakness: pushing</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Typography>Amiable</Typography>
                  <Typography>Needs: to be safe </Typography>
                  <Typography>Prefers: relationships</Typography>
                  <Typography>Weakness: Taking action</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Typography>Expressive</Typography>
                  <Typography>Needs: Approval</Typography>
                  <Typography>Prefers: spontaneity</Typography>
                  <Typography>Weakness: impulsiveness</Typography>
                </Box>
              </>
            }
            arrow>
            <HelpOutlineIcon color="secondary" />
          </Tooltip>
        </Cursor>
      </Box>
      <Stack justifyContent="center" position="relative" mb={2.5}>
        <Typography textAlign="center" variant="subtitle4" component="p">
          Control Emotions
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography ml={{ xs: "10%", sm: "20%" }} mb={{ xs: 1, md: 2 }}>
          Analytical
        </Typography>
        <Typography ml={{ xs: "30%", sm: "40%" }} mb={{ xs: 1, md: 2 }}>
          Driving
        </Typography>
      </Stack>
      <Box
        border={`1px solid ${theme.palette.text.secondary}`}
        borderRadius="10px"
        bgcolor={colorPalette.grey["05"]}>
        <VictoryChart
          padding={15}
          domain={domain} // this is axis value
          containerComponent={<VictoryContainer responsive />}>
          <VictoryAxis
            style={{
              axis: { stroke: theme.palette.secondary.main, strokeWidth: 4 }
            }}
            tickFormat={() => ""} // hiding labels
            axisComponent={<CustomAxis />}
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: theme.palette.secondary.main, strokeWidth: 4 }
            }}
            tickFormat={() => ""}
            axisComponent={<CustomAxis />}
          />
          <VictoryScatter
            style={{
              data: {
                fill: colorPalette.blue[70],
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                opacity: ({ datum }: any) => datum.opacity
              }
            }}
            size={pointerSize}
            data={[{ x, y }]}
            animate={{
              onLoad: {
                duration: 2000,
                before: () => ({ opacity: 0.3 }),
                after: () => ({ opacity: 1 })
              }
            }}
          />
        </VictoryChart>
      </Box>
      <Stack direction="row">
        <Typography ml={{ xs: "10%", sm: "20%" }} mt={{ xs: 1, md: 2 }}>
          Amiable
        </Typography>
        <Typography ml={{ xs: "30%", sm: "40%" }} mt={{ xs: 1, md: 2 }}>
          Expressive
        </Typography>
      </Stack>
      <Typography textAlign="center" mt={2.5} variant="subtitle4" component="p">
        Displays Emotions
      </Typography>
    </Box>
  );
};

export default Chart;
