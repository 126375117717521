import Stepper from "@components/Stepper";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const StepperPage = () => {
  return (
    <>
      <Heading text="Stepper" />
      <Stepper
        currentStep={1}
        steps={[
          { title: "Cultural match" },
          { title: "Social Styles" },
          { title: "Emotional Intelligence" },
          { title: "Attention to detail" },
          { title: "Priority in Work" }
        ]}
      />
    </>
  );
};

export default StepperPage;
