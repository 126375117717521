import intl from "react-intl-universal";

import cookie from "cookie";

import { LOCALE, LOCALE_SHORT } from "@utils/config";

import enUS from "@utils/lang/en-us.json";
import jaJP from "@utils/lang/ja-jp.json";

export const LOCALES_LIST = [
  {
    label: "English",
    value: LOCALE.EN
  },
  {
    label: "日本語",
    value: LOCALE.JA
  }
];

export const LOCALE_DATA = {
  "en-US": enUS,
  "ja-JP": jaJP
};

export default (() => {
  const updateListeners: Array<() => void> = [];
  let appLocale: typeof LOCALE[keyof typeof LOCALE] = LOCALE.EN;
  const setLocale = (currentLocale: string) => {
    intl.init({
      currentLocale,
      locales: LOCALE_DATA,
      fallbackLocale: LOCALE.EN
    });
    document.cookie = cookie.serialize("lang", currentLocale);
    updateListeners.forEach((singleUpdateListener) => singleUpdateListener());
    appLocale = currentLocale as typeof LOCALE[keyof typeof LOCALE];
  };

  return {
    initializeIntl: () => {
      // 1. Get the currentLocale from url, cookie, or browser setting
      let currentLocale = intl.determineLocale({
        urlLocaleKey: "lang", // Example: https://jobs.tokhimo.com?lang=en-US
        cookieLocaleKey: "lang"
      });

      // 2. Fallback to "en-US" if the currentLocale isn't supported in LOCALES_LIST
      if (!LOCALES_LIST.some((item) => item.value === currentLocale)) {
        currentLocale = LOCALE.EN;
      }

      // 3. Set currentLocale and load locale data
      setLocale(currentLocale);
    },

    setCurrentLocale: (currentLocale: string) => {
      setLocale(currentLocale);
    },

    getCurrentLocale: () => {
      return appLocale;
    },

    getCurrentLocaleShort: () => {
      let locale: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT] =
        LOCALE_SHORT.EN;
      switch (appLocale) {
        case LOCALE.EN:
          locale = LOCALE_SHORT.EN;
          break;
        case LOCALE.JA:
          locale = LOCALE_SHORT.JA;
          break;
        default:
          locale = LOCALE_SHORT.EN;
          break;
      }
      return locale;
    },

    addLanguageChangedListener: (listener: () => void) => {
      updateListeners.push(listener);
    },
    removeLanguageChangedListener: (listener: () => void) => {
      const key = updateListeners.indexOf(listener);
      if (key !== -1) {
        updateListeners.splice(key, 1);
      }
    }
  };
})();
