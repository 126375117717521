import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import intl from "react-intl-universal";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Link as MuiLink,
  Stack,
  styled,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { FirebaseError } from "firebase/app";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import Button from "@components/Button";
import Loader from "@components/Loader";
import TextField from "@components/TextField";

import { ROUTES } from "@utils/config";
import { auth, functions, setAuthLocale } from "@utils/firebase";
import translate from "@utils/translate";

import ForgotPassword_BG from "@assets/images/forgotPassword.svg";
import Tokhimo_Logo from "@assets/logos/logo-white.svg";

interface ForgotPasswordForm {
  email: string;
}

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 62,
    width: 62
  },
  [theme.breakpoints.up("sm")]: {
    height: 55,
    width: 55
  },
  [theme.breakpoints.up("lg")]: {
    height: 62,
    width: 62
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledForgotPasswordImage = styled("img")({
  width: "95%",
  position: "relative",
  top: "calc(20vh - 1.5rem)",
  transform: "translateY(-25%)"
});

const ForgotPassword = () => {
  const { is_email_sent: isEmailSent } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const currentLocale = translate.getCurrentLocale();
  const [searchParams] = useSearchParams();

  const [forgotPassword, isForgotPasswordLoading, forgotPasswordError] =
    useHttpsCallable(functions, "forgotPassword");

  const schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_email_address")
        })
      )
  });

  const defaultFormValues: ForgotPasswordForm = {
    email: ""
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  useEffect(() => {
    const code = (forgotPasswordError as FirebaseError | undefined)?.code;
    if (code === "auth/invalid-email") {
      setErrorMessage(intl.get("t_auth_error_wrong_email"));
    } else if (code === "auth/user-not-found") {
      setErrorMessage(intl.get("t_auth_error_user_not_found"));
    } else if (
      code === "auth/internal-error" ||
      code === "auth/too-many-requests"
    ) {
      setErrorMessage(intl.get("t_general_internal_error"));
    } else {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  }, [forgotPasswordError]);

  const handleForgotPasswordForm = async (formData: ForgotPasswordForm) => {
    setAuthLocale();
    const returnURL = searchParams.get("return_url") ?? "";
    const url =
      window.location.protocol +
      "//" +
      window.location.hostname +
      ":" +
      window.location.port +
      returnURL;
    forgotPassword({
      email: formData.email,
      returnURL: url,
      locale: currentLocale
    });
    navigate(":is_email_sent?");
  };
  if (auth.currentUser) {
    return <></>;
  } else {
    return (
      <>
        {isForgotPasswordLoading ? (
          <Loader />
        ) : (
          <Grid container maxWidth={1440} alignSelf="center">
            <Grid
              item
              xs={12}
              md={6}
              position={{ md: "sticky" }}
              height={{ md: "100vh" }}
              overflow="hidden"
              top={{ md: 0 }}>
              <Box zIndex={1} mx={1.25} my={2.5} left={{ md: "5%", lg: "10%" }}>
                <MuiLink component={Link} to={ROUTES.root}>
                  <StyledLogo
                    variant="square"
                    src={Tokhimo_Logo}
                    alt={intl.get("t_alt_tokhimo_logo")}
                  />
                </MuiLink>
              </Box>
              <Box display={{ xs: "none", md: "block" }}>
                <StyledForgotPasswordImage
                  src={ForgotPassword_BG}
                  alt={intl.get("t_alt_forgot_password_background")}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              zIndex={2}
              position="relative"
              mt={{ xs: 1.5, md: 7.5 }}>
              <Container>
                {isEmailSent ? (
                  <>
                    <Typography variant="subtitle3">
                      <MuiLink
                        component={Link}
                        to={ROUTES.resetPassword}
                        underline="none">
                        <Stack flexDirection="row">
                          <ChevronLeft />
                          {intl.get("t_general_back")}
                        </Stack>
                      </MuiLink>
                    </Typography>
                    <Stack
                      height="50vh"
                      minHeight={{ xs: 400, md: 530 }}
                      justifyContent="center">
                      <Typography variant="h3">
                        {intl.get("t_forgot_password_title")}
                      </Typography>
                      <br />
                      <br />
                      <Typography variant="subtitle3">
                        {intl.get("t_forgot_password_email_sent_subtitle")}
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle3">
                      <MuiLink
                        component={Link}
                        to={ROUTES.login}
                        underline="none">
                        <Stack flexDirection="row">
                          <ChevronLeft />
                          {intl.get("t_general_back")}
                        </Stack>
                      </MuiLink>
                    </Typography>
                    <Box my={12.5}>
                      <Typography variant="h3" mb={2.5}>
                        {intl.get("t_forgot_password_title")}
                      </Typography>
                      {forgotPasswordError ? (
                        <Typography variant="subtitle4" color="error.main">
                          {errorMessage}
                        </Typography>
                      ) : (
                        false
                      )}
                      <Typography variant="subtitle3">
                        {intl.get("t_forgot_password_subtitle")}
                      </Typography>
                      <Box
                        noValidate
                        component="form"
                        onSubmit={handleSubmit(handleForgotPasswordForm)}
                        my={3.5}>
                        <TextField
                          type="email"
                          name="email"
                          control={control}
                          label={intl.get("t_general_email")}
                          placeholder={intl.get(
                            "t_register_email_address_placeholder"
                          )}
                          required
                        />
                        <Box mt={3}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large">
                            {intl.get("t_forgot_password_title")}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
                <Typography align="center" mb={7.5}>
                  © {dayjs().format("YYYY")}
                  {intl.get("t_general_copyright")}
                </Typography>
              </Container>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
};

export default ForgotPassword;
