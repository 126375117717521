import intl from "react-intl-universal";
import { useNavigate } from "react-router-dom";

import { alpha, Box, Stack, Typography } from "@mui/material";

import Button from "@components/Button";

import { ROUTES } from "@utils/config";
import theme from "@utils/theme";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Stack
        height="100vh"
        width="100%"
        overflow="hidden"
        alignItems="center"
        justifyContent="space-evenly"
        position="absolute">
        <Box
          fontSize="20rem"
          fontWeight={900}
          color={alpha(theme.palette.primary.main, 0.03)}>
          40404040404040404
        </Box>
        <Box
          fontSize="20rem"
          fontWeight={900}
          color={alpha(theme.palette.primary.main, 0.03)}>
          40404040404040404
        </Box>
        <Box
          display={{ xs: "none", sm: "block" }}
          fontSize="20rem"
          fontWeight={900}
          color={alpha(theme.palette.primary.main, 0.03)}>
          40404040404040404
        </Box>
        <Box
          display={{ xs: "none", xl: "block" }}
          fontSize="20rem"
          fontWeight={900}
          color={alpha(theme.palette.primary.main, 0.03)}>
          40404040404040404
        </Box>
      </Stack>
      <Stack
        height="100vh"
        justifyContent="center"
        alignItems="center"
        position="relative">
        <Stack direction="row" mb="1.5rem">
          <Box
            fontSize="8rem"
            fontWeight={900}
            color={theme.palette.primary.main}>
            4
          </Box>
          <Box fontSize="8rem" fontWeight={900}>
            0
          </Box>
          <Box
            fontSize="8rem"
            fontWeight={900}
            color={theme.palette.primary.main}>
            4
          </Box>
        </Stack>
        <Box fontSize="2.25rem" mb={3}>
          {intl.get("t_not_found_page_title")}
        </Box>
        <Typography
          variant="subtitle1"
          m="0rem 1rem 5rem 1rem"
          textAlign="center">
          {intl.get("t_not_found_page_subtitle")}
        </Typography>
        <Button size="large" onClick={() => navigate(ROUTES.root)}>
          {intl.get("t_not_found_page_back_button")}
        </Button>
      </Stack>
    </>
  );
};

export default NotFound;
