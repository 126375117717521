import { Context, createContext, ReactNode, useEffect, useState } from "react";

import { doc, FirestoreError, setDoc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { FIRESTORE_COLLECTIONS } from "@utils/config";
import { db } from "@utils/firebase";

import useUserProfile from "@hooks/database/useUserProfile";
import ContextCompanyProfile from "@interfaces/components/ContextCompanyProfile";
import CompanyProfile from "@interfaces/database/CompanyProfile";

interface CompanyDetailsContextProps {
  children?: ReactNode;
}

export const CompanyDetailsDBContext: Context<{
  value?: ContextCompanyProfile;
  setValue?: (
    companyValue: ContextCompanyProfile,
    handleSuccess: () => void,
    handleFail: () => void
  ) => void;
  loading?: boolean;
  error?: FirestoreError;
}> = createContext({});

const CompanyDetailsContext = ({ children }: CompanyDetailsContextProps) => {
  const [companyDetails, setCompanyDetails] = useState<object>({});
  const userProfile = useUserProfile();
  const documentReference = doc(
    db,
    `${FIRESTORE_COLLECTIONS.COMPANIES}/${userProfile.value?.company_ids?.[0]}`
  );
  const [companyData, companyDataLoading, companyDataError] =
    useDocumentData(documentReference);

  const setCompanyValue = (
    companyValue: ContextCompanyProfile,
    handleSuccess: () => void = () => undefined,
    handleFail: () => void = () => undefined
  ) => {
    const data = companyValue as CompanyProfile;
    setDoc(documentReference, data)
      .then(() => handleSuccess())
      .catch(() => handleFail());
  };

  useEffect(() => {
    setCompanyDetails({
      value: companyData,
      loading: companyDataLoading,
      error: companyDataError,
      setValue: setCompanyValue
    });
  }, [companyData, companyDataLoading, companyDataError]);

  return (
    <CompanyDetailsDBContext.Provider value={companyDetails}>
      {children}
    </CompanyDetailsDBContext.Provider>
  );
};

export default CompanyDetailsContext;
