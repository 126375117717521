import { useEffect, useState } from "react";

import intl from "react-intl-universal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Stack, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";

import useToast from "@hooks/useToast";

import { TEST_SECTION_TYPE } from "@utils/config";
import { find, findIndex, isEmpty, toPairs } from "@utils/lodash";

import useTestDetails from "@hooks/database/useTestDetailsContext";
import CandidateSubmission from "@interfaces/database/CandidateSubmission";
import RadioQuestion from "@interfaces/database/Question/RadioQuestion";
import QuestionAnswerResponse from "@interfaces/database/QuestionAnswerResponse";
import AttentionToDetailForm from "@pages/Candidates/Test/AttentionToDetailForm";

interface AttentionToDetailProps {
  questions: Array<RadioQuestion>;
}

const AttentionToDetail = ({ questions }: AttentionToDetailProps) => {
  const [queryParams, setQueryParams] = useSearchParams();
  const token = queryParams.get("token");
  const navigate = useNavigate();
  const currentQuestion = queryParams.get("current_question") ?? 0;
  const [completedQuestionsCount, setCompletedQuestionsCount] =
    useState<number>(
      currentQuestion && Number(currentQuestion) > 1
        ? Number(currentQuestion)
        : 1
    );
  const { setSubmissionData, submission } = useTestDetails();
  const { testId, submissionId } = useParams();
  const toast = useToast();
  const [initialValue, setInitialValue] = useState({});

  useEffect(() => {
    setQueryParams((params) => {
      params.set("current_question", completedQuestionsCount?.toString());
      return params;
    });
  }, []);

  useEffect(() => {
    const attentionToDetailSectionData = find(submission?.sections, {
      type: TEST_SECTION_TYPE.ATTENTION_TO_DETAIL
    });
    if (attentionToDetailSectionData?.question_answer_responses) {
      const questionAnswerResponses =
        attentionToDetailSectionData?.question_answer_responses.map(
          (questionAnswerResponse: QuestionAnswerResponse) => {
            return {
              question_id: questionAnswerResponse.question_id,
              user_entered_answer: questionAnswerResponse.user_entered_answer
            };
          }
        );
      const findCurrentQuestionData = find(questionAnswerResponses, {
        question_id: questions?.[completedQuestionsCount - 1]?.id
      });

      if (findCurrentQuestionData) {
        const { question_id = "", user_entered_answer = "" } =
          findCurrentQuestionData || {};
        setInitialValue({
          [question_id?.toString()]: user_entered_answer
        });
      } else {
        setInitialValue({
          [questions?.[completedQuestionsCount - 1]?.id?.toString() ?? ""]: null
        });
      }
    } else {
      setInitialValue({
        [questions?.[completedQuestionsCount - 1]?.id?.toString() ?? ""]: null
      });
    }
  }, [submission, completedQuestionsCount, questions, currentQuestion]);

  const handleSubmissionUpdateSuccess = (submissionId: string) => {
    if (completedQuestionsCount === questions?.length) {
      navigate(
        `/candidates/${testId}/${submissionId}/tests/sections/5?token=${token}`
      );
      return;
    }
    setCompletedQuestionsCount(completedQuestionsCount + 1);
    setQueryParams((params) => {
      params.set("current_question", (completedQuestionsCount + 1)?.toString());
      return params;
    });
  };

  const handleSubmissionUpdateFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = (formData: Record<string, string>) => {
    const [questionId, userEnteredAnswer] = toPairs(formData)[0];

    const attentionToDetailSectionData = find(submission?.sections, {
      type: TEST_SECTION_TYPE.ATTENTION_TO_DETAIL
    });
    const attentionToDetailSectionIdx = findIndex(submission?.sections, {
      type: TEST_SECTION_TYPE.ATTENTION_TO_DETAIL
    });

    if (!attentionToDetailSectionData) {
      submission?.sections?.push({
        question_answer_responses: [
          {
            question_id: questionId,
            user_entered_answer: userEnteredAnswer
          }
        ],
        type: TEST_SECTION_TYPE.ATTENTION_TO_DETAIL
      });
    } else {
      const questionAnswerResponses = [
        ...attentionToDetailSectionData.question_answer_responses
      ];
      const alreadyExistResponse = find(questionAnswerResponses, {
        question_id: questionId
      });
      const alreadyExistResponseIdx = findIndex(questionAnswerResponses, {
        question_id: questionId
      });
      if (alreadyExistResponse && alreadyExistResponseIdx !== -1) {
        alreadyExistResponse.user_entered_answer = userEnteredAnswer;
        questionAnswerResponses[alreadyExistResponseIdx];
      } else {
        submission?.sections?.[
          attentionToDetailSectionIdx
        ]?.question_answer_responses.push({
          question_id: questionId,
          user_entered_answer: userEnteredAnswer
        });
      }
    }
    try {
      const submissionData: CandidateSubmission = {
        ...submission,
        current_active_step: "5",
        created_at: Timestamp.now(),
        updated_at: Timestamp.now()
      };

      if (setSubmissionData && testId && submissionId) {
        setSubmissionData(
          testId,
          submissionId,
          submissionData,
          handleSubmissionUpdateSuccess,
          handleSubmissionUpdateFail
        );
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Attention to detail</Typography>
        <Typography variant="subtitle4" mb={3}>
          Question {completedQuestionsCount} of {questions?.length}
        </Typography>
      </Stack>
      <Typography variant="subtitle4" display="block">
        Compare each text below to see if the text matches or not
      </Typography>
      {questions?.length > 0 && completedQuestionsCount <= questions.length ? (
        <>
          <AttentionToDetailForm
            initialValue={
              isEmpty(initialValue)
                ? { [questions?.[completedQuestionsCount - 1]?.id ?? ""]: null }
                : initialValue
            }
            name={questions?.[completedQuestionsCount - 1]?.id ?? ""}
            options={questions?.[completedQuestionsCount - 1]?.options?.map(
              (option) => {
                return {
                  label: option?.label?.en ?? "",
                  value: option?.key
                };
              }
            )}
            labelAsHtml
            label={questions?.[completedQuestionsCount - 1]?.question?.en ?? ""}
            // label="<p style='margin-bottom:10px'> Do the following match ? </p> &bull; on821I84 &nbsp;&nbsp;&nbsp;  &bull; on82I184"
            handleFormSubmit={handleFormSubmit}
            totalQuestions={questions.length}
            completedQuestionsCount={completedQuestionsCount}
          />
        </>
      ) : (
        false
      )}
    </>
  );
};

export default AttentionToDetail;
