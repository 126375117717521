import { Box } from "@mui/material";

import Button from "@components/Button";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const ButtonPage = () => {
  return (
    <>
      <Heading text="Buttons" />
      <Box sx={{ m: 2 }}>
        <Button size="small">Small</Button>
        <Button>Medium</Button>
        <Button size="large">Large</Button>
      </Box>
      <Box sx={{ m: 2 }}>
        <Button size="small" color="secondary">
          Small
        </Button>
        <Button color="secondary">Medium</Button>
        <Button size="large" color="secondary">
          Large
        </Button>
      </Box>
      <Box sx={{ m: 2 }}>
        <Button size="small" variant="outlined">
          Small
        </Button>
        <Button variant="outlined">Medium</Button>
        <Button size="large" variant="outlined">
          Large
        </Button>
      </Box>
      <Box sx={{ m: 2 }}>
        <Button size="small" variant="outlined" color="secondary">
          Small
        </Button>
        <Button variant="outlined" color="secondary">
          Medium
        </Button>
        <Button size="large" variant="outlined" color="secondary">
          Large
        </Button>
      </Box>

      <Box sx={{ m: 2 }}>
        <Button size="small" disabled>
          Small
        </Button>
        <Button disabled>Medium</Button>
        <Button size="large" disabled>
          Large
        </Button>
      </Box>
      <Box sx={{ m: 2 }}>
        <Button size="small" color="secondary" disabled>
          Small
        </Button>
        <Button color="secondary" disabled>
          Medium
        </Button>
        <Button size="large" color="secondary" disabled>
          Large
        </Button>
      </Box>
    </>
  );
};

export default ButtonPage;
