import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogContent, Stack } from "@mui/material";
import * as yup from "yup";

import Button from "@components/Button";
import Dialog from "@components/Dialog";
import TextField from "@components/TextField";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const ModalPage = () => {
  // validation schema
  const schema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required")
  });

  const methods = useForm({
    defaultValues: { name: "", email: "" },
    resolver: yupResolver(schema),
    mode: "onChange"
  });

  const { handleSubmit, control } = methods;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => alert(JSON.stringify(data));
  return (
    <>
      <Heading text="Modal" />
      <Dialog
        initiator={
          <Button variant="contained" color="primary" size="large">
            Add Candidate Modal
          </Button>
        }
        title="Add Candidate">
        {() => (
          <DialogContent>
            <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                control={control}
                name="name"
                label="Candidate's Name"
                placeholder="Enter name"
                required
              />
              <TextField
                control={control}
                name="email"
                label="Candidate's Email"
                placeholder="Enter email address"
                required
              />
              <Stack alignItems="flex-end" mt={1} width="100%">
                <Box width={{ md: "100%", lg: "max-content" }}>
                  <Button type="submit" size="large" fullWidth>
                    Send Invitation
                  </Button>
                </Box>
              </Stack>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default ModalPage;
