import { Stack, styled } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

interface QuestionStepperProps {
  totalQuestions: number;
  completedQuestionsCount: number;
}

const StyledLinearProgress = styled(LinearProgress)(() => ({
  width: "100%",
  height: "0.375rem"
}));

const QuestionStepper = ({
  totalQuestions,
  completedQuestionsCount
}: QuestionStepperProps) => {
  return (
    <Stack gap={2} flexDirection="row">
      {[...Array(totalQuestions)].map((_, index) => (
        <StyledLinearProgress
          key={index}
          variant="determinate"
          value={index < completedQuestionsCount ? 100 : 0}
        />
      ))}
    </Stack>
  );
};

export default QuestionStepper;
