import { ReactNode } from "react";

import { Stack } from "@mui/material";

import theme from "@utils/theme";

const SettingPagesWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      minHeight={{
        xs: "calc(100vh - 28.75rem)",
        sm: "calc(100vh - 30rem)",
        lg: "calc(100vh - 31.5rem)"
      }}
      px={{ xs: 2, md: 10, lg: 20 }}
      py={8}
      my={4}
      bgcolor={theme.palette.background.default}
      border={`1px solid ${theme.palette.text.secondary}`}
      borderRadius="10px">
      {children}
    </Stack>
  );
};

export default SettingPagesWrapper;
