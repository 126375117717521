/* eslint-disable @typescript-eslint/no-unused-vars */
import { createBrowserRouter, Navigate } from "react-router-dom";

import AdminData from "@pages/AdminData";
import CandidateList from "@pages/CandidateList";
import CandidatesApp from "@pages/CandidatesApp";
import CompanyInformation from "@pages/CompanyInformation";
import CompanyType from "@pages/CompanyType";
import CompleteProfile from "@pages/CompleteProfile";
import CustomizeCompanyType from "@pages/CustomizeCompanyType";
import ForgotPassword from "@pages/ForgotPassword";
import Home from "@pages/Home";
import Login from "@pages/Login";
import NotFound from "@pages/NotFound";
import Register from "@pages/Register";
import ResetPassword from "@pages/ResetPassword";
import Settings from "@pages/Settings";

// import AuthRedirectRoute from "@components/AuthRedirectRoute";

import { USER_TYPE } from "@utils/config";

import AboutYourSelfPage from "@pages/Candidates/AboutYourSelf";
import TestQuestionsPage from "@pages/Candidates/TestQuestions";
import TestSections from "@pages/Candidates/TestSections";
import TestWelcomePage from "@pages/Candidates/TestWelcome";
import ComponentPreview from "@pages/dashboard/ComponentPreview";
import ReportPage from "@pages/dashboard/ComponentPreview/ReportPage";
import RequireAuth from "@utils/components/RequireAuth";

import App from "@src/App";

export default createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Navigate to="not-found" replace />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: "components",
        element: <ComponentPreview />
      },
      {
        // FIXME: add private page configuration with some secure key
        path: "report/:testId/:submissionId",
        element: <ReportPage />
      },
      {
        path: "auth/register",
        element: (
          // <AuthRedirectRoute check="authenticatedCheck">
          <Register />
          // </AuthRedirectRoute>
        )
      },
      {
        path: "auth/complete-profile",
        element: (
          <RequireAuth accessRestriction={USER_TYPE.COMPANY}>
            {/* <AuthRedirectRoute check="onlyCompleteProfileCheck"> */}
            <CompleteProfile />
            {/* </AuthRedirectRoute> */}
          </RequireAuth>
        )
      },
      {
        path: "auth/login",
        element: (
          // <AuthRedirectRoute check="authenticatedCheck">
          <Login />
          // </AuthRedirectRoute>
        )
      },
      {
        path: "auth/reset-password/:is_email_sent?",
        element: <ForgotPassword />
      },
      {
        path: "forgot-password/:is_reset?",
        element: <ResetPassword />
      },

      {
        path: "candidate-list",
        element: (
          <RequireAuth accessRestriction={USER_TYPE.COMPANY}>
            {/* <AuthRedirectRoute check="completeProfileCheck"> */}
            <CandidateList />
            {/* </AuthRedirectRoute> */}
          </RequireAuth>
        )
      },
      {
        path: "settings",
        element: <RequireAuth accessRestriction={USER_TYPE.COMPANY} />,
        children: [
          {
            path: "",
            element: (
              // <AuthRedirectRoute check="completeProfileCheck">
              <Settings />
              // </AuthRedirectRoute>
            ),
            children: [
              {
                path: "",
                element: <Navigate to="company-information" replace />
              },
              {
                path: "company-information",
                element: <CompanyInformation />
              },
              {
                path: "company-type",
                element: <CompanyType />
              },
              {
                path: "customize-company-type",
                element: <CustomizeCompanyType />
              }
              // FIXME: phase-2 route
              // {
              //   path: "admin-data",
              //   element: <AdminData />
              // }
            ]
          }
        ]
      },
      {
        path: "not-found",
        element: <NotFound />
      },
      {
        path: "candidates/:testId/:submissionId",
        element: <CandidatesApp />,
        children: [
          {
            path: "",
            element: <TestWelcomePage />
          },
          {
            path: "about-your-self",
            element: <AboutYourSelfPage />
          },
          {
            path: "tests",
            element: <TestQuestionsPage />,
            children: [
              {
                path: "sections/:active_section?",
                element: <TestSections />
              }
            ]
          }
        ]
      }
    ]
  }
]);
