import intl from "react-intl-universal";

import * as yup from "yup";

/**
 * Generate form initial values dynamically
 * @param {Array} data = array of questions
 * @returns {Object}
 */

export const getInitialValues = (data: Array<object>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialValues: any = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.forEach((field: any) => {
    if (field?.id) {
      initialValues[field.id] = "";
    }
  });
  return initialValues;
};

/**
 * Generate yup schema dynamically
 * @param {Array} data = array of questions
 * @returns {Object}
 */

export const getValidationSchema = (data: Array<object>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schemaObject: any = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.forEach((field: any) => {
    schemaObject[field.id] = yup
      .string()
      .trim()
      .required(intl.get("t_error_required"));
  });
  return yup.object().shape(schemaObject);
};
