import intl from "react-intl-universal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";

import Button from "@components/Button";

import theme from "@utils/theme";

import CandidatePagesWrapper from "@pages/Candidates/CandidatePagesWrapper";

const TestWelcome = () => {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("token");

  const { testId, submissionId } = useParams();

  return (
    <CandidatePagesWrapper>
      <Stack
        justifyContent="center"
        alignItems="center"
        bgcolor={theme.palette.background.default}
        borderRadius="10px"
        px={1.25}
        py={{ xs: 5, lg: 18.25 }}>
        <Typography variant="h3" mb={2.5}>
          {intl.get("t_test_welcome_page_heading")}
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          mb={3}
          color={theme.palette.text.secondary}>
          {intl.get("t_test_welcome_page_are_you_ready_text")} <br />
          {intl.get("t_test_welcome_page_save_answer_text")} <br />
          {intl.get("t_test_welcome_page_not_retake_text")}
        </Typography>
        <Box width={{ xs: "100%", md: "8.75rem" }}>
          <Button
            type="submit"
            size="large"
            fullWidth
            onClick={() =>
              navigate(
                `/candidates/${testId}/${submissionId}/about-your-self?token=${token}`
              )
            }>
            {intl.get("t_general_next")}
          </Button>
        </Box>
      </Stack>
    </CandidatePagesWrapper>
  );
};

export default TestWelcome;
