import { ReactNode } from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import {
  Backdrop,
  DialogContent,
  DialogProps,
  IconButton,
  Dialog as MuiDialog,
  Stack,
  styled,
  Typography
} from "@mui/material";

interface ModalProps extends DialogProps {
  title?: string;
  showCloseBtn?: boolean;
  isBackdropBlur?: boolean;
  children: ReactNode;
  onClose?: () => void;
  open: boolean;
}

const StyledDialogHeader = styled(Stack)({
  maxWidth: "100%",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem 0rem"
});

const StyledDialogTitle = styled(Typography)({
  wordBreak: "break-all",
  textAlign: "center"
});

const StyledBlurredBackdrop = styled(Backdrop)({
  backdropFilter: "blur(4px)"
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "auto",
  position: "absolute",
  right: 0,
  top: 0,
  color: theme.palette.text.primary
}));

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.text.secondary}`,
      padding: "0.625rem",
      [theme.breakpoints.between("sm", "lg")]: {
        padding: "1.25rem"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "2.5rem"
      }
    }
  }
}));

const Modal = ({
  title,
  showCloseBtn = true,
  isBackdropBlur = false,
  children,
  open = false,
  onClose = () => {
    /* no op */
  },
  ...rest
}: ModalProps) => {
  return (
    <>
      <StyledDialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        slots={{ backdrop: isBackdropBlur ? StyledBlurredBackdrop : Backdrop }}
        disableScrollLock
        sx={{ zIndex: 1000 }} //To show below Drawer
        {...rest}>
        <StyledDialogHeader direction="row">
          {title ? (
            <StyledDialogTitle variant="h4" color="primary">
              {title}
            </StyledDialogTitle>
          ) : (
            false
          )}
          {showCloseBtn ? (
            <StyledIconButton onClick={onClose} size="large">
              <CloseIcon />
            </StyledIconButton>
          ) : (
            false
          )}
        </StyledDialogHeader>
        <DialogContent>{children}</DialogContent>
      </StyledDialog>
    </>
  );
};

export default Modal;
