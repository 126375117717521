import { Context, createContext, ReactNode, useEffect, useState } from "react";

import { doc, getDoc, setDoc } from "firebase/firestore";

import { FIRESTORE_COLLECTIONS } from "@utils/config";
import { db } from "@utils/firebase";

import CandidateSubmission from "@interfaces/database/CandidateSubmission";
import Test from "@interfaces/database/Test";

interface TestDetailsContextProps {
  children?: ReactNode;
}

export const TestDetailsDBContext: Context<{
  getTestData?: ({ testId }: { testId: string }) => Promise<object | undefined>;
  isTestDataLoading?: boolean;
  testDataError?: unknown;
  isSubmissionDataLoading?: boolean;
  submissionDataError?: unknown;
  setTestData?: (
    testId: string,
    testValue: Test,
    handleSuccess?: () => void,
    handleFail?: () => void
  ) => void;
  getSubmissionData?: ({
    testId,
    submissionId
  }: {
    testId: string;
    submissionId: string;
  }) => Promise<object | undefined>;
  setSubmissionData?: (
    testId: string,
    submissionId: string,
    submissionValue: CandidateSubmission,
    handleSuccess?: (submissionId: string) => void,
    handleFail?: () => void
  ) => void;
  test?: Test;
  submission?: CandidateSubmission;
}> = createContext({});

const TestDetailsContext = ({ children }: TestDetailsContextProps) => {
  const [testDetails, setTestDetails] = useState<object>({});
  const [isTestDataLoading, setIsTestDataLoading] = useState<boolean>(false);
  const [testDataError, setTestDataError] = useState<unknown>();
  const [submissionDataError, setSubmissionDataError] = useState<unknown>();
  const [submission, setSubmission] = useState<unknown>();
  const [isSubmissionDataLoading, setIsSubmissionDataLoading] =
    useState<boolean>(false);
  const [test, setTest] = useState<object>();

  // Function to fetch data based on an ID and update the context
  const getTestData = async ({ testId }: { testId: string }) => {
    try {
      setIsTestDataLoading(true);
      const testCollectionRef = doc(
        db,
        `${FIRESTORE_COLLECTIONS.TESTS}/${testId}`
      );
      const test = await getDoc(testCollectionRef);
      if (test.exists()) {
        setTest(test.data());
      }
    } catch (error) {
      setTestDataError(error);
    } finally {
      setIsTestDataLoading(false);
    }
  };

  // Function to fetch data based on an ID and update the context
  const getSubmissionData = async ({
    testId,
    submissionId
  }: {
    testId: string;
    submissionId: string;
  }) => {
    try {
      setIsSubmissionDataLoading(true);
      const submissionSubCollectionRef = doc(
        db,
        `${FIRESTORE_COLLECTIONS.TESTS}/${testId}/${FIRESTORE_COLLECTIONS.SUBMISSIONS}`,
        submissionId
      );
      const submission = await getDoc(submissionSubCollectionRef);

      let submissionData = submission.data();
      if (submission.exists()) {
        if (!submissionData?.sections) {
          // by default set section array as empty
          submissionData = { ...submissionData, sections: [] };
        }
        setSubmission(submissionData);
      }
      setIsSubmissionDataLoading(false);
    } catch (error) {
      setSubmissionDataError(error);
    }
  };

  const setTestData = (
    testId: string,
    testValue: Test,
    handleSuccess: () => void = () => undefined,
    handleFail: () => void = () => undefined
  ) => {
    try {
      setIsTestDataLoading(true);
      const testCollectionRef = doc(
        db,
        `${FIRESTORE_COLLECTIONS.TESTS}/${testId}`
      );
      const data = testValue as Test;
      setDoc(testCollectionRef, data)
        .then(() => handleSuccess())
        .catch(() => handleFail());
      setIsTestDataLoading(false);
    } catch (error) {
      setTestDataError(error);
    }
  };

  const setSubmissionData = (
    testId: string,
    submissionId: string,
    submissionValue: CandidateSubmission,
    handleSuccess: (submissionId: string) => void = () => undefined,
    handleFail: () => void = () => undefined
  ) => {
    try {
      setIsSubmissionDataLoading(true);
      const submissionCollectionRef = doc(
        db,
        FIRESTORE_COLLECTIONS.TESTS,
        testId,
        FIRESTORE_COLLECTIONS.SUBMISSIONS,
        submissionId
      );
      setDoc(submissionCollectionRef, submissionValue)
        .then(() => {
          handleSuccess(submissionId);
          setSubmission(submissionValue);
          setIsSubmissionDataLoading(false);
        })
        .catch(() => {
          handleFail();
          setIsSubmissionDataLoading(false);
        });
    } catch (error) {
      setIsSubmissionDataLoading(false);
      setSubmissionDataError(error);
    }
  };

  useEffect(() => {
    setTestDetails({
      getTestData,
      isTestDataLoading,
      testDataError,
      isSubmissionDataLoading,
      submissionDataError,
      setTestData,
      getSubmissionData,
      setSubmissionData,
      test,
      submission
    });
  }, [
    isTestDataLoading,
    testDataError,
    test,
    isSubmissionDataLoading,
    submissionDataError,
    submission
  ]);
  // }, [testData, testDataLoading, testDataError]);

  return (
    <TestDetailsDBContext.Provider value={testDetails}>
      {children}
    </TestDetailsDBContext.Provider>
  );
};

export default TestDetailsContext;
