import intl from "react-intl-universal";
import { darkStyles, JsonView } from "react-json-view-lite";

import { Box, Typography } from "@mui/material";

import Loader from "@components/Loader";

import { colorPalette } from "@utils/theme";

import useUserProfile from "@hooks/database/useUserProfile";

import "react-json-view-lite/dist/index.css";

const ProfileData = () => {
  const data = useUserProfile();

  return (
    <>
      <Typography variant="h5">
        {intl.get("t_debugger_profile_information")}:
      </Typography>
      <br />
      {data.loading ? (
        <Loader />
      ) : (
        <Box p={2} bgcolor={colorPalette.blue["20"]}>
          <JsonView
            data={data.value ?? {}}
            shouldInitiallyExpand={() => true}
            style={darkStyles}
          />
        </Box>
      )}
    </>
  );
};

export default ProfileData;
