import { Outlet, useParams } from "react-router-dom";

import { Box } from "@mui/material";

import Stepper from "@components/Stepper";

import CandidatePagesWrapper from "@pages/Candidates/CandidatePagesWrapper";

const TestQuestions = () => {
  // TODO: fetch test questions and pass into outlet

  const { active_section: activeSection = 0 } = useParams();

  const steps = [
    { title: "Cultural match" },
    { title: "Social Styles" },
    { title: "Subjective Thinking" },
    { title: "Emotional Intelligence" },
    { title: "Attention to detail" },
    { title: "Priority in Work" }
  ];

  const isNotSuccessPage = Number(activeSection) < 6;

  return (
    <CandidatePagesWrapper>
      {isNotSuccessPage ? (
        <Stepper currentStep={Number(activeSection)} steps={steps} />
      ) : (
        false
      )}
      {isNotSuccessPage ? (
        <Box
          bgcolor="background.default"
          borderRadius="10px"
          mt={{ xs: 5, md: 7.5 }}
          px={{ xs: 1.5, md: 10 }}
          py={{ xs: 5, md: 10 }}>
          <Outlet />
        </Box>
      ) : (
        <Box bgcolor="background.default" borderRadius="10px">
          <Outlet />
        </Box>
      )}
    </CandidatePagesWrapper>
  );
};

export default TestQuestions;
