import intl from "react-intl-universal";

import { Box, Container, Grid, Stack, styled, Typography } from "@mui/material";

import AccessmentCategoriesCard from "@components/AccessmentCategoriesCard";
import Button from "@components/Button";
import Chart from "@components/Chart";
import Cultural from "@components/Cultural";
import ProgressBar from "@components/ProgressBar";
import SortableList from "@components/SortableList";

import { colorPalette } from "@utils/theme";

import BgHomepageBrainLight from "@assets/images/BgHomepageBrainLight.svg";
import BGPricingText from "@assets/images/BGPricingText.svg";
import BGWhyText from "@assets/images/BGWhyText.svg";
import LightBulb from "@assets/images/LightBulb.svg";
import PointingHand from "@assets/images/PointingHand.svg";

const StyledContainer = styled(Container)({
  marginTop: "1.5rem",
  marginBottom: "1.5rem",
  zIndex: "5"
});

const StyledHomepagePCBackgroundContainer = styled(Box)(({ theme }) => {
  return {
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${BgHomepageBrainLight})`,
      backgroundSize: "auto 100%",
      overflow: "visible",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: 780,
      position: "relative",
      top: -50,
      display: "flex",
      alignItems: "center"
    }
  };
});

const StyledLightBulbImg = styled("img")(({ theme }) => {
  return {
    width: "100%",
    height: 500,
    marginTop: 40,
    [theme.breakpoints.up("md")]: { marginTop: "unset" }
  };
});

const StyledBgWhyImg = styled("img")(({ theme }) => {
  return {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translate(0, -50%)",
      zIndex: "0"
    }
  };
});

const StyledPricingText = styled("img")(({ theme }) => {
  return {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translate(0,-50%)",
      zIndex: 0,
      width: "50%"
    }
  };
});

const Home = () => {
  const randomNumber = () => parseFloat((Math.random() * 4 + 1).toFixed(1));

  return (
    <>
      <section>
        <Box position="relative">
          <StyledHomepagePCBackgroundContainer>
            <StyledContainer>
              <Box position="relative" ml={{ md: "10%" }}>
                <Typography variant="h1" color="primary.main">
                  {intl.get("t_home_tokhimo")} <br />
                  {intl.get("t_home_aptitude_test")}
                </Typography>
                <Typography variant="subtitle1" my={2.5}>
                  {intl.get("t_home_subtitle")}
                </Typography>
                {/* FIXME: confirm where it will take another page */}
                <Box>
                  <Button size="large">{intl.get("t_home_know_more")}</Button>
                </Box>
              </Box>
            </StyledContainer>
            <Box
              display={{ xs: "block", md: "none" }}
              width="auto"
              height="100%"
              mt={{ sm: -12.5 }}
              ml={-50}>
              <Box
                component="img"
                src={BgHomepageBrainLight}
                alt={intl.get("t_alt_home_brain_light")}
                width="100%"
              />
            </Box>
          </StyledHomepagePCBackgroundContainer>
        </Box>
      </section>
      <section>
        <StyledContainer>
          <Typography
            variant="h2"
            color="primary.main"
            align="center"
            mt={12.5}>
            {intl.get("t_home_how_tokhimo_aptitude_work")}
          </Typography>
          <Grid container px={{ md: 12.5 }} mt={10}>
            <Grid
              item
              xs={12}
              md={3}
              px={2}
              pr={{ xs: "10%", sm: "40%", md: 0 }}>
              <Typography variant="h2" color="secondary.main">
                {intl.get("t_home_how_tokhimo_aptitude_work_one_title")}
              </Typography>
              <Typography variant="subtitle1">
                {intl.get("t_home_how_tokhimo_aptitude_work_one_subtitle")}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={3}
              px={2}
              pt={{ xs: 5, md: 9 }}
              pl={{ xs: "10%", sm: "40%", md: 0 }}
              flexDirection="column"
              alignContent="flex-end">
              <Typography variant="h2" color="secondary.main">
                {intl.get("t_home_how_tokhimo_aptitude_work_two_title")}
              </Typography>
              <Typography variant="subtitle1">
                {intl.get("t_home_how_tokhimo_aptitude_work_two_subtitle")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              px={2}
              pt={{ xs: 5, md: 0 }}
              pr={{ xs: "10%", sm: "40%", md: 0 }}>
              <Typography variant="h2" color="secondary.main">
                {intl.get("t_home_how_tokhimo_aptitude_work_three_title")}
              </Typography>
              <Typography variant="subtitle1">
                {intl.get("t_home_how_tokhimo_aptitude_work_three_subtitle")}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={3}
              px={2}
              pt={{ xs: 5, md: 9 }}
              pl={{ xs: "10%", sm: "40%", md: 0 }}
              flexDirection="column"
              alignContent="flex-end">
              <Typography variant="h2" color="secondary.main">
                {intl.get("t_home_how_tokhimo_aptitude_work_four_title")}
              </Typography>
              <Typography variant="subtitle1">
                {intl.get("t_home_how_tokhimo_aptitude_work_four_subtitle")}
              </Typography>
            </Grid>
          </Grid>
        </StyledContainer>
      </section>
      <section>
        <StyledContainer>
          <Grid
            container
            position="relative"
            my={12.5}
            justifyContent="flex-end">
            <StyledBgWhyImg src={BGWhyText} alt={intl.get("t_alt_home_why")} />
            <Grid container item md={6} px={6.25}>
              <Stack justifyContent="center">
                <Typography variant="h2" color="primary.main">
                  {intl.get("t_home_why_it_help_global_talent_hiring_title")}
                </Typography>
                <Typography variant="subtitle1" mt={2.5}>
                  {intl.get("t_home_why_it_help_global_talent_hiring_content")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={6}>
              <StyledLightBulbImg
                src={LightBulb}
                alt={intl.get("t_alt_home_light_bulb")}
              />
            </Grid>
          </Grid>
        </StyledContainer>
      </section>
      <section>
        <StyledContainer>
          <Typography variant="h2" color="primary.main" align="center">
            {intl.get("t_home_assessment_categories")}
          </Typography>
          <Grid container mt={12} justifyContent="center">
            <Grid item sm={6} md={4}>
              {/* FIXME: will update desc later */}
              <AccessmentCategoriesCard
                title={intl.get(
                  "t_home_assessment_categories_culture_fit_title"
                )}
                description={intl.get(
                  "t_home_assessment_categories_culture_fit_desc"
                )}
                image="peoplegroup"
              />
            </Grid>
            <Grid item sm={6} md={4}>
              {/* FIXME: will update desc later */}
              <AccessmentCategoriesCard
                title={intl.get(
                  "t_home_assessment_categories_social_style_title"
                )}
                description={intl.get(
                  "t_home_assessment_categories_social_style_desc"
                )}
                image="briefcase"
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <AccessmentCategoriesCard
                title={intl.get(
                  "t_home_assessment_categories_subjective_thinking_title"
                )}
                description={intl.get(
                  "t_home_assessment_categories_subjective_thinking_desc"
                )}
                image="thumbup"
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <AccessmentCategoriesCard
                title={intl.get(
                  "t_home_assessment_categories_emotional_intelligence_title"
                )}
                description={intl.get(
                  "t_home_assessment_categories_emotional_intelligence_desc"
                )}
                image="smilingface"
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <AccessmentCategoriesCard
                title={intl.get(
                  "t_home_assessment_categories_attention_to_details_title"
                )}
                description={intl.get(
                  "t_home_assessment_categories_attention_to_details_desc"
                )}
                image="twogear"
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <AccessmentCategoriesCard
                title={intl.get(
                  "t_home_assessment_categories_work_priority_title"
                )}
                description={intl.get(
                  "t_home_assessment_categories_work_priority_desc"
                )}
                image="checkcircleoutline"
              />
            </Grid>
          </Grid>
        </StyledContainer>
      </section>
      <section>
        <StyledContainer>
          <Typography
            variant="h2"
            color="primary.main"
            align="center"
            mt={12.5}>
            {intl.get("t_home_report")}
          </Typography>
          <Box
            bgcolor={colorPalette.black.background.base}
            mx={{ md: 12.5 }}
            mt={7.5}
            p={5}>
            <Box>
              <Stack
                flexDirection="row"
                gap={1}
                alignItems="center"
                alignSelf="flex-start">
                <Stack
                  height={56}
                  width={56}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={1.25}
                  bgcolor={colorPalette.lighterBlue[15]}>
                  <Typography variant="h3" color="secondary.main">
                    1
                  </Typography>
                </Stack>
                <Box>
                  <Typography variant="h4" color="secondary.main">
                    {intl.get("t_home_report_cultural_match_title")}
                  </Typography>
                  {/*  FIXME: will update later*/}
                  <Typography variant="body1">
                    {intl.get("t_home_report_cultural_match_subtitle")}
                  </Typography>
                </Box>
              </Stack>

              {/* FIXME: need to fix responsive later */}
              <Cultural
                domain={{ x: [1, 8], y: [1, 5] }}
                japaneseCultureDataPoints={[...Array(8)].map((_, index) => ({
                  x: index + 1,
                  y: randomNumber()
                }))}
                examineeDataPoints={[...Array(8)].map((_, index) => ({
                  x: index + 1,
                  y: randomNumber()
                }))}
              />
            </Box>
            <Grid container rowSpacing={1} columnSpacing={3} mt={5} mb={3.75}>
              <Grid item xs={12} md={7}>
                <Stack flexDirection="row" gap={1} alignItems="center" mb={4}>
                  <Stack
                    height={56}
                    width={56}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={1.25}
                    bgcolor={colorPalette.lighterBlue[15]}>
                    <Typography variant="h3" color="secondary.main">
                      2
                    </Typography>
                  </Stack>
                  <Box>
                    <Typography variant="h4" color="secondary.main">
                      {intl.get("t_home_report_social_style_title")}
                    </Typography>
                    <Typography variant="body1">
                      {intl.get("t_home_report_social_style_subtitle")}
                    </Typography>
                  </Box>
                </Stack>
                <Chart domain={[-10, 10]} x={-5.5} y={8} />
              </Grid>
              <Grid item xs={12} md={5} mt={{ xs: 5, md: "unset" }}>
                <Stack flexDirection="row" gap={1} alignItems="center" mb={4}>
                  <Stack
                    height={56}
                    width={56}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={1.25}
                    bgcolor={colorPalette.lighterBlue[15]}>
                    <Typography variant="h3" color="secondary.main">
                      3
                    </Typography>
                  </Stack>
                  <Box>
                    <Typography variant="h4" color="secondary.main">
                      {intl.get("t_home_report_work_priority_title")}
                    </Typography>
                    <Typography variant="body1">
                      {intl.get("t_home_report_work_priority_subtitle")}
                    </Typography>
                  </Box>
                </Stack>
                <SortableList
                  items={[
                    intl.get("t_home_sortable_list_salary"),
                    intl.get("t_home_sortable_list_work_life_balance"),
                    intl.get("t_home_sortable_list_work_location"),
                    intl.get("t_home_sortable_list_stability"),
                    intl.get("t_home_sortable_list_benefits"),
                    intl.get("t_home_sortable_list_self_growth"),
                    intl.get("t_home_sortable_list_relationship_with_boss"),
                    intl.get(
                      "t_home_sortable_list_relationship_with_colleagues"
                    ),
                    intl.get("t_home_sortable_list_company_growth"),
                    intl.get("t_home_sortable_list_higher_responsibility"),
                    intl.get("t_home_sortable_list_stability")
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container columnGap={3} mt={2}>
              <Grid item xs={12}>
                <Stack flexDirection="row" gap={1} alignItems="center" mb={4}>
                  <Stack
                    height={56}
                    width={56}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={1.25}
                    bgcolor={colorPalette.lighterBlue[15]}>
                    <Typography variant="h3" color="secondary.main">
                      4
                    </Typography>
                  </Stack>
                  <Box>
                    <Typography variant="h4" color="secondary.main">
                      {intl.get("t_home_report_progress_title")}
                    </Typography>
                    <Typography variant="body1">
                      {intl.get("t_home_report_progress_subtitle")}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md mt={4}>
                <ProgressBar
                  value={10}
                  severity="success"
                  label={intl.get("t_home_report_progress_subjective_thinking")}
                />
              </Grid>
              <Grid item xs={12} md mt={4}>
                <ProgressBar
                  value={20}
                  severity="error"
                  label={intl.get(
                    "t_home_report_progress_emotional_intelligence"
                  )}
                />
              </Grid>
              <Grid item xs={12} md mt={4}>
                <ProgressBar
                  value={30}
                  severity="warning"
                  label={intl.get("t_home_report_progress_attention_to_detail")}
                />
              </Grid>
            </Grid>
          </Box>
        </StyledContainer>
      </section>
      <section>
        <StyledContainer>
          <Grid
            container
            my={12.5}
            position="relative"
            flexDirection={{ xs: "column-reverse", md: "unset" }}>
            <StyledPricingText
              src={BGPricingText}
              alt={intl.get("t_alt_home_pricing")}
            />
            <Grid
              item
              md={6}
              mt={{ xs: 6.25, sm: "unset" }}
              ml={{ xs: -3, xl: "unset" }}>
              <img
                src={PointingHand}
                width="100%"
                alt={intl.get("t_alt_home_pointing_hand")}
              />
            </Grid>
            <Grid container item md={6} pl="10%">
              <Stack justifyContent="center">
                <Typography variant="h2" color="primary.main">
                  {intl.get("t_home_pricing_title")}
                </Typography>
                <Typography variant="subtitle1" my={2.5}>
                  {intl.get("t_home_pricing_content")}
                </Typography>
                <Box>
                  <Button size="large">
                    {intl.get("t_home_pricing_try_free")}
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </StyledContainer>
      </section>
    </>
  );
};

export default Home;
