import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import jaLocale from "i18n-iso-countries/langs/ja.json";

import { LOCALE } from "@utils/config";
import translate from "@utils/translate";

import KeyLabel from "@interfaces/components/KeyLabel";

countries.registerLocale(enLocale);
countries.registerLocale(jaLocale);

const getCountryName = (countryCode: string): string => {
  const locale = translate.getCurrentLocaleShort();
  return countries.getName(countryCode, locale, { select: "official" }) ?? "";
};

const getCountryCode = (countryName: string): string => {
  const locale = translate.getCurrentLocaleShort();
  return countries.getAlpha3Code(countryName, locale) ?? "";
};

const isValidCountryCode = (countryCode: string) => {
  return countries.isValid(countryCode);
};

const isValidCountryName = (countryName: string) => {
  const locale = translate.getCurrentLocaleShort();
  const countryAlpha3 = countries.getAlpha3Code(countryName, locale);
  return countryAlpha3 ? true : false;
};

// This function is specifically to get the list of countries for country selection dropdown.
const getDropdownCountryList = (): Array<KeyLabel> => {
  const allCountryAlpha3 = countries.getAlpha3Codes();
  const countryList = Object.keys(allCountryAlpha3).map((singleAlpha3) => {
    const enName = countries.getName(singleAlpha3, "en", {
      select: "official"
    });
    let localeName = "";
    if (translate.getCurrentLocale() !== LOCALE.EN) {
      switch (translate.getCurrentLocale()) {
        case LOCALE.JA:
          localeName =
            countries.getName(singleAlpha3, "ja", {
              select: "official"
            }) ?? "";
          break;
      }
    }
    return {
      key: singleAlpha3,
      label: enName + (localeName ? ` (${localeName})` : "")
    };
  });
  return countryList;
};

export default countries;
export {
  getCountryName,
  getCountryCode,
  getDropdownCountryList,
  isValidCountryCode,
  isValidCountryName
};
