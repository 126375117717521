import { ReactNode, useState } from "react";

import { Control, Controller, FieldError } from "react-hook-form";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  TextField as MuiTextField,
  Stack,
  styled
} from "@mui/material";

import DangerousComponentFromHTML from "@utils/components/DangerousComponentFromHTML";

interface TextFieldProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  type?: "text" | "password" | "number" | "email" | "hidden";
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  autoComplete?: boolean;
  helperText?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  maxLength?: number;
  readOnly?: boolean;
  defaultValue?: string;
  labelAsHtml?: boolean; // It will render form label as HTML
}

const HelperText = ({
  value,
  error,
  maxLength = 0,
  helperText
}: {
  value: string;
  error: FieldError | undefined;
  maxLength: number;
  helperText: string;
}) => {
  const leftCharacters = maxLength - value?.length;
  return (
    <Stack direction="row" justifyContent="space-between">
      {error ? <span>{error?.message}</span> : <span>{helperText}</span>}
      {maxLength > 0 && (
        <span>
          {leftCharacters >= 0 ? leftCharacters : 0}
          &nbsp;characters left
        </span>
      )}
    </Stack>
  );
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary
}));

const TextField = ({
  name,
  control,
  label,
  disabled = false,
  required = false,
  type = "text",
  placeholder = "",
  multiline = false,
  rows = 1,
  autoComplete = false,
  helperText = "",
  startAdornment = null,
  endAdornment = null,
  maxLength = 0,
  readOnly = false,
  defaultValue,
  labelAsHtml
}: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [inputType, setInputType] = useState<
    "text" | "password" | "number" | "email" | "hidden"
  >(type);

  const handleClickShowPassword = () => {
    if (inputType === "password") {
      setInputType("text");
      setShowPassword(!showPassword);
    } else {
      setInputType("password");
      setShowPassword(!showPassword);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            {label && (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}>
                {labelAsHtml ? (
                  <DangerousComponentFromHTML html={label} />
                ) : (
                  label
                )}
              </InputLabel>
            )}
            <MuiTextField
              id={name}
              helperText={
                <HelperText
                  value={value}
                  error={error}
                  maxLength={maxLength}
                  helperText={helperText}
                />
              }
              /**
               * Note: There is nothing we can do at the moment to not expect error.
               * This is an issue from MUI. GH tickets for reference:
               * https://github.com/mui/material-ui/issues/33339
               * https://github.com/mui/material-ui/issues/27703
               * https://github.com/mui/material-ui/issues/32392
               * https://github.com/mui/material-ui/issues/32872
               */
              FormHelperTextProps={{ component: "span" }}
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              multiline={multiline}
              rows={rows}
              margin="dense"
              variant="outlined"
              placeholder={placeholder}
              disabled={disabled}
              required={required}
              type={type === "password" ? inputType : type}
              InputProps={{
                readOnly,
                autoComplete: autoComplete ? "on" : "off",
                startAdornment: (
                  <>
                    {startAdornment && (
                      <InputAdornment position="start">
                        {startAdornment}
                      </InputAdornment>
                    )}
                  </>
                ),
                endAdornment: (
                  <>
                    {type === "password" && !endAdornment && (
                      <InputAdornment position="end">
                        <StyledIconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </StyledIconButton>
                      </InputAdornment>
                    )}
                    {endAdornment && (
                      <InputAdornment position="end">
                        {endAdornment}
                      </InputAdornment>
                    )}
                  </>
                )
              }}
            />
          </>
        );
      }}
    />
  );
};

export default TextField;
