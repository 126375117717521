import intl from "react-intl-universal";

import { Box, styled, Typography } from "@mui/material";

import { USER_TEST_STATUS, USER_TEST_STATUS_T_LABELS } from "@utils/config";
import theme from "@utils/theme";

interface StatusProps {
  label: typeof USER_TEST_STATUS[keyof typeof USER_TEST_STATUS];
}
interface StyledBoxProps {
  color: string;
  backgroundColor: string;
}

const StyledBox = styled(Box)<StyledBoxProps>(({ backgroundColor, color }) => ({
  padding: ".5rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: ".5rem",
  borderRadius: "0.625rem",
  width: "max-content",
  backgroundColor,
  color
}));

const Dot = styled(Box)<{ backgroundColor: string }>(({ backgroundColor }) => ({
  width: "0.75rem",
  height: "0.75rem",
  borderRadius: "50%",
  display: "inline-block",
  backgroundColor
}));

const Status = ({ label }: StatusProps) => {
  let color = "";
  let backgroundColor = "";

  switch (label) {
    case USER_TEST_STATUS.TAKEN:
      color = theme.palette.success.main;
      backgroundColor = theme.palette.success.light;
      break;

    case USER_TEST_STATUS.INVITED:
      color = theme.palette.secondary.main;
      backgroundColor = theme.palette.secondary.light;
      break;

    case USER_TEST_STATUS.INPROGRESS:
      color = theme.palette.warning.main;
      backgroundColor = theme.palette.warning.light;
      break;

    case USER_TEST_STATUS.EXPIRED:
      color = theme.palette.error.main;
      backgroundColor = theme.palette.error.light;
      break;
  }

  return (
    <StyledBox
      backgroundColor={backgroundColor}
      color={color}
      data-testid="status">
      <Dot backgroundColor={color} />
      <Box data-testid="status-label">
        <Typography variant="subtitle5">
          {intl.get(USER_TEST_STATUS_T_LABELS[label])}
        </Typography>
      </Box>
    </StyledBox>
  );
};

export default Status;
