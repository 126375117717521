import { Context, createContext, ReactNode, useEffect, useState } from "react";

import { doc, FirestoreError, getDoc, setDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { FIRESTORE_COLLECTIONS, USER_TYPE } from "@utils/config";
import { auth, db } from "@utils/firebase";

import ContextUserProfile from "@interfaces/components/ContextUserProfile";
import CompanyProfile from "@interfaces/database/CompanyProfile";
interface UserProfileContextProps {
  children?: ReactNode;
}

export interface UserProfileContextStructure {
  value?: ContextUserProfile;
  setValue?: (
    details: ContextUserProfile,
    handleSuccess?: () => void,
    handleFail?: () => void
  ) => void;
  loading?: boolean;
  error?: FirestoreError;
}

export const userProfileDBContext: Context<UserProfileContextStructure> =
  createContext({});

const UserProfileContext = ({ children }: UserProfileContextProps) => {
  const [profileData, setProfileData] = useState<object>({});
  const [user] = useAuthState(auth);
  const userDocumentReference = doc(
    db,
    `${FIRESTORE_COLLECTIONS.USERS}/${user?.uid}`
  );
  const [value, loading, error] = useDocumentData(userDocumentReference);

  const setDetails = (
    details: ContextUserProfile,
    handleSuccess: () => void = () => undefined,
    handleFail: () => void = () => undefined
  ) => {
    const data = details;

    if (value?.user_type === USER_TYPE.COMPANY) {
      // fetch user data from company collection and add to context
      // first index is main user
      const companyId = value?.company_ids?.[0];
      const getCompanyData = async () => {
        if (companyId) {
          const companyRef = doc(
            db,
            FIRESTORE_COLLECTIONS.COMPANIES,
            companyId
          );
          const companyDoc = await getDoc(companyRef);
          const companyData = companyDoc.data() as CompanyProfile;

          if (companyDoc.exists()) {
            setDoc(companyRef, {
              ...companyData,
              company_id: companyId,
              ...data
            })
              .then(() => handleSuccess())
              .catch(() => handleFail());
          }
        }
      };
      getCompanyData();
    }
  };

  useEffect(() => {
    setProfileData({
      loading: loading,
      error: error,
      value: value as ContextUserProfile
    });
    if (value?.user_type === USER_TYPE.COMPANY) {
      // fetch user data from company collection and add to context
      // first index is main user
      const companyId = value?.company_ids?.[0];
      const getCompanyData = async () => {
        if (companyId) {
          const companyRef = doc(
            db,
            FIRESTORE_COLLECTIONS.COMPANIES,
            companyId
          );
          const companyDoc = await getDoc(companyRef);
          if (companyDoc.exists()) {
            value.company_information = {
              ...companyDoc.data(),
              company_id: companyId
            };
            setProfileData({
              value: value as ContextUserProfile,
              loading: loading,
              error: error,
              setValue: setDetails
            });
          }
        }
      };
      getCompanyData();
    }
  }, [value, loading, error]);

  return (
    <userProfileDBContext.Provider value={profileData}>
      {children}
    </userProfileDBContext.Provider>
  );
};

export default UserProfileContext;
