import { ReactNode } from "react";

import { Tabs as MuiTabs } from "@mui/material";

interface TabsProps {
  children: ReactNode;
  value: string | number;
}

const Tabs = ({ children, value }: TabsProps) => {
  return (
    <MuiTabs
      value={value}
      aria-label="tabs"
      variant="scrollable"
      scrollButtons={false}
      allowScrollButtonsMobile>
      {children}
    </MuiTabs>
  );
};

export default Tabs;
