import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable
} from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

import { ENVIRONMENT, LOCALE } from "@utils/config";
import translate from "@utils/translate";

import firebaseConfig from "@utils/firebase/config";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

if (process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.LOCAL) {
  connectAuthEmulator(auth, "http://127.0.0.1:9099/", {
    disableWarnings: true
  });
  connectFirestoreEmulator(db, "127.0.0.1", 8089);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
}

const envOptions = {
  ...app.options,
  isEmulator: false,
  fireStoreEmulatorHost: "",
  storageProdHost: "",
  storageHost: ""
};

httpsCallable(functions, "ping")
  .call({})
  .then((response: { data: unknown }) => {
    if (typeof response?.data === typeof {}) {
      const response_data = response.data as {
        emulator_mode: string;
        firestore_emulator_host: string;
        storage_host: string;
      };
      if (response_data.emulator_mode === "true") {
        envOptions.isEmulator = true;
        envOptions.fireStoreEmulatorHost =
          response_data.firestore_emulator_host;
      }
      envOptions.storageHost = response_data.storage_host;
    }
  });

const setAuthLocale = () => {
  const currentLocale = translate.getCurrentLocale();
  switch (currentLocale) {
    case LOCALE.EN:
      auth.languageCode = "en";
      break;
    case LOCALE.JA:
      auth.languageCode = "ja";
      break;
    default:
      auth.languageCode = "en";
      break;
  }
};

export { auth, db, functions, storage, envOptions, setAuthLocale };
