/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";

import intl from "react-intl-universal";
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";

import { applyActionCode } from "firebase/auth";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import useForceUpdate from "use-force-update";

// import EmailNotVerifiedBanner from "@components/EmailNotVerifiedBanner";
import Loader from "@components/Loader";

import useToast from "@hooks/useToast";

import { ENVIRONMENT, LOCALE, ROUTES, USER_TYPE } from "@utils/config";
import { auth, functions } from "@utils/firebase";
import translate from "@utils/translate";

import useUserProfile from "@hooks/database/useUserProfile";
import UserTypeDetails from "@interfaces/functions/UserTypeDetails";

const HIDE_BANNER_PATHS = [
  ROUTES.register,
  ROUTES.completedProfile,
  ROUTES.login,
  ROUTES.forgotPassword,
  ROUTES.resetPassword,
  ROUTES.notFound,
  ROUTES.candidates
];

const UserStatusChecker = () => {
  const [user, isUserLoading] = useAuthState(auth);
  const [signOut] = useSignOut(auth);
  const [isShowEmailNotVerifiedBanner, setShowEmailNotVerifiedBanner] =
    useState<boolean>(false);
  const userProfile = useUserProfile();
  const toast = useToast();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [
    setNewUserTypeDetails,
    isSetNewUserTypeDetailsLoading,
    setNetUserTypeDetailsError
  ] = useHttpsCallable(functions, "setNewUserTypeDetails");
  const forceUpdate = useForceUpdate();

  const hideBannerPath = HIDE_BANNER_PATHS.some((singlePath) =>
    matchPath(singlePath, pathname)
  );

  const resendVerificationEmail = () => {
    searchParams.set("send_verification_email", "1");
    setSearchParams(searchParams.toString(), { replace: true });
  };

  /**
   * This function will redirect user to the next page.
   * If the next page is in the same domain, it will use react-router-dom to navigate to the next page.
   * In that case, forceUpdate() is called to force the component to re-render.
   */
  const navigateToNextPage = () => {
    const continueURL = searchParams.get("continueUrl") ?? "";
    if (continueURL) {
      searchParams.delete("continueURL");
      const parsedContinueURL = new URL(continueURL);
      if (parsedContinueURL.hostname === window.location.hostname) {
        navigate(parsedContinueURL.pathname + parsedContinueURL.search);
        forceUpdate();
      } else {
        window.location.href = continueURL;
      }
    } else {
      setSearchParams(searchParams.toString(), { replace: true });
      forceUpdate();
    }
  };

  useEffect(() => {
    const lang = searchParams.get("lang") ?? "";
    if (lang) {
      const lang = searchParams.get("lang") ?? "";
      let newLocale: typeof LOCALE[keyof typeof LOCALE] = LOCALE.EN;
      switch (lang) {
        case "en":
          newLocale = LOCALE.EN;
          break;
        case "ja":
          newLocale = LOCALE.JA;
          break;
        default:
          newLocale = LOCALE.EN;
          break;
      }
      const currentLocale = translate.getCurrentLocale();
      if (currentLocale !== newLocale) {
        translate.setCurrentLocale(newLocale);
      }
      searchParams.delete("lang");
      setSearchParams(searchParams.toString(), { replace: true });
    }
    const mode = searchParams.get("mode") ?? "";
    const verificationCode = searchParams.get("oobCode") ?? "";
    const apiKey = searchParams.get("apiKey") ?? "";
    if (mode === "verifyEmail") {
      if (verificationCode) {
        if (
          process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.LOCAL &&
          apiKey !== process.env.REACT_APP_FIREABSE_API_KEY
        ) {
          toast.kampai(intl.get("t_forgot_password_expired_link"), "error");
          navigate("/");
        }
        (async () => {
          try {
            await applyActionCode(auth, verificationCode);
            toast.kampai(intl.get("t_verify_email_success"), "success");
            setShowEmailNotVerifiedBanner(false);
            searchParams.delete("mode");
            searchParams.delete("oobCode");
            searchParams.delete("apiKey");
            navigateToNextPage();
          } catch (e) {
            toast.kampai(intl.get("t_forgot_password_expired_link"), "error");
            navigate("/");
          }
        })();
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (!user.emailVerified && !hideBannerPath) {
        setShowEmailNotVerifiedBanner(true);
      }
      if (userProfile.value?.user_type === USER_TYPE.NO_TYPE) {
        const userTypeDetails: UserTypeDetails = {
          user_type: USER_TYPE.COMPANY
        };
        if (searchParams.get("is_dummy") === "1") {
          userTypeDetails.is_dummy = true;
          searchParams.delete("is_dummy");
          setSearchParams(searchParams.toString(), { replace: true });
        }
        (async () => {
          try {
            await setNewUserTypeDetails(userTypeDetails);
          } catch (e) {
            toast.kampai(
              intl.get("t_general_internal_error_login_again"),
              "error"
            );
            signOut();
          }
        })();
      }
    } else {
      setShowEmailNotVerifiedBanner(false);
    }
  }, [user, userProfile]);

  useEffect(() => {
    if (setNetUserTypeDetailsError) {
      toast.kampai(intl.get("t_general_internal_error_login_again"), "error");
      signOut();
    }
  }, [setNetUserTypeDetailsError]);

  return (
    <>
      {isUserLoading || isSetNewUserTypeDetailsLoading ? <Loader /> : false}
      {isShowEmailNotVerifiedBanner
        ? ""
        : // (
          //   <EmailNotVerifiedBanner
          //     email={user?.email ?? ""}
          //     handleResend={resendVerificationEmail}
          //   />
          // )
          false}
    </>
  );
};

export default UserStatusChecker;
