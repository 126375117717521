import Pagination from "@components/Pagination";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const PaginationPage = () => {
  return (
    <>
      <Heading text="Pagination" />
      <Pagination count={6} shape="rounded" />
    </>
  );
};

export default PaginationPage;
