import Header from "@components/Header";

import Heading from "@pages/dashboard/ComponentPreview/Heading";

const HeaderPage = () => {
  return (
    <>
      <Heading text="Header without login" />
      <Header />
      <Heading text="Header after login" />
      <Header isLoggedIn />
    </>
  );
};

export default HeaderPage;
